import axios from 'axios'
import * as Notifications from 'expo-notifications'

import { BASE_URL, ENVIRONMENT } from 'react-native-dotenv'

const APP_ID = 'TODOS'
const FOLDERS = `${BASE_URL}/api/v1/todos/folders/`
const LISTS = `${BASE_URL}/api/v1/todos/lists/`
const TIME_LOGS = `${BASE_URL}/api/v1/todos/time-logs/`
const PUSH_NOTIFICATIONS = `${BASE_URL}/api/v1/user/push-notifications/`
const TASKS = `${BASE_URL}/api/v1/todos/tasks/`
const USER_PROFILE = `${BASE_URL}/api/v1/todos/profiles/`

export async function folderDelete(id: number) {
    try {
        const { data } = await axios.delete(`${FOLDERS}${id}/`)
        return { data }
    } catch (error) {
        return { error }
    }
}

export async function foldersGet() {
    try {
        const { data } = await axios.get(`${FOLDERS}`)
        return { data }
    } catch (error) {
        return { error }
    }
}

export async function folderPatch(folderFields: Folder, id: number) {
    try {
        const { data } = await axios.patch(`${FOLDERS}${id}/`, folderFields)
        return { data }
    } catch (error) {
        return { error }
    }
}

export async function folderPost(folderFields: Folder) {
    try {
        const { data } = await axios.post(`${FOLDERS}`, folderFields)
        return { data }
    } catch (error) {
        return { error }
    }
}


export async function listDelete(id: number) {
    try {
        const { data } = await axios.delete(`${LISTS}${id}/`)
        return { data }
    } catch (error) {
        return { error }
    }
}

export async function listPatch(listFields: List, id: number) {
    try {
        const { data } = await axios.patch(`${LISTS}${id}/`, listFields)
        return { data }
    } catch (error) {
        return { error }
    }
}

export async function listPost(listFields: List) {
    try {
        const { data } = await axios.post(`${LISTS}`, listFields)
        return { data }
    } catch (error) {
        return { error }
    }
}

export async function listsGet() {
    try {
        const { data } = await axios.get(`${LISTS}`)
        return { data }
    } catch (error) {
        return { error }
    }
}

export async function pushNotificationMetadataDelete(id: number) {
    try {
        const { data } = await axios.delete(`${PUSH_NOTIFICATIONS}/${id}/`, { headers: { 'app': APP_ID } })
        return { data }
    } catch (error) {
        return { error }
    }
}

export async function pushNotificationMetadataGet(accessToken: string) {
    try {
        const { data } = await axios.get(`${PUSH_NOTIFICATIONS}`, { headers: { 'app': APP_ID, 'Authorization': `Bearer ${accessToken}` } })
        return { data }
    } catch (error) {
        return { error }
    }
}

export async function pushNotificationMetadataPost(fields: any) {
    try {
        const { data } = await axios.post(`${PUSH_NOTIFICATIONS}`, fields, { headers: { 'app': APP_ID } })
        return { data }
    } catch (error) {
        return { error }
    }
}

export async function pushNotificationSchedule(notificationRequest: any) {
    const { title, body, data, trigger } = notificationRequest
    try {
        const notificationId = await Notifications.scheduleNotificationAsync({
            content: {
                title,
                body,
                data,
            },
            trigger,
        })
        return notificationId
    } catch (error) {
        return { error }
    }
}

export async function pushNotificationUnschedule(notificationId: string) {
    try {
        await Notifications.cancelScheduledNotificationAsync(notificationId)
        return { data: notificationId }
    } catch (error) {
        return { error }
    }
}

export async function taskDelete(id: number, accossToken: string) {
    try {
        const { data } = await axios.delete(`${TASKS}${id}/`, {
            headers: {
                Authorization: `Bearer ${accossToken}`
            }
        })
        return { data }
    } catch (error) {
        return { error }
    }
}

export async function taskPatch(taskFields: Task, id: number, accessToken: string) {
    try {
        const { data } = await axios.patch(`${TASKS}${id}/`, taskFields, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        return { data }
    } catch (error) {
        return { error }
    }
}

export async function taskPost(taskFields: Task, accessToken: string) {
    try {
        const { data } = await axios.post(`${TASKS}`, taskFields, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        return { data }
    } catch (error) {
        return { error }
    }
}

export async function tasksGet(accessToken: string) {
    try {
        const { data } = await axios.get(`${TASKS}`, {
        // const { data } = await axios.get(`${TASKS}${ENVIRONMENT=='development'? `?parent_task=86`: ''}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        return { data }
    } catch (error) {
        return { error }
    }
}

export async function timeLogDelete(id: number) {
    try {
        const { data } = await axios.delete(`${TIME_LOGS}/${id}`)
        return { data }
    } catch (error) {
        return { error }
    }
}

export async function timeLogsGet(accessToken: string) {
    try {
        const { data } = await axios.get(`${TIME_LOGS}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        return { data }
    } catch (error) {
        return { error }
    }
}

export async function timeLogPatch(timeLogFields: TimeLog, id: number) {
    try {
        const { data } = await axios.patch(`${TIME_LOGS}/${id}`, timeLogFields)
        return { data }
    } catch (error) {
        return { error }
    }
}

export async function timeLogPost(timeLogFields: TimeLog) {
    try {
        const { data } = await axios.post(`${TIME_LOGS}`, timeLogFields)
        return { data }
    } catch (error) {
        return { error }
    }
}

export async function userProfileGet(accessToken: string) {
    try {
        const { data } = await axios.get(USER_PROFILE, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        })
        return { data }
    } catch (error) {
        return { error }
    }
}

export async function userProfilePatch(fields: TodosUserProfile, id: number) {
    try {
        const { data } = await axios.patch(`${USER_PROFILE}/${id}`, fields)
        return { data }
    } catch (error) {
        return { error }
    }
}