import React from 'react'
import {Modal as RNModal, View} from 'react-native'

import styles from './styles'

export default function Modal({
    animationType = 'slide',
    visible,
    onRequestClose,
    transparent = true,
    children
}) {
    return (
        <RNModal
            animationType={animationType}
            transparent={transparent}
            visible={visible}
            onRequestClose={onRequestClose}
        >
            <View style={styles.centeredView}>
                <View style={styles.modalView}>
                    {children}
                </View>
            </View>
        </RNModal>
    )
}
