import * as React from 'react'
import { FlatList, ScrollView, View, StyleSheet, TouchableOpacity, Text } from 'react-native'
import { useSelector } from 'react-redux'

import TextLabel from 'src/app/components/TextLabel'
import { SCREENS } from 'src/time/components/TasksNavigator'
import store from 'src/app/redux/store'
import * as helpers from 'src/time/helpers'
import ActiveTaskBar from 'src/time/components/ActiveTaskBar'
import Button from 'src/app/components/Button'
// import TextInput from 'src/app/components/TextInput'
import { TextInput } from 'react-native-gesture-handler'
import { showMessage } from 'react-native-flash-message'
import TaskItem from 'src/time/components/TaskItem'


export default function GoalHome({ navigation }) {
    const homeItems = ['TASKS', 'TIME']
    const flaggedIncompleteTasks = useSelector(state => state.todos.tasks)
                    .filter((task: Task) => !task.completed)
                    .filter((task: Task) => task.flagged)
    const activeTaskId = useSelector((state: RootState) => state.todos.userProfile.active_task)
    const default_list = useSelector((state: RootState) => state.todos.userProfile.default_list)
    const allTasks = useSelector(state => state.todos.tasks).filter((task: Task) => !task.completed)
    const rootTasks = allTasks.filter((task: Task) => task.parent_task === null)

    const [transcription, setTranscription] = React.useState('')
    const [filterTaskId, setFilterTaskId] = React.useState(null)
    const [filterType, setFilterType] = React.useState('IN')
    const [sectionsVisibility, setSectionsVisibility] = React.useState({
        currentTasks: true,
        overdueTasks: true,
        tasks: true,
        flaggedTasks: true,
        currentTimeWindows: true
    })
    
    React.useEffect(() => { store.dispatch({ type: 'SET_GOALTASKSNAV', payload: null }) }, [])

    const renderRootTasks = ({ item }) => {
        return (
            <View style={styles.taskContainer}>
                <TouchableOpacity
                    onPress={() => navigation.navigate(SCREENS[`GOAL_TASKS`], { parentTaskId: item.id})}
                    onLongPress={() => navigation.navigate(SCREENS['ADD_EDIT_TASK'], { mode: 'TASK_EDIT', payload: { id: item.id } })}
                    key={item.id}
                >
                    <View style={styles.titleContainer}>
                        <TextLabel extraStyles={{fontSize: 16}} text={`${item.name} (${helpers.getChildTaskCountForTask(item, allTasks)})${helpers.getDescriptionStatus(item)}${helpers.getTimeLogStatus(item)}`} />
                    </View>
                </TouchableOpacity>
            </View>
        )
    }

    const renderCurrentTimeWindowItem = ({ item }) => {
        return (
            <View style={styles.taskContainer}>
                <TouchableOpacity
                    onPress={() => navigation.navigate(SCREENS[`GOAL_TIME`], { dueCode: item })}
                    key={item}
                >
                    <View style={styles.titleContainer}>
                        <TextLabel extraStyles={{fontSize: 16}} text={`${item} (${helpers.getTaskCountForTimeWindow(item, allTasks)})`} />
                    </View>
                </TouchableOpacity>
            </View>
        )
    }

    const renderFlaggedTaskItem = ({ item }) => {
        const childTaskCountText = helpers.getChildTaskCountForTask(item, allTasks)
        const parentTaskName = allTasks.find((task: Task) => task.id === item.parent_task)?.name
        return (
            <TaskItem
                item={item}
                parentTaskName={parentTaskName}
                childTaskCountText={childTaskCountText}
                rootNavigation={navigation}
            />
        )
    }

    return (
        <ScrollView>
            {/* Header */}
            {activeTaskId && <ActiveTaskBar />}
            <View style={styles.header}>
                <TextInput
                    value={transcription}
                    placeholder='Enter command...'
                    onChangeText={(text) => setTranscription(text)}
                    style={{
                        width: '75%',
                        borderRadius: 8,
                        margin: 4,
                        padding: 8,
                        backgroundColor: '#ddd',
                        height: 40
                    }}
                />
                <Button
                    text={`╳`}
                    onPress={() => { setTranscription(''); setFilterTaskId(null) }}
                />
                <Button
                    text={'▶️'}
                    onPress={() => helpers.executeCommand(transcription, navigation, allTasks, SCREENS, setFilterTaskId, setFilterType)}
                />
            </View>
            {/* Overdue Tasks */}
            <Text
                style={{
                    textAlign: 'center',
                    fontSize: 24,
                    paddingTop: 8,
                    paddingBottom: 8,
                    color: sectionsVisibility.overdueTasks ? 'white' : '#222',
                    backgroundColor: sectionsVisibility.overdueTasks ? '#777' : '#777'                }}
                onPress={() => setSectionsVisibility({...sectionsVisibility, overdueTasks: !sectionsVisibility.overdueTasks})}
            >
                Overdue Tasks
            </Text>
            {
                sectionsVisibility.overdueTasks && 
                helpers.getCurrentTimeWindows(undefined)
                .reverse()
                .map((dueCode: string) => {
                    // Get all overdue tasks for this due code
                    // console.log('dueCode', dueCode)
                    const overdueTasks = helpers.getOverdueTasks(allTasks, dueCode)
                    const overdueDueCodes = overdueTasks
                                            .map((task: Task) => `${task.due_type} : ${task.due_value}`) // Convert to due code
                                            .filter((value, index, self) => self.indexOf(value) === index) // Just get unique
                                            .sort((a, b) => b > a ? 1 : -1) // Sort
                    // console.log('overdueDueCodes for a given dueCode', dueCode, overdueDueCodes, overdueTasks.length)
                    return overdueDueCodes.map((overdueCode: string) => {
                        // console.log(`overdueCode: ${overdueCode}`)
                        return (
                            <View>
                                <TouchableOpacity
                                    onPress={() => navigation.navigate(SCREENS[`GOAL_TIME`], { dueCode: overdueCode })}
                                    key={overdueCode}
                                >
                                    <View style={styles.sectionHeader}>
                                        <TextLabel extraStyles={{fontSize: 16}} text={`${overdueCode}`} />
                                    </View>
                                </TouchableOpacity>
                                {
                                    overdueTasks
                                    .filter((task: Task) => { // Filter if any filterTask is set
                                        if (filterTaskId) {
                                            const isDescendent = helpers.isDescendentOf(task, filterTaskId, allTasks)
                                            return filterType === 'IN' ? isDescendent : !isDescendent
                                        } else {
                                            return true
                                        }
                                    })
                                    .filter((task: Task) => `${task.due_type} : ${task.due_value}` === overdueCode)
                                    .map((task: Task) => {
                                        return (
                                            <TaskItem
                                                item={task}
                                                parentTaskName={allTasks.find((t: Task) => t.id === task.parent_task)?.name}
                                                childTaskCountText={helpers.getChildTaskCountForTask(task, allTasks)}
                                                rootNavigation={navigation}
                                            />
                                        )
                                    })
                                }
                            </View>
                        )
                    })
                })
            }
            <View
                style={{
                    borderBottomColor: 'black',
                    borderBottomWidth: 1,
                }}
            />
            {/* Current Tasks */}
            <Text
                style={{
                    textAlign: 'center',
                    fontSize: 24,
                    paddingTop: 8,
                    paddingBottom: 8,
                    color: sectionsVisibility.currentTasks ? 'white' : '#222',
                    backgroundColor: sectionsVisibility.currentTasks ? '#777' : '#777'
                }}
                onPress={() => setSectionsVisibility({...sectionsVisibility, currentTasks: !sectionsVisibility.currentTasks})}
            >
                Current Tasks
            </Text>
            {
                sectionsVisibility.currentTasks && 
                helpers.getCurrentTimeWindows(undefined)
                .reverse()
                .map((dueCode: string) => {
                    const timeWindowTasks = allTasks.filter((task: Task) => `${task.due_type} : ${task.due_value}` === dueCode)
                    return (
                        <View>
                            <TouchableOpacity
                                onPress={() => navigation.navigate(SCREENS[`GOAL_TIME`], { dueCode: dueCode })}
                                key={dueCode}
                            >
                                <View style={styles.sectionHeader}>
                                    <TextLabel extraStyles={{fontSize: 16}} text={`${dueCode} (${helpers.getTaskCountForTimeWindow(dueCode, allTasks)})`} />
                                </View>
                            </TouchableOpacity>
                            {
                                timeWindowTasks
                                .filter((task: Task) => {
                                    if (filterTaskId) {
                                        const isDescendent = helpers.isDescendentOf(task, filterTaskId, allTasks)
                                        return filterType === 'IN' ? isDescendent : !isDescendent
                                    } else {
                                        return true
                                    }
                                })
                                .map((task: Task) => {
                                    return (
                                        <TaskItem
                                            item={task}
                                            parentTaskName={allTasks.find((t: Task) => t.id === task.parent_task)?.name}
                                            childTaskCountText={helpers.getChildTaskCountForTask(task, allTasks)}
                                            rootNavigation={navigation}
                                        />
                                    )
                                })
                            }
                        </View>
                    )
                })
            }
            <View
                style={{
                    borderBottomColor: 'black',
                    borderBottomWidth: 1,
                }}
            />
            {/* Tasks */}
            <Text
                style={{
                    textAlign: 'center',
                    fontSize: 24,
                    paddingTop: 8,
                    paddingBottom: 8,
                    color: sectionsVisibility.tasks ? 'white' : '#222',
                    backgroundColor: sectionsVisibility.tasks ? '#777' : '#777'                }}
                onPress={() => setSectionsVisibility({...sectionsVisibility, tasks: !sectionsVisibility.tasks})}
            >
                Tasks
            </Text>
            {sectionsVisibility.tasks && <FlatList
                data={rootTasks}
                keyExtractor={(item) => item.id}
                renderItem={renderRootTasks}
            />}
            <View
                style={{
                    borderBottomColor: 'black',
                    borderBottomWidth: 1,
                }}
            />
            {/* Flagged Tasks */}
            <Text
                style={{
                    textAlign: 'center',
                    fontSize: 24,
                    paddingTop: 8,
                    paddingBottom: 8,
                    color: sectionsVisibility.flaggedTasks ? 'white' : '#222',
                    backgroundColor: sectionsVisibility.flaggedTasks ? '#777' : '#777'                }}
                onPress={() => setSectionsVisibility({...sectionsVisibility, flaggedTasks: !sectionsVisibility.flaggedTasks})}
            >
                Flagged Tasks
            </Text>
            {sectionsVisibility.flaggedTasks && <FlatList 
                data={flaggedIncompleteTasks}
                keyExtractor={(item) => item.id}
                renderItem={renderFlaggedTaskItem}
            />}
            <View
                style={{
                    borderBottomColor: 'black',
                    borderBottomWidth: 1,
                }}
            />
            {/* Current Time Windows */}
            <Text
                style={{
                    textAlign: 'center',
                    fontSize: 24,
                    paddingTop: 8,
                    paddingBottom: 8,
                    color: sectionsVisibility.currentTimeWindows ? 'white' : '#222',
                    backgroundColor: sectionsVisibility.currentTimeWindows ? '#777' : '#777'                }}
                onPress={() => setSectionsVisibility({...sectionsVisibility, currentTimeWindows: !sectionsVisibility.currentTimeWindows})}
            >
                Current Time Windows
            </Text>
            {sectionsVisibility.currentTimeWindows && <FlatList 
                data={helpers.getCurrentTimeWindows(undefined)}
                keyExtractor={(item) => item.id}
                renderItem={renderCurrentTimeWindowItem}
            />}
            {/* horizontal line */}
            <View
                style={{
                    borderBottomColor: 'black',
                    borderBottomWidth: 1,
                }}
            />
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    modalView: {
        backgroundColor: "white",
        borderRadius: 20,
        padding: 4,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        width: '80%',
        height: '50%'
    },
    checkboxContainer: {
        marginRight: 10
    },
    flagContainer: {
        marginRight: 4
    },
    header: {
        display: 'flex',
        flexDirection: 'row', 
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center', 
    },
    taskItemBodyContainer: {
        flex: 1,
        flexDirection: 'row'
    },
    titleContainer: {
        // backgroundColor: '#bbb',
        paddingLeft: 4,
        paddingRight: 4,
        paddingTop: 4
    },
    sectionHeader: {
        backgroundColor: '#bbb',
        paddingLeft: 4,
        paddingRight: 4,
        paddingTop: 4
    },
    footerContainer: {
        // backgroundColor: 'yellow',
        paddingLeft: 4
    },
    footerText: {
        fontSize: 12,
        color: '#777'
    },
    taskContainer: {
        flex: 1,
        flexDirection: 'column',
        // backgroundColor: 'red',
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
        paddingTop: 4,
        paddingBottom: 4
    },
})