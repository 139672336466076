
import * as React from 'react'
import { createStackNavigator } from '@react-navigation/stack'

import Login from '../screens/Login'
import SignUp from '../screens/SignUp'

const AuthStack = createStackNavigator()

export default function RootNavigator() {
    return (
        <AuthStack.Navigator screenOptions={{headerShown: false}}>
                <AuthStack.Screen 
                  name='Login' 
                  component={Login} 
                />
                <AuthStack.Screen 
                  name='Sign Up' 
                  component={SignUp} 
                />
        </AuthStack.Navigator>
    )
}