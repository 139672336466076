import * as React from 'react'
import { FlatList, ScrollView, View, StyleSheet, TouchableOpacity, Text, SectionList } from 'react-native'
import { useSelector } from 'react-redux'

import TextLabel from 'src/app/components/TextLabel'
import { SCREENS } from 'src/time/components/TasksNavigator'
import * as helpers from 'src/time/helpers'
import ActiveTaskBar from 'src/time/components/ActiveTaskBar'
import TextInput from 'src/app/components/TextInput'
import Button from 'src/app/components/Button'
import { get } from 'react-native/Libraries/TurboModule/TurboModuleRegistry'
import TaskItem from 'src/time/components/TaskItem'

const getFiveYearChildValues = (dueCode: string) => {
    const year = parseInt(dueCode.split(':')[1].trim().split('-')[0])
    return [`FY : ${year}-${year+4}`, `FY : ${year+5}-${year+9}`, `FY : ${year+10}-${year+14}`, `FY : ${year+15}-${year+19}`, `FY : ${year+20}-${year+24}`]
}

const getYearChildValues = (dueCode: string) => {
    const year = parseInt(dueCode.split(':')[1].trim().split('-')[0]) + 1991
    return [`Y : ${year}`, `Y : ${year+1}`, `Y : ${year+2}`, `Y : ${year+3}`, `Y : ${year+4}`, `Y : ${year+5}`, `Y : ${year+6}`, `Y : ${year+7}`, `Y : ${year+8}`, `Y : ${year+9}`]
}

const getQuarterChildValues = (dueCode: string) => {
    const year = parseInt(dueCode.split(':')[1].trim())
    return [`Q : ${year}-Q1`, `Q : ${year}-Q2`, `Q : ${year}-Q3`, `Q : ${year}-Q4`]
}

const getDecadeChildValues = (dueCode: string) => {
    // For dueCode like "TD : 1-30", it should return decades array like ["TD : 1-10", "TD : 11-20", "TD : 21-30"]
    const decade = parseInt(dueCode.split(':')[1].trim().split('-')[0])
    return [`DD : ${decade}-${decade+9}`, `DD : ${decade+10}-${decade+19}`, `DD : ${decade+20}-${decade+29}`]
}

const getMonthChildValues = (dueCode: string) => {
    const year = parseInt(dueCode.split(':')[1].trim().split('-')[0])
    const quarter = parseInt(dueCode.split(':')[1].trim().split('-')[1].split('')[1])
    let month1 = (quarter - 1) * 3 + 1
    if (month1 < 10) month1 = `0${month1}`
    let month2 = (quarter - 1) * 3 + 2
    if (month2 < 10) month2 = `0${month2}`
    let month3 = (quarter - 1) * 3 + 3
    if (month3 < 10) month3 = `0${month3}`
    return [`M : ${year}-${month1}`, `M : ${year}-${month2}`, `M : ${year}-${month3}`]
}

function getWeekChildValues(dueCode: string) {
    dueCode = dueCode.split(':')[1].trim()
    // Parse the date string and create a new date object
    const month = new Date(`${dueCode}-01`);
  
    // Set the date to the first day of the month
    month.setDate(1);
  
    // Get the day of the week for the first day of the month
    const dayOfWeek = month.getDay();
  
    // Calculate the number of days to go back to the previous Sunday
    const daysToGoBack = dayOfWeek === 6 ? 6 : dayOfWeek - 1;
  
    // Go back to the previous Saturday
    month.setDate(6 - daysToGoBack);
  
    // Initialize the array of first dates of weeks
    const firstDatesOfWeeks = [];
  
    // Loop through the weeks of the month
    while (true) {
      // If the current date is in the current month, add it to the array
      if (month.getMonth() === new Date(`${dueCode}-01`).getMonth()) {
        firstDatesOfWeeks.push(new Date(month));
      }
  
      // Move to the next week
      month.setDate(month.getDate() + 7);
  
      // If the month has changed, stop the loop
      if (month.getMonth() !== new Date(`${dueCode}-01`).getMonth()) {
        break;
      }
    }

    // Convert all dates into YYYY-MM-DD format
    for (let i = 0; i < firstDatesOfWeeks.length; i++) {
        const date = firstDatesOfWeeks[i];
        const year = date.getFullYear();
        let month = date.getMonth() + 1;
        if (month < 10) month = `0${month}`;
        let day = date.getDate();
        if (day < 10) day = `0${day}`;
        firstDatesOfWeeks[i] = `W : ${year}-${month}-${day}`;
    }
  
    return firstDatesOfWeeks;
}

const getDayChildValues = (dueCode: string) => {
    dueCode = dueCode.split(':')[1].trim()
    // Parse the date string and create a new date object
    const startDate = new Date(dueCode);

    // Initialize the array of dates
    const dates = [];

    // Loop through the next 6 days
    for (let i = 0; i < 7; i++) {
        // Add the current date to the array
        dates.push(new Date(startDate));

        // Move to the next day
        startDate.setDate(startDate.getDate() + 1);
    }

    // Convert all dates into YYYY-MM-DD format
    for (let i = 0; i < dates.length; i++) {
        const date = dates[i];
        const year = date.getFullYear();
        let month = date.getMonth() + 1;
        if (month < 10) month = `0${month}`;
        let day = date.getDate();
        if (day < 10) day = `0${day}`;
        dates[i] = `D : ${year}-${month}-${day}`;
    }

  return dates
}

const getChildTimeWindows = (dueCode: string) => {
    const dueCodeHeader = dueCode.split(':')[0].trim()
    switch (dueCodeHeader) {
        case 'L':
            return ['TD : 1-30', 'TD : 31-60', 'TD : 61-90', 'TD : 91-120']
        case 'TD':
            return getDecadeChildValues(dueCode)
        case 'DD':
            return getYearChildValues(dueCode)
        case 'Y':
            return getQuarterChildValues(dueCode)
        case 'Q': 
            return getMonthChildValues(dueCode)
        case 'M':
            return getWeekChildValues(dueCode)
        case 'W':
            return getDayChildValues(dueCode)
        default:
            return []
    }
}

export default function GoalTime({ navigation, route }) {
    const dueCode = route.params?.dueCode
    const filterTasks = (t: Task) => {
        const dueCodeHeader = dueCode.split(':')[0].trim()
        const dueCodeValue = dueCode.split(':')[1].trim()
        const taskDueCodeValue = t.due_code.split(':')[1].trim()
        const taskDueCodeHeader = t.due_code.split(':')[0].trim()
        const returnValue = dueCodeHeader === taskDueCodeHeader && dueCodeValue === taskDueCodeValue
        return returnValue
    }

    const allTasks = useSelector((state: RootState) => state.todos.tasks)

    // Add due_code
    const tasks = allTasks
                    .map((t: Task) => { t.due_code = t.due_type ? `${t.due_type} : ${t.due_value}`: 'NA : NA' ; return t })

    let dueTasks = dueCode 
                    ? tasks.filter(filterTasks)
                    : tasks.filter((t: Task) => t.due_type === 'L')
    dueTasks = dueTasks.sort((a: Task, b: Task) => a.name.localeCompare(b.name))

    // Get child tasks for the time window based on child time windows
    const childTimeWindows = getChildTimeWindows(dueCode || 'L : L')
    // console.log('childTimeWindows', childTimeWindows)
    const childTimeWindowTasks = childTimeWindows.map((timeWindow: string) => {
        return {
            timeWindow,
            tasks: tasks.filter((t: Task) => t.due_code === timeWindow)
        }
    })
    // console.log('childTimeWindowTasks', childTimeWindowTasks)
    const sectionsForChildTimeWindowTasks = childTimeWindowTasks.map((timeWindowTasks: any) => {
        return {
            title: timeWindowTasks.timeWindow,
            data: timeWindowTasks.tasks
        }
    })

    function SectionHeader({ title }: { title: string }) {
        return (
            <View style={{backgroundColor: '#ccc', padding: 4}}>
                <TouchableOpacity
                    onPress={() => { title != "NA : NA" ? navigation.navigate(SCREENS.GOAL_TIME, { dueCode: title }) : null }}
                    // onLongPress={() => {
                    //     // Go to add edit task screen
                    //     navigation.navigate(SCREENS.ADD_EDIT_TASK, { id: item.id, list: item.list })
                    // }}
                    key={title}
                >
                    <TextLabel text={title} />
                </TouchableOpacity>
            </View>
        )
    }

    // Calculate overdue tasks by filtering tasks that are not completed and are overdue
    const overdueTasks = helpers.getOverdueTasks(allTasks, dueCode)

    const activeTaskId = useSelector((state: RootState) => state.todos.userProfile.active_task)

    // const parentTaskName = tasks.find((t: Task) => t.id === parentTaskId)?.name
    const [searchTerm, updateSearchTerm] = React.useState('')
    const [flagFilter, setFlagFilter] = React.useState(false)

    navigation.setOptions({ title: '🔼 '+ (dueCode || 'LIFE') })

    function ListItem({ item }: { item: Task }) {
        const childTaskCount = helpers.getChildTaskCountForTask(item, allTasks)
        const childTaskCountText = childTaskCount ? `(${childTaskCount})` : ''
        const parentTaskId = item.parent_task
        const parentTaskName = tasks.find((t: Task) => t.id === parentTaskId)?.name

        return (
            <TaskItem
                item={item}
                childTaskCountText={childTaskCountText}
                navigation={navigation}
                parentTaskName={parentTaskName}
            />
        )
    }

    function ListOverdueItem({ item }: { item: Task }) {
        const childTaskCount = helpers.getChildTaskCountForTask(item, allTasks)
        const childTaskCountText = childTaskCount ? `(${childTaskCount})` : ''
        return (
            <View style={styles.taskContainer}>
                <TouchableOpacity
                    onPress={() => { navigation.push(SCREENS.GOAL_TASKS, { parentTaskId: item.id }) }}
                    onLongPress={() => {
                        // Go to add edit task screen
                        navigation.push(SCREENS.ADD_EDIT_TASK, { id: item.id, list: item.list })
                    }}
                    key={item.id}
                >
                    <View style={styles.titleContainer}>
                        <TextLabel extraStyles={{fontSize: 16}} text={`[${item.due_type}:${item.due_value}] ${item.name} ${childTaskCountText}${helpers.getDescriptionStatus(item)}${helpers.getTimeLogStatus(item)}`} />
                    </View>
                </TouchableOpacity>
            </View>
        )
    }

    function ListTimeWindowItem({ item } : { item: string }) {
        return (
            <View style={styles.taskContainer}>
                <TouchableOpacity
                    onPress={() => { navigation.push(SCREENS.GOAL_TIME, { dueCode: item }) }}
                    key={item}
                >
                    <View style={styles.titleContainer}>
                        <TextLabel extraStyles={{fontSize: 16}} text={`${item} (${helpers.getTaskCountForTimeWindow(item, allTasks)})`} />
                    </View>
                </TouchableOpacity>
            </View>
        )
    }

    return (
        <>
            {activeTaskId && <ActiveTaskBar />}

            {/* Header */}
            <View style={styles.header}>
            <Button
                    text={`◀️ ${helpers.getPreviousDueCode(dueCode)}`}
                    extraStyle={{
                        // flexGrow: 0,
                        // flexShrink: 0,
                        // whiteSpace: 'nowrap',
                        // backgroundColor: '#f00',
                    }}
                    onPress={() => {
                        navigation.push(SCREENS.GOAL_TIME, { dueCode: helpers.getPreviousDueCode(dueCode) })
                    }}
                />
                <Button
                    text={`🔼 ${helpers.getParentDueCode(dueCode)}`}
                    extraStyle={{
                        // flexGrow: 0,
                        // flexShrink: 0,
                        // whiteSpace: 'nowrap',
                        // backgroundColor: '#f00',
                    }}
                    onPress={() => {
                        const parentDueCode = helpers.getParentDueCode(dueCode)
                        if (parentDueCode !== "NA : NA") navigation.push(SCREENS.GOAL_TIME, { dueCode: parentDueCode })
                    }}
                />
                <Button
                    text={`${helpers.getNextDueCode(dueCode)} ▶️`}
                    extraStyle={{
                        // flexGrow: 0,
                        // flexShrink: 0,
                        // whiteSpace: 'nowrap',
                        // backgroundColor: '#f00',
                    }}
                    onPress={() => {
                        navigation.push(SCREENS.GOAL_TIME, { dueCode: helpers.getNextDueCode(dueCode) })
                    }}
                />
            </View>
            <TextInput
                placeholder='Search'
                value={searchTerm}
                onChangeText={(text) => updateSearchTerm(text)}
                extraStyle={{
                    margin: 8,
                    padding: 8,
                    borderWidth: 1,
                    borderColor: '#ccc',
                    borderRadius: 8,
                    backgroundColor: '#fff'
                }}
            />

            <ScrollView>
                {/* Tasks for Time Window */}
                <Text
                    style={{
                        textAlign: 'center',
                        fontSize: 24,
                        paddingTop: 8,
                        margin: 12,
                        color: '#777'
                    }}
                >
                    Tasks
                </Text>
                <FlatList
                    data={dueTasks.filter((t: Task) => !t.completed).filter((t: Task) => {
                        if (flagFilter) {
                            return t.flagged && t.name.toLowerCase().includes(searchTerm.toLowerCase())
                        } else {
                            return t.name.toLowerCase().includes(searchTerm.toLowerCase())
                        }
                    })}
                    renderItem={ListItem}
                    keyExtractor={(item: Task) => item.id.toString()}
                />

                {/* Tasks for Children */}
                <Text
                    style={{
                        textAlign: 'center',
                        fontSize: 24,
                        paddingTop: 16,
                        margin: 12,
                        color: '#777'
                    }}
                >
                    Time Windows
                </Text>
                {/* <FlatList
                    data={childTimeWindows}
                    renderItem={ListTimeWindowItem}
                    keyExtractor={(item) => item.toString()}
                /> */}
                <SectionList
                    sections={sectionsForChildTimeWindowTasks}
                    renderItem={({ item }) => ListItem({ item })}
                    renderSectionHeader={({ section: { title } }) => SectionHeader({ title })}
                    keyExtractor={(item) => item.id}
                />

                {/* Overdue Tasks */}
                <Text
                    style={{
                        textAlign: 'center',
                        fontSize: 24,
                        paddingTop: 8,
                        margin: 12,
                        color: '#777'
                    }}
                >
                    Overdue Tasks
                </Text>
                <FlatList
                    data={overdueTasks.filter((t: Task) => !t.completed).filter((t: Task) => {
                        if (flagFilter) {
                            return t.flagged && t.name.toLowerCase().includes(searchTerm.toLowerCase())
                        } else {
                            return t.name.toLowerCase().includes(searchTerm.toLowerCase())
                        }
                    })}
                    renderItem={ListOverdueItem}
                    keyExtractor={(item: Task) => item.id.toString()}
                />

                {/* Completed Tasks */}
                <Text
                    style={{
                        textAlign: 'center',
                        fontSize: 24,
                        paddingTop: 8,
                        margin: 12,
                        color: '#777'
                    }}
                >
                    Completed Tasks
                </Text>
                <FlatList
                    data={dueTasks.filter((t: Task) => t.completed).filter((t: Task) => {
                        if (flagFilter) {
                            return t.flagged && t.name.toLowerCase().includes(searchTerm.toLowerCase())
                        } else {
                            return t.name.toLowerCase().includes(searchTerm.toLowerCase())
                        }
                    })}
                    renderItem={ListItem}
                    keyExtractor={(item: Task) => item.id.toString()}
                />
            </ScrollView>
        </>
    )
}

const styles = StyleSheet.create({
    checkboxContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 8
    },
    contentContainer: {},
    flagContainer: {
        marginRight: 4
    },
    footerContainer: {
        // backgroundColor: 'red',
    },
    footerText: {
        fontSize: 12,
        color: '#777'
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    taskItemBodyContainer: {
        flex: 1,
        flexDirection: 'row'
    },
    titleBodyContainer: {
        flexDirection: 'row',
        // backgroundColor: 'green',
    },
    titleContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    taskContainer: {
        flex: 1,
        flexDirection: 'column',
        // backgroundColor: 'red',
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
        paddingTop: 4,
        paddingBottom: 4
    },
})