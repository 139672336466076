export const initialState = {
    folders: [],
    lists: [],
    tasks: [],
    timeLogs: [],
    pushNotificationMetadata: [],
    userProfile: {},
    appState: { planningMode: false },
}

declare type State = {
    folders: Array<Folder>
    lists: Array<List>
    tasks: Array<Task>,
    timeLogs: Array<TimeLog>,
    pushNotificationMetadata: [],
    userProfile: {
        id: number,
        ios_reminder_list_id: number | null,
        user: number,
        default_list: number,
        active_task: number | null
    },
    appState: { planningMode: boolean },
}

function implementAddUpdateList(state: State, payload: List) {
    const newState = JSON.parse(JSON.stringify(state))
    const index = newState.lists.findIndex((list: List) => list.id === payload.id)
    if (index === -1) {
        newState.lists.push(payload)
    } else {
        newState.lists[index] = payload
    }
    return newState
}

function implementAddUpdateTask(state: State, payload: Task) {
    const newState = JSON.parse(JSON.stringify(state))
    const index = newState.tasks.findIndex((task: Task) => task.id === payload.id)
    if (index === -1) {
        newState.tasks.push(payload)
    } else {
        newState.tasks[index] = payload
    }
    return newState
}

function implementDeleteList(state: State, payload: number) {
    const newState = JSON.parse(JSON.stringify(state))

    // Remove list object from lists
    const index = newState.lists.findIndex((list: List) => list.id === payload)
    if (index !== -1) {
        newState.lists.splice(index, 1)
    }

    // Remove tasks that belong to this list
    newState.tasks = newState.tasks.filter((task: Task) => task.list !== payload)
    return newState
}

function implementDeleteTask(state: State, payload: number) {
    const newState = JSON.parse(JSON.stringify(state))
    const index = newState.tasks.findIndex((task: Task) => task.id === payload)
    if (index !== -1) {
        newState.tasks.splice(index, 1)
    }
    return newState
}

function implementReplaceLists(state: State, payload: Array<List>) {
    const newState = JSON.parse(JSON.stringify(state))
    payload.sort((a: List, b: List) => a.name.localeCompare(b.name));
    newState.lists = payload
    return newState
}

function implementReplaceTasks(state: State, payload: Array<Task>) {
    const newState = JSON.parse(JSON.stringify(state))
    newState.tasks = payload
    return newState
}

function implementFoldersReplace(state: State, payload: Array<Folder>) {
    const newState = JSON.parse(JSON.stringify(state))
    payload.sort((a: Folder, b: Folder) => a.name.localeCompare(b.name));
    newState.folders = payload
    return newState
}

function implementFolderDelete(state: State, payload: number) {
    const newState = JSON.parse(JSON.stringify(state))
    const index = newState.folders.findIndex((folder: Folder) => folder.id === payload)
    if (index !== -1) {
        newState.folders.splice(index, 1)
    }
    return newState
}

function implementFolderAddUpdate(state: State, payload: Folder) {
    const newState = JSON.parse(JSON.stringify(state))
    const index = newState.folders.findIndex((folder: Folder) => folder.id === payload.id)
    if (index === -1) {
        newState.folders.push(payload)
    } else {
        newState.folders[index] = payload
    }
    return newState
}

function implementTimeLogsReplace(state: State, payload: Array<TimeLog>) {
    const newState = JSON.parse(JSON.stringify(state))
    // payload.sort((a: TimeLog, b: TimeLog) => a.name.localeCompare(b.name));
    newState.timeLogs = payload
    return newState
}

function implementTimeLogDelete(state: State, payload: number) {
    const newState = JSON.parse(JSON.stringify(state))
    const index = newState.timeLogs.findIndex((i: TimeLog) => i.id === payload)
    if (index !== -1) {
        newState.timeLogs.splice(index, 1)
    }
    return newState
}

function implementTimeLogAddUpdate(state: State, payload: TimeLog) {
    const newState = JSON.parse(JSON.stringify(state))
    const index = newState.timeLogs.findIndex((i: TimeLog) => i.id === payload.id)
    if (index === -1) {
        newState.timeLogs.push(payload)
    } else {
        newState.timeLogs[index] = payload
    }
    return newState
}

function implementPushNotificationMetadataAdd(state: State, payload: any) {
    const newState = JSON.parse(JSON.stringify(state))
    payload.metadata = JSON.parse(payload.metadata)
    newState.pushNotificationMetadata.push(payload)
    return newState
}

function implementPushNotificationMetadataDelete(state: State, payload: number) {
    const newState = JSON.parse(JSON.stringify(state))
    const index = newState.pushNotificationMetadata.findIndex((pushNotificationMetadata: any) => pushNotificationMetadata.id === payload)
    if (index !== -1) {
        newState.pushNotificationMetadata.splice(index, 1)
    }
    return newState
}

function implementPushNotificationMetadataReplace(state: State, payload: Array<any>) {
    const newState = JSON.parse(JSON.stringify(state))
    newState.pushNotificationMetadata = payload.map(p => ({ ...p, metadata: JSON.parse(p.metadata) }))
    return newState
}

function implementTogglePlanningMode(state: State) {
    const newState = JSON.parse(JSON.stringify(state))
    newState.appState.planningMode = !newState.appState.planningMode
    return newState
}

function implementUserProfileReplace(state: State, payload: TodosUserProfile) {
    const newState = JSON.parse(JSON.stringify(state))
    newState.userProfile = payload
    return newState
}

export const ACTION_TYPES = {
    ADD_UPDATE_LIST: 'ADD_UPDATE_LIST',
    ADD_UPDATE_TASK: 'ADD_UPDATE_TASK',
    DELETE_LIST: 'DELETE_LIST',
    DELETE_TASK: 'DELETE_TASK',
    REPLACE_LISTS: 'REPLACE_LISTS',
    REPLACE_TASKS: 'REPLACE_TASKS',

    FOLDERS_REPLACE: 'FOLDERS_REPLACE',
    FOLDER_ADD_UPDATE: 'FOLDER_ADD_UPDATE',
    FOLDER_DELETE: 'FOLDER_DELETE',
    PUSH_NOTIFICATION_METADATA_ADD: 'PUSH_NOTIFICATION_METADATA_ADD',
    PUSH_NOTIFICATION_METADATA_REPLACE: 'PUSH_NOTIFICATION_METADATA_REPLACE',
    PUSH_NOTIFICATION_METADATA_DELETE: 'PUSH_NOTIFICATION_METADATA_DELETE',
    TIME_LOGS_REPLACE: 'TIME_LOGS_REPLACE',
    TIME_LOG_ADD_UPDATE: 'TIME_LOG_ADD_UPDATE',
    TIME_LOG_DELETE: 'TIME_LOG_DELETE',
    TOGGLE_PLANNING_MODE: 'TOGGLE_PLANNING_MODE',
    USER_PROFILE_REPLACE: 'USER_PROFILE_REPLACE',

    CLEAR_TIME_DATA: 'CLEAR_TIME_DATA',
}

function reducer(state: State = initialState, action: ReduxAction) {
    switch (action.type) {
        case ACTION_TYPES.ADD_UPDATE_LIST:
            return implementAddUpdateList(state, action.payload)
        case ACTION_TYPES.ADD_UPDATE_TASK:
            return implementAddUpdateTask(state, action.payload)
        case ACTION_TYPES.DELETE_LIST:
            return implementDeleteList(state, action.payload)
        case ACTION_TYPES.DELETE_TASK:
            return implementDeleteTask(state, action.payload)
        case ACTION_TYPES.REPLACE_LISTS:
            return implementReplaceLists(state, action.payload)
        case ACTION_TYPES.REPLACE_TASKS:
            return implementReplaceTasks(state, action.payload)
        case ACTION_TYPES.FOLDERS_REPLACE:
            return implementFoldersReplace(state, action.payload)
        case ACTION_TYPES.FOLDER_ADD_UPDATE:
            return implementFolderAddUpdate(state, action.payload)
        case ACTION_TYPES.FOLDER_DELETE:
            return implementFolderDelete(state, action.payload)
        case ACTION_TYPES.PUSH_NOTIFICATION_METADATA_ADD:
            return implementPushNotificationMetadataAdd(state, action.payload)
        case ACTION_TYPES.PUSH_NOTIFICATION_METADATA_DELETE:
            return implementPushNotificationMetadataDelete(state, action.payload)
        case ACTION_TYPES.PUSH_NOTIFICATION_METADATA_REPLACE:
            return implementPushNotificationMetadataReplace(state, action.payload)
        case ACTION_TYPES.TIME_LOGS_REPLACE:
            return implementTimeLogsReplace(state, action.payload)
        case ACTION_TYPES.TIME_LOG_ADD_UPDATE:
            return implementTimeLogAddUpdate(state, action.payload)
        case ACTION_TYPES.TIME_LOG_DELETE:
            return implementTimeLogDelete(state, action.payload)
        case ACTION_TYPES.TOGGLE_PLANNING_MODE:
            return implementTogglePlanningMode(state)
        case ACTION_TYPES.USER_PROFILE_REPLACE:
            return implementUserProfileReplace(state, action.payload)
        case ACTION_TYPES.CLEAR_TIME_DATA:
            console.log('Changed time app state to', initialState)
            return initialState
        default:
            return state;
    }
}

export default reducer
