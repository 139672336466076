import axios from 'axios'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { hideMessage, showMessage } from 'react-native-flash-message'

import { BASE_URL } from 'react-native-dotenv'
import * as authHelpers from 'src/auth/helpers'
import { sentryHelpers } from './helpers'

const axiosInstance = axios.create({
  baseURL: BASE_URL,
})

axiosInstance.defaults.headers.post['Content-Type'] = 'application/json'

export async function setRequestInterceptor() {
    // Use this interceptor to set the Authorization header for every request
    axiosInstance.interceptors.request.use(async (config) => {
        const tokens = await AsyncStorage.getItem('tokens')
        if (tokens) {
            config.headers.Authorization = `${JSON.parse(tokens).access}`
        }
        return config
    }),
    (error) => {
        return Promise.reject(error)
    }
}
setRequestInterceptor()

function setResponseInterceptor() {
    axiosInstance.interceptors.response.use(response => {
        showMessage({
            message: `Success (${response.config.url?.split(BASE_URL)[1]})`,
            type: 'success',
            icon: 'success',
        })
        return response
    } , error => {
        console.log(error)
        // sentryHelpers(error)
        showMessage({
            message: `Error: ${error.message} (${error.config.url.split(BASE_URL)[1]})`,
            type: 'danger',
            icon: 'auto',
        })
        if (error.response.status === 401) {
            AsyncStorage.removeItem('tokens')
            authHelpers.logUserOut()
        }
        return Promise.reject(error)
    } )
}
// setResponseInterceptor()

export default axiosInstance