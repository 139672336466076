import * as React from 'react'
import { View, StyleSheet, TouchableOpacity, Alert } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'

import TextLabel from 'src/app/components/TextLabel'
import * as helpers from 'src/time/helpers'
import { useSelector } from 'react-redux'

export default function ActiveTaskBar() {
    const activeTaskId = useSelector((state: RootState) => state.todos.userProfile.active_task)

    if (!activeTaskId) return null

    const activeTaskStartTime = useSelector((state: RootState) => state.todos.userProfile.active_task_start_time)
    const task = useSelector((state: RootState) => state.todos.tasks.find((task: Task) => task.id === activeTaskId))
    const list = useSelector((state: RootState) => state.todos.lists.find((list: List) => list.id === task?.list))

    async function handleTaskStop() {
        // Calculate stop time as nearest 0, 15, 30, 45 minute mark before current time
        const now = new Date()
        const minutes = now.getMinutes()
        const nearestQuarterHour = Math.floor(minutes / 15) * 15
        const stopTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), nearestQuarterHour, 0, 0)

        // Clear active task
        // @ts-ignore
        await helpers.userProfilePatch({ active_task: null, active_task_start_time: null })

        // Save new time Log
        let duration = `${Math.floor((new Date(stopTime).getTime() - new Date(activeTaskStartTime).getTime()) / 1000 / 60)}:00`
        if (duration == '0:00') duration = '15:00'

        // @ts-ignore
        const timeLogFields: TimeLog = {
            start_date: activeTaskStartTime.split('T')[0],
            start_time: activeTaskStartTime.split('T')[1].split('.')[0],
            duration: duration,
            title: '',
            notes: '',
            source_type: 'task',
            source_id: task.id,
        }

        await helpers.timeLogPost(timeLogFields)
    }
    
    async function handleTaskClear() {
        // @ts-ignore
        await helpers.userProfilePatch({ active_task: null, active_task_start_time: null })
    }

    async function handleNewStartTime(newStartTime: string) {
        // @ts-ignore
        await helpers.userProfilePatch({ active_task_start_time: newStartTime })
    }

    function handleEditActiveTask() {
        Alert.prompt(
            "Start time",
            "Enter start time in format HH:MM",
            [
              {
                text: "Cancel",
                onPress: () => {},
                style: "cancel"
              },
              {
                text: "OK",
                onPress: (newStartTime: string) => handleNewStartTime(newStartTime)
              }
            ],
            'plain-text',
            activeTaskStartTime
          )
    }

    function StopTaskIcon() {
        return <MaterialCommunityIcons name="stop-circle-outline" size={32} color="orange" />
    }

    function ClearTaskIcon() {
        return <MaterialCommunityIcons name="delete-circle-outline" size={32} color="orange" />
    }

    return (
        <View style={styles.activeTaskBarContainer}>
            <View style={styles.titleContainer}>
                <TextLabel extraStyles={{fontSize: 16}} text={'⏳ '+ task?.name} onPress={() => handleEditActiveTask()} />
                <TouchableOpacity
                    style={styles.clearButtonContainer}
                    onPress={() => handleTaskClear()}
                    // onLongPress={() => handleToggleFlag(item)}
                >
                    <ClearTaskIcon />
                </TouchableOpacity>
                <TouchableOpacity
                        style={styles.stopButtonContainer}
                        onPress={() => handleTaskStop()}
                        // onLongPress={() => handleToggleFlag(item)}
                >
                    <StopTaskIcon />
                </TouchableOpacity>
            </View>
            <View style={styles.footerContainer}>
                <TextLabel 
                    extraStyles={styles.footerText} 
                    text={` List: ${list?.name} • Start: ${activeTaskStartTime.split('T')[1].split('.')[0].split('+')[0]}`} 
                />
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    activeTaskBarContainer: {
        // flex: 1, 
        // flexDirection: 'column',
        // backgroundColor: 'grey',
        borderColor: '#777',
        borderBottomWidth: 1,
        borderTopWidth: 1,
        paddingTop: 4,
        paddingBottom: 4,
        height: 64,
        shadowColor: "#000000",
        shadowOpacity: 0.2,
        shadowRadius: 16,
        marginBottom: 8,
    },
    clearButtonContainer: {
        // flexDirection: 'row',
        // alignItems: 'center',
        // justifyContent: 'center',
        width: 32,
        marginRight: 8,
    },
    footerContainer: {
        paddingLeft: 4,
        // marginTop: 12
    },
    footerText: {
        fontSize: 12,
        color: '#777'
    },
    stopButtonContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 8,
        width: 32,
    },
    titleContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
})
