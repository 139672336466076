import * as React from 'react'
import { useRef } from 'react'
import { NavigationContainer } from '@react-navigation/native'
import { useSelector } from 'react-redux'
import * as Notifications from 'expo-notifications'
import Constants from 'expo-constants'
import { Platform, Text } from 'react-native'

import AuthNavigator from '../../auth/components/RootNavigator'
import AppNavigator from '../components/RootNavigator'
import * as helpers from '../helpers'


Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: false,
  }),
})

function LoadedApp() {
    console.log('LoadedApp mounted')
    const loginStatus = useSelector(state => state.auth.loginStatus)
    console.log('loginStatus', loginStatus)
    const [notification, setNotification]: any = React.useState(null)
    const notificationListener: any = useRef()
    const responseListener: any = useRef()

    React.useEffect(() => {
      registerForPushNotificationsAsync().then(token => {
          // console.log('Push token:', token)
          if (token) helpers.processPushToken(token)

          notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
            setNotification(notification)
            // console.log('notification', notification)
          })
          responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
            // Navigate to a screen depending on the notification type
            // console.log(response)
          })
          return () => {
            Notifications.removeNotificationSubscription(notificationListener)
            Notifications.removeNotificationSubscription(responseListener)
          }
      })
    }, [])

    return (
        <NavigationContainer>
          {loginStatus === 'LOGGEDIN' ? <AppNavigator /> : <AuthNavigator/>}
        </NavigationContainer>
    )
}

async function registerForPushNotificationsAsync() {
  let token
  if (Constants.isDevice) {
    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync()
      finalStatus = status
    }
    if (finalStatus !== 'granted') {
      console.log('Failed to get push token for push notification!')
      return
    }
    token = (await Notifications.getExpoPushTokenAsync()).data
  } else {
    console.log('Must use physical device for Push Notifications')
  }

  if (Platform.OS === 'android') {
    Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    })
  }

  return token
}

export default LoadedApp