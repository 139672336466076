import * as React from 'react'
import { Text, ScrollView, TouchableOpacity, View, SectionList, SafeAreaView, StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'
import { TabActions } from '@react-navigation/native'
import { MaterialCommunityIcons } from '@expo/vector-icons'

import Chip from 'src/app/components/Chip'
import { RootState } from 'src/app/redux/store'
import * as TasksNavigator from '../../components/TasksNavigator'
import * as RootNavigator from '../../components/RootNavigator'
import {SCREENS} from '../../components/TasksNavigator'
import * as todosHelpers from '../../helpers'
import TaskItem from 'src/time/components/TaskItem'

function ifOverdue(task: Task): boolean {
    const taskDueDate = new Date(task.due_date) // task's ISO format date at 12 AM
    return taskDueDate < new Date(new Date().toISOString().slice(0,10)) // Get today's date at 12 AM 
}

function handleTaskCompletion(item: Task) {
    todosHelpers.taskPatch({ completed: true }, item.id)
}

const Item = ({item, navigation}) => (
    <TouchableOpacity
        onPress={() => {
            TasksNavigator.navigate(TasksNavigator.SCREENS.ADD_EDIT_TASK, { id: item.id, list: item.list })
            navigation.dispatch(TabActions.jumpTo(RootNavigator.SCREENS.TASKS))
        }}
        style={{
            padding: 8,
            borderBottomWidth: 1,
            borderBottomColor: '#ccc'
        }}
        key={item.id}
    >
        <View style={styles.taskItemBodyContainer}>
            <TouchableOpacity 
                style={styles.checkboxContainer}
                onPress={() => handleTaskCompletion(item)}
            >
                {item.completed 
                    ? <MaterialCommunityIcons name="checkbox-marked-circle" size={32} color="black" /> 
                    : <MaterialCommunityIcons name="checkbox-blank-circle-outline" size={32} color="black" />
                }
            </TouchableOpacity>
            <View style={styles.titleContainer}>
                <Text>{item.name}</Text>
            </View>
            {item.duration && 
                <View style={styles.secondaryItemContainer}>
                    <Chip text={todosHelpers.getFormattedDuration(item.duration)} />
                </View>    
            }
            {item.flagged && 
                <View style={styles.flagContainer}>
                    <Chip text={'🚩'} />
                </View>    
            }
        </View>
    </TouchableOpacity>
)

export default function Overdue({ navigation }: { navigation: any }) {
    const allTasks = useSelector((state: RootState) => state.todos.tasks)
    const tasks = allTasks
        .filter((t: Task) => t.due_date)
        .filter((t: Task) => !t.completed)
        .filter((t: Task) => ifOverdue(t))
        .sort((a, b) => a.due_date.localeCompare(b.due_date))
        .reduce((re, o) => {  
            let existObj = re.find(obj => obj.title === o.due_date)
            if (existObj) { existObj.data.push(o) } else { re.push({title: o.due_date, data: [o]}) }
            return re
          }, [])
    const lists = useSelector((state: RootState) => state.todos.lists)

    function ListItem({ item }: { item: Task }) {
        const dependencyTaskName = item.dependency_task ? tasks.find((t: Task) => t.id === item.dependency_task).name : ''
        const parentTaskName = item.parent_task ? allTasks.find((t: Task) => t.id === item.parent_task)?.name : ''
        return (
            <TaskItem
                item={item}
                parentTaskName={parentTaskName}
                dependencyTaskName={dependencyTaskName}
                rootNavigation={navigation}
                from={'Overdue'}
            />
        )
    }

    return (
        <SafeAreaView>
            <ScrollView>
                <SectionList
                    sections={tasks}
                    keyExtractor={(item, index) => item.id}
                    renderItem={ListItem}
                    renderSectionHeader={({ section: { title } }) => (
                        <Text style={styles.sectionHeader}>{title}</Text>
                    )}
                />
            </ScrollView>
        </SafeAreaView>
    )
}

// Styles for SectionList component for list of tasks
const styles = StyleSheet.create({
    sectionHeader: {
      paddingTop: 4,
      paddingLeft: 10,
      paddingRight: 10,
      paddingBottom: 4,
      fontSize: 16,
      fontWeight: 'bold',
      backgroundColor: '#ccc',
    },
    item: {
      padding: 10,
      fontSize: 18,
      backgroundColor: 'purple',
      borderWidth: 1
    },
    checkboxContainer: {
        marginRight: 10
    },
    flagContainer: {
        marginRight: 4
    },
    taskItemBodyContainer: {
        flex: 1, 
        flexDirection: 'row'
    },
    titleContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    secondaryItemContainer: {
        marginRight: 4
    },
  })
