import React from 'react'
import {Text} from 'react-native'

import styles from './styles'

export default function TextLabel({text}) {
    return (        
        <Text style={styles.base}> {text} </Text>
    )
}
