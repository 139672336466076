import * as React from 'react'
import { FlatList, ScrollView, View, StyleSheet, TouchableOpacity, Text } from 'react-native'
import { useSelector } from 'react-redux'
import { HoldItem } from 'react-native-hold-menu'

import Button from 'src/app/components/Button'
import TextLabel from 'src/app/components/TextLabel'
import { SCREENS } from 'src/time/components/TasksNavigator'
import store from 'src/app/redux/store'
import * as helpers from 'src/time/helpers'
import * as TasksNavigator from '../../components/TasksNavigator'
import ActiveTaskBar from 'src/time/components/ActiveTaskBar'


export default function Folders({ navigation }) {
    const planningMode = useSelector((state: RootState) => state.todos.appState.planningMode)
    const folders = useSelector(state => state.todos.folders)
    const lists = useSelector(state => state.todos.lists)
    const tasks = useSelector(state => state.todos.tasks)
    const default_list = useSelector(state => state.todos.userProfile.default_list)
    const activeTaskId = useSelector((state: RootState) => state.todos.userProfile.active_task)

    const [inboxCount, setInboxCount] = React.useState(0)

    React.useEffect(() => {
        setInboxCount(tasks.filter((task: Task) => task.list === default_list && !task.completed).length)
    } , [tasks])

    const getUnscheduledTasksCountInFolder = (folder: Folder): number => {
        const folderTasks = helpers.getUnplannedTasks(tasks, lists, undefined, folder)
        return folderTasks.length
    }

    const planningFolders = folders.filter((folder: Folder) => {
            return getUnscheduledTasksCountInFolder(folder) > 0
    })

    const renderItem = ({ item: folder }: {item: Folder}) => {
        const activeListsCount = lists.filter((list: List) => list.folder === folder.id && !list.archived).length
        const unscheduledTasksCount = getUnscheduledTasksCountInFolder(folder)

        const MenuItems = [
            {
                text: 'Edit folder',
                onPress: () => {
                    TasksNavigator.navigate(SCREENS.FOLDER_ADD_EDIT, { id: folder.id })
                },
            }
        ]

        return (
            <View style={styles.taskContainer}>
                <HoldItem items={MenuItems}>
                    <TouchableOpacity
                        onPress={() => navigation.navigate(SCREENS.LISTS, { id: folder.id })}
                        key={folder.id}
                    >
                        <View style={styles.titleContainer}>
                            <TextLabel extraStyles={{fontSize: 16}} text={'📂 '+folder.name} />
                        </View>
                    </TouchableOpacity>
                    <View style={styles.footerContainer}>
                        <TextLabel
                            extraStyles={styles.footerText}
                            text={`${activeListsCount} active lists. ${unscheduledTasksCount} unplanned tasks.`}
                        />
                    </View>
                </HoldItem>
            </View>
        )
    }

    return (
        <>
            {activeTaskId && <ActiveTaskBar />}
            <View style={styles.header}>
            <Button
                    text='+'
                    primary
                    onPress={() => navigation.navigate(SCREENS.FOLDER_ADD_EDIT, { id: null })}
                    extraStyle={{minWidth: 48}}
                />
                <Button
                    text={`📥 (${inboxCount})`}
                    onPress={() => navigation.navigate(SCREENS.LIST, { id: default_list })}
                />
                <Button
                    text={planningMode ? `🎯 ✅` : `🎯🧐`}
                    onPress={() => store.dispatch({ type: 'TOGGLE_PLANNING_MODE' })}
                    extraStyle={{minWidth: 48}}
                />
            </View>
            <ScrollView>
                <View>
                    <Text
                        style={{
                            textAlign: 'center',
                            fontSize: 24,
                            paddingTop: 16,
                            margin: 12,
                            color: '#777'
                        }}
                    >
                        {`${planningFolders.length > 0 ? planningFolders.length : '😌 Zero'} Unplanned Folder(s)!`}
                    </Text>
                </View>
                <FlatList
                    data={planningMode ? planningFolders : folders}
                    keyExtractor={(item) => item.id}
                    renderItem={renderItem}
                />
            </ScrollView>
        </>
    )
}

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    modalView: {
        backgroundColor: "white",
        borderRadius: 20,
        padding: 4,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        width: '80%',
        height: '50%'
    },
    checkboxContainer: {
        marginRight: 10
    },
    flagContainer: {
        marginRight: 4
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
    },
    taskItemBodyContainer: {
        flex: 1,
        flexDirection: 'row'
    },
    titleContainer: {
        // backgroundColor: 'pink',
        paddingLeft: 4,
        paddingRight: 4,
        paddingTop: 4
    },
    footerContainer: {
        // backgroundColor: 'yellow',
        paddingLeft: 4
    },
    footerText: {
        fontSize: 12,
        color: '#777'
    },
    taskContainer: {
        flex: 1,
        flexDirection: 'column',
        // backgroundColor: 'red',
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
        paddingTop: 4,
        paddingBottom: 4
    },
})