import * as React from 'react'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { FontAwesome } from '@expo/vector-icons'
import { AppState } from 'react-native'

import TasksNavigator from './TasksNavigator'
import Due from '../screens/Due'
import Overdue from '../screens/Overdue'
import * as helpers from '../helpers'
import SwitchApp from 'src/app/screens/SwitchApp'
import { getCurrentDate } from '../screens/Due/helpers'
import { useSelector } from 'react-redux'
import { RootState } from 'src/app/redux/store'
import * as dueDateHelpers from 'src/time/screens/Due/helpers'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { showMessage } from 'react-native-flash-message'
import { createStackNavigator } from '@react-navigation/stack'


const Stack = createStackNavigator()

export const SCREENS = {
    TASKS: 'Tasks',
    DUE: 'Due',
    OVERDUE: 'Overdue',
}

export default function TimeNavigator({ navigation }: { navigation: any }) {
    const allTasks = useSelector((state: RootState) => state.todos.tasks)
    // console.log('TimeNavigator mounted')

    React.useEffect(() => {
        // helpers.setUpWebSocket()

        async function getData() {
            // get tokens from async storage
            console.log('Getting data in TimeNavigator')
            await helpers.userProfileGet()
            // await helpers.pushNotificationMetadataGet(accessToken)
            // await helpers.foldersGet()
            // await helpers.listsGet()
            await helpers.tasksGet()
            // await helpers.timeLogsGet(accessToken)
        }
        getData()
    }, [])

    React.useEffect(() => {
        AppState.addEventListener("change", nextAppState => {
            if (nextAppState === "active") { // TODO not sure if this is only running when app is in foreground
                helpers.remindersImport()
            }
        })
    }, [])

    const tasksDueTodayCount = function (): number {
        // Get current date in string format and return count of tasks due today
        const currentDate = dueDateHelpers.getCurrentDate()
        return allTasks.filter((task: Task) => task.due_date === currentDate && !task.completed).length
    }

    function getDueTabOptions() {
        const options: Object = { tabBarIcon: () => (<FontAwesome name="calendar-check-o" size={20} />) }  
        // const count: number = tasksDueTodayCount()
        // if (count > 0) {
        //         options.tabBarBadge = count
        //         options.tabBarBadgeStyle = { backgroundColor: 'red', maxWidth: 32 }
        // }
        return options
    }

    const tasksOverdueTodayCount = function (): number {
        // Get current date in string format and return count of tasks due today
        const currentDate = dueDateHelpers.getCurrentDate()
        const count = allTasks.filter((task: Task) => task.due_date < currentDate && !task.completed).length
        return count
    }

    function getOverdueTabOptions() {
        const options: Object = { tabBarIcon: () => (<FontAwesome name="warning" size={20} />) }  
        // const count: number = tasksOverdueTodayCount()
        // if (count > 0) {
        //         options.tabBarBadge = count
        //         options.tabBarBadgeStyle = { backgroundColor: 'red', maxWidth: 32 }
        // }
        return options
    }

    return (
        <Stack.Navigator
            initialRouteName={SCREENS.TASKS}
            screenOptions={{
                headerShown: false,
            }}
        >
            <Stack.Screen
                name={SCREENS.TASKS}
                component={TasksNavigator}
                initialParams={{ appNavigation: navigation }}
                // options={{
                //     tabBarIcon: () => (<FontAwesome name="list" size={20} />),
                // }}
            />
            <Stack.Screen
                name={SCREENS.DUE}
                component={Due}
                // options={getDueTabOptions()}
            />
            <Stack.Screen
                name={SCREENS.OVERDUE}
                component={Overdue}
                // options={getOverdueTabOptions()}
            />
            <Stack.Screen
                name='Apps'
                component={SwitchApp}
                initialParams={{ appNavigation: navigation }}
                // options={{ tabBarIcon: () => (<FontAwesome name="th-large" size={20} />) }}

            />
        </Stack.Navigator>
    )
}