import React from 'react'
import { SafeAreaView } from 'react-native'

import { APPS } from '../components/RootNavigator'
import Button from '../components/Button'


export default function SwitchApp({ route }) {
    const { appNavigation } = route.params

    return (<SafeAreaView>
        {
            Object.keys(APPS).map(app => (
                <Button
                    key={app}
                    text={APPS[app]}
                    onPress={() => appNavigation.navigate(APPS[app])}
                    extraStyles={{
                        margin: 10,
                        padding: 10,
                        borderRadius: 4,
                        backgroundColor: '#fff',
                        borderColor: '#000',
                        borderWidth: 1,
                    }}
                />
            ))
        }
    </SafeAreaView>)
}