import React from 'react'
import { TextInput as RNTextInput, Text, View } from 'react-native'

import styles from './styles'

export default function TextInput({
    multiline = false,
    onChangeText,
    placeholder,
    secureTextEntry = false,
    value,
    extraStyle = {}
}: {
    multiline?: boolean,
    onChangeText: (text: string) => void,
    placeholder?: string,
    secureTextEntry?: boolean,
    value: string,
    extraStyle?: object
}) {
    return (
        <View>
            <RNTextInput
                autoCapitalize='none'
                multiline={multiline}
                onChangeText={onChangeText}
                placeholder={placeholder}
                secureTextEntry={secureTextEntry}
                style={[styles.base, multiline ? styles.multiline : styles.monoline, extraStyle]}
                value={value}
            />
        </View>
    )
}
