import * as React from 'react'
import { Text, ScrollView, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'

import { RootState } from 'src/app/redux/store'
import { SCREENS } from 'src/time/components/TasksNavigator'
import Chip from 'src/app/components/Chip'

export default function List({ navigation, route }) {
    const { listId } = route.params
    const tasks = useSelector((state: RootState) => state.todos.tasks.filter((t: Task) => t.list === listId && t.completed))

    return (
        <ScrollView>
            {tasks
                .sort((a: Task, b: Task) => b.due_date.localeCompare(a.due_date))
                .map((item: Task) =>
                (
                    <TouchableOpacity
                        onPress={() => navigation.navigate(SCREENS.ADD_EDIT_TASK, { id: item.id })}
                        style={{
                            padding: 10,
                            borderBottomWidth: 1,
                            borderBottomColor: '#ccc'
                        }}
                        key={item.id}
                    >
                        <View>
                            <Text>{item.name}</Text>
                            {item.due_date && <Chip text={item.due_date} />}
                            {item.flagged && <Chip text={'🚩'} />}
                        </View>
                    </TouchableOpacity>
                ))}
        </ScrollView>
    )
}