import * as React from 'react'
import { useState } from 'react'
import { View } from 'react-native'

import * as auth from '../../helpers'
import styles from './style'

import Button from 'src/app/components/Button'
import TextInput from 'src/app/components/TextInput'

export default function Login({ navigation }) {
    const [username, updateUsername] = useState('')
    const [password, updatePassword] = useState('')

    return (
        <View style={styles.container}>
            <TextInput
                value={username}
                onChangeText={(text) => updateUsername(text)}
                placeholder="Username"
            />
            <TextInput
                value={password}
                onChangeText={(text) => updatePassword(text)}
                placeholder="Password"
                secureTextEntry
            />
            <Button
                text="Login"
                onPress={() => auth.logUserIn({ username, password })}
                primary
                extraStyle={{ marginTop: 20 }}
            />
            {/* <Button
                text="Sign Up"
                onPress={() => navigation.navigate('Sign Up')}
            /> */}
        </View>
    )
}

