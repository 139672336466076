import cronParser from 'cron-parser'


export const getCurrentDate = (): string => {
    const today = new Date()
    return today.toISOString().split('T')[0]
}

export const ifDue = (taskDueDate?: string, cronSchedule?: string, userDate?: string): boolean => {
    // Calculate if task due date is today
    if (taskDueDate) {
        const dueDate = new Date(taskDueDate)
        let today = userDate ? new Date(userDate) : new Date()
        return dueDate.getDate() === today.getDate()
            && dueDate.getMonth() === today.getMonth()
            && dueDate.getFullYear() === today.getFullYear()
    }

    if (cronSchedule) {
        if (userDate && userDate < getCurrentDate()) return false
        const startDate = userDate ? new Date(userDate) : new Date()

        // Set current time to zero
        startDate.setHours(0, 0, 0, 0)

        const options = {
            currentDate: startDate,
            endDate: new Date(startDate.getTime() + new Date(86400000).getTime()),
            iterator: true,
        }
        try {
            const iterator = cronParser.parseExpression(cronSchedule, options)
            const next = iterator.next().value
            if (next) return true
            return false
        } catch (e) {
            return false
        }
    }

    return false
}

export const isValidDate = (dateString: string | null) => {
    if (!dateString) return false // falsy input
    var regEx = /^\d{4}-\d{2}-\d{2}$/
    if (!dateString.match(regEx)) return false  // Invalid format
    var d = new Date(dateString)
    var dNum = d.getTime()
    if (!dNum && dNum !== 0) return false // NaN value, Invalid date
    return d.toISOString().slice(0, 10) === dateString
}