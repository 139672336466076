import React from 'react'
import { TouchableOpacity, Text, View } from 'react-native'

import styles from './styles'

export default function Button({
    disabled = false,
    extraStyle = {},
    onPress,
    onLongPress,
    primary = false,
    text,
}: {
    disabled?: boolean
    extraStyle?: any
    onPress: () => void
    onLongPress?: () => void
    primary?: boolean
    text: string
    }) {
    return (
        <TouchableOpacity onPress={onPress} disabled={disabled} onLongPress={onLongPress}>
            <View style={primary ? [styles.baseCommon, styles.basePrimary, extraStyle] : [styles.baseCommon, styles.baseSecondary, extraStyle]}>
                {text && <Text style={primary ? styles.textPrimary : styles.textSecondary}> {text} </Text>}
            </View>
        </TouchableOpacity>
    )
}
