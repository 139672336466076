import * as React from 'react'
import { Text, StyleSheet, TouchableOpacity, View, SectionList } from 'react-native'
import { useSelector } from 'react-redux'
import { MaterialCommunityIcons } from '@expo/vector-icons'

import Button from 'src/app/components/Button'
import Chip from 'src/app/components/Chip'
import { RootState } from 'src/app/redux/store'
import * as helpers from '../../helpers'
import { SCREENS } from 'src/time/components/TasksNavigator'
import Modal from 'src/app/components/Modal'
import TextInput from 'src/app/components/TextInput'


const SORT_OPTIONS = {
    name: 'Name',
    due_date: 'Due', // Ascending
}
const DEFAULT_SORT_OPTION = 'due_date'

export default function TimeLogsList({ navigation, route }) {
    const { source_type, source_id } = route.params

    // Get timeLogs from redux store and restructure them into sections
    const timeLogs = useSelector((state: RootState) => state.todos.timeLogs)
                        .filter((t: TimeLog) => t.source_id === source_id)
                        .sort((t1: TimeLog, t2: TimeLog) => t2.start_date.localeCompare(t1.start_date))
                        .reduce((re, o) => {  
                            let existObj = re.find(obj => obj.start_date === o.start_date)
                            if (existObj) { existObj.data.push(o) } else { re.push({title: o.start_date, data: [o]}) }
                            return re
                          }, [])
    
    const [deleteModalVisible, setDeleteModalVisible] = React.useState(false)


    function Item({ item }: { item: TimeLog }) {
        return (
            <TouchableOpacity
                onPress={() => navigation.navigate(SCREENS.TIME_LOG_ADD_EDIT, { id: item.id })}
                style={{
                    padding: 8,
                    borderBottomWidth: 1,
                    borderBottomColor: '#ccc'
                }}
                key={item.id}
            >
                <View style={styles.taskItemBodyContainer}>
                    <View style={styles.titleContainer}>
                        <Text>{'Time: ' + item.start_time + '. Duration (min): ' + item.duration}</Text>
                    </View>
                </View>
            </TouchableOpacity>
        )
    }

    return (
        <>
            <View
                style={styles.header}
            >
                {/* <Button
                    text='➕'
                    onPress={() => navigation.navigate(SCREENS.TIME_LOG_ADD_EDIT, { source_type, source_id })}
                    primary
                    extraStyle={{ minWidth: 48 }}
                /> */}
                <Modal
                    visible={deleteModalVisible}
                    onRequestClose={() => setDeleteModalVisible(false)}
                >
                    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                        <Text>Are you sure you want to delete all logs for this source?</Text>
                        {/* <Button
                            text='Delete'
                            onPress={() => {
                                helpers.timeLogsDelete({ source_type, source_id })
                                setDeleteModalVisible(false)
                                navigation.navigate.goBack()
                            }}
                        /> */}
                        <Button
                            text='Cancel'
                            onPress={() => setDeleteModalVisible(false)}
                        />
                    </View>
                </Modal>
            </View>
            {/* <FlatList
                data={timeLogs}
                renderItem={renderListItem}
                keyExtractor={(item: Task) => item.id.toString()}
            /> */}
            {
                <SectionList
                    sections={timeLogs}
                    keyExtractor={(item, index) => item.id}
                    renderItem={({ item }) => <Item item={item} navigation={navigation} />}
                    renderSectionHeader={({ section: { title } }) => (
                        <Text style={styles.sectionHeader}>{title}</Text>
                    )}
                />
            }
        </>
    )
}

const styles = StyleSheet.create({
    checkboxContainer: {
        marginRight: 10
    },
    flagContainer: {
        marginRight: 4
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    sectionHeader: {
        paddingTop: 4,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 4,
        fontSize: 16,
        fontWeight: 'bold',
        backgroundColor: '#ccc',
      },
    taskItemBodyContainer: {
        flex: 1,
        flexDirection: 'row'
    },
    titleContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
})