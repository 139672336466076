import { Dimensions, StyleSheet } from "react-native"

const width = Dimensions.get('window').width

export default StyleSheet.create({
  base: {
    margin: 2,
    padding: 2,
    backgroundColor: '#a5e0b4',
    borderRadius: 4,
    alignSelf: 'flex-start',
  }
})
