import { Dimensions, StyleSheet } from "react-native"

const width = Dimensions.get('window').width

export default StyleSheet.create({
  base: {
    borderRadius: 8,
    margin: 4,
    padding: 8,
    backgroundColor: '#ddd'
  },
  monoline: {
    height: 40
  },
  multiline: {
    height: 128
  },
})
