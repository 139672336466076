import { Dimensions, StyleSheet } from "react-native"

const width = Dimensions.get('window').width

export default StyleSheet.create({
  baseCommon: {
    height: 40,
    borderRadius: 8,
    margin: 4,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 6
  },
  basePrimary: {
    backgroundColor: '#00a8ff'
  },
  baseSecondary: {
    backgroundColor: '#ccc'
  },
  baseDanger: {
    backgroundColor: '#ff0000'
  },
  textPrimary: {
    color: '#fff'
  },
  textSecondary: {
    color: '#000'
  },
})
