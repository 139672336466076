import * as React from 'react'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { FontAwesome } from '@expo/vector-icons'
import { AppState } from 'react-native'
import { useSelector } from 'react-redux'
import AsyncStorage from '@react-native-async-storage/async-storage'

import KnowledgeNavigator from './KnowledgeNavigator'
import * as helpers from 'src/knowledge/helpers'
import SwitchApp from 'src/app/screens/SwitchApp'
import { RootState } from 'src/app/redux/store'
import Flashcards from 'src/knowledge/screens/Flashcards'

import { showMessage } from 'react-native-flash-message'
import { createStackNavigator } from '@react-navigation/stack'

const Stack = createStackNavigator()

export const SCREENS = {
    KNOWLEDGE: 'Knowledge',
    FLASHCARDS: 'Flashcards',
}

export default function RootNavigator({ navigation }: { navigation: any }) {
    console.log('Knowledge > RootNavigator mounted')

    React.useEffect(() => {
        async function getData() {
            console.log('Getting data in Knowledge > RootNavigator')
            showMessage({ message: 'Getting knowledge content...', type: 'info', icon: 'auto' })
            await helpers.knowledgeContentGet()
        }
        getData()
    }, [])

    React.useEffect(() => {
        AppState.addEventListener("change", nextAppState => {
            if (nextAppState === "active") { // TODO not sure if this is only running when app is in foreground
                // helpers.remindersImport()
            }
        })
    }, [])

    return (
        <Stack.Navigator
            initialRouteName={SCREENS.KNOWLEDGE}
            screenOptions={{
                headerShown: false,
            }}
        >
            <Stack.Screen
                name={SCREENS.KNOWLEDGE}
                component={KnowledgeNavigator}
                initialParams={{ appNavigation: navigation }}
                // options={{
                //     tabBarIcon: () => (<FontAwesome name="list" size={20} />),
                // }}
            />
            {/* <Stack.Screen
                name={SCREENS.FLASHCARDS}
                component={Flashcards}
                // options={getDueTabOptions()}
            />
            <Stack.Screen
                name='Apps'
                component={SwitchApp}
                initialParams={{ appNavigation: navigation }}
                // options={{ tabBarIcon: () => (<FontAwesome name="th-large" size={20} />) }}

            /> */}
        </Stack.Navigator>
    )
}