import * as React from 'react'
import { FlatList, Text, ScrollView, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import Button from 'src/app/components/Button'
import { RootState } from 'src/app/redux/store'
import * as helpers from '../../helpers'
import { SCREENS } from 'src/time/components/TasksNavigator'
import Modal from 'src/app/components/Modal'
import TextInput from 'src/app/components/TextInput'
import TaskItem from 'src/time/components/TaskItem'
import ActiveTaskBar from 'src/time/components/ActiveTaskBar'


const SORT_OPTIONS = {
    name: 'Name',
    due_date: 'Due', // Ascending
}
const DEFAULT_SORT_OPTION = 'due_date'

export default function List({ navigation, route }) {
    const listId = route.params.id

    const planningMode = useSelector((state: RootState) => state.todos.appState.planningMode)
    const tasks = useSelector((state: RootState) => state.todos.tasks
        .filter((t: Task) => t.list === listId))
    const activeTaskId = useSelector((state: RootState) => state.todos.userProfile.active_task)

    const [deleteModalVisible, setDeleteModalVisible] = React.useState(false)
    const [sortField, setSortField] = React.useState(DEFAULT_SORT_OPTION)
    const [sortFieldModalVisilbe, setSortFieldModalVisible] = React.useState(false)
    const listName = useSelector((state: RootState) => state.todos.lists.find((l: List) => l.id === listId)?.name)
    const [searchTerm, updateSearchTerm] = React.useState('')
    const [flagFilter, setFlagFilter] = React.useState(false)

    React.useEffect(() => { navigation.setOptions({ title: '📋 '+listName }) }, [])

    let sortedTasks = helpers.getSortedList(tasks, sortField, 'STRING')
                            .filter((t: Task) => !t.completed)   
                            .filter((task: Task) => {
                                return !planningMode ? true : !task.due_date && !task.completed && !task.name.includes('♾')
                            })

    let completedTasks = helpers.getSortedList(tasks, sortField, 'STRING', 'DESC')
                            .filter((task: Task) => task.completed)

    async function handleDeleteList() {
        setDeleteModalVisible(false)
        helpers.listDelete(listId)
        navigation.goBack()        
    }

    function ListItem({ item }: { item: Task }) {
        const dependencyTaskName = item.dependency_task ? tasks.find((t: Task) => t.id === item.dependency_task).name : ''
        return (
            <TaskItem
                item={item}
                listName={listName}
                dependencyTaskName={dependencyTaskName}
                navigation={navigation}
            />
        )
    }

    return (
        <>
            {activeTaskId && <ActiveTaskBar />}
            <View
                style={styles.header}
            >
                <Button
                    text='➕'
                    onPress={() => navigation.navigate(SCREENS.ADD_EDIT_TASK, { list: listId })}
                    primary
                    extraStyle={{ minWidth: 48 }}
                />
                <Button
                    text={`Sort: ${SORT_OPTIONS[sortField]}`}
                    onPress={() => setSortFieldModalVisible(true)}
                    extraStyle={{ minWidth: 48 }}
                />
                <Modal
                    onRequestClose={() => setSortFieldModalVisible(false)}
                    visible={sortFieldModalVisilbe}
                >
                    <ScrollView>
                        {Object.keys(SORT_OPTIONS).map((s: string) => (
                            <Button
                                key={s}
                                text={SORT_OPTIONS[s]}
                                onPress={() => {
                                    setSortField(s)
                                    setSortFieldModalVisible(false)
                                }}
                            />
                        ))}
                    </ScrollView>
                    <Button
                        text='Cancel'
                        onPress={() => setSortFieldModalVisible(false)}
                        primary
                    />
                </Modal>
                <Button
                    text='✏️'
                    onPress={() => navigation.navigate(SCREENS.ADD_EDIT_LIST, { id: listId })}
                    extraStyle={{ minWidth: 48 }}
                />
                <Button
                    text='🚩'
                    onPress={() => setFlagFilter(!flagFilter)}
                    extraStyle={{ minWidth: 48 }}
                />
                <Button
                    text='❌'
                    onPress={() => setDeleteModalVisible(true)}
                />
                <Modal
                    visible={deleteModalVisible}
                    onRequestClose={() => setDeleteModalVisible(false)}
                >
                    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                        <Text>Are you sure you want to delete this list?</Text>
                        <Button
                            text='Delete'
                            onPress={() => handleDeleteList()}
                        />
                        <Button
                            text='Cancel'
                            onPress={() => setDeleteModalVisible(false)}
                        />
                    </View>
                </Modal>
            </View>
            <TextInput
                placeholder='Search'
                onChangeText={(text) => updateSearchTerm(text)}
                extraStyle={{
                    margin: 8,
                    padding: 8,
                    borderWidth: 1,
                    borderColor: '#ccc',
                    borderRadius: 8,
                    backgroundColor: '#fff'
                }}
            />
            <ScrollView>
            <FlatList
                data={sortedTasks.filter((t: Task) => {
                    if (flagFilter) {
                        return t.flagged && t.name.toLowerCase().includes(searchTerm.toLowerCase())
                    } else {
                        return t.name.toLowerCase().includes(searchTerm.toLowerCase())
                    }
                })}
                renderItem={ListItem}
                keyExtractor={(item: Task) => item.id.toString()}
            />
            {!planningMode && <Text
                style={{
                    textAlign: 'center',
                    fontSize: 24,
                    paddingTop: 16,
                    margin: 8,
                    color: '#777'
                }}
            >
                Completed
            </Text>}
            {!planningMode && <FlatList
                data={completedTasks.filter((t: Task) => t.name.toLowerCase().includes(searchTerm.toLowerCase()))}
                renderItem={ListItem}
                keyExtractor={(item: Task) => item.id.toString()}
            />}
            </ScrollView>
        </>
    )
}

const styles = StyleSheet.create({
    checkboxContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 8
    },
    contentContainer: {},
    flagContainer: {
        marginRight: 4
    },
    footerContainer: {
        // backgroundColor: 'red',
    },
    footerText: {
        fontSize: 12,
        color: '#777'
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    taskItemBodyContainer: {
        flex: 1,
        flexDirection: 'row'
    },
    titleBodyContainer: {
        flexDirection: 'row',
        // backgroundColor: 'green',
    },
    titleContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
})