import { Dimensions, StyleSheet } from "react-native"

const width = Dimensions.get('window').width

export default StyleSheet.create({
  base: {
    // height: 16,
    margin: 4,
    justifyContent: 'center',
    alignItems: 'center'
  }
})
