
import * as React from 'react'
import { Platform, SafeAreaView, ScrollView, StyleSheet, Switch, Text } from 'react-native'
import { useSelector } from 'react-redux'

import { RootState } from 'src/app/redux/store'
import TextInput from 'src/app/components/TextInput'
import Button from 'src/app/components/Button'
import TextLabel from 'src/app/components/TextLabel'
import Modal from 'src/app/components/Modal'

import * as helpers from '../../helpers'


export default function AddEditList({ navigation, route }) {
    const { id, folderId } = route.params
    const emptyList = {
        name: '',
        description: '',
        cron_schedule: '',
        duration: '',
        sorting_field: '',
        sorting_type: '',
        folder: folderId,
        archived: false,
    }
    const list = useSelector((state: RootState) => state.todos.lists.find((list: List) => list.id === id))
    const folders = useSelector((state: RootState) => state.todos.folders)
    const [listFields, updateListFields] = React.useState(id ? list : emptyList)
    const [folderModalVisible, setFolderModalVisible] = React.useState(false)

    const handleSubmit = async () => {
        const listFieldsFormatted = JSON.parse(JSON.stringify(listFields))
        if (listFields.name === '') listFieldsFormatted.name = null
        if (listFields.duration === '') listFieldsFormatted.duration = null
        if (id) {
            helpers.listPatch(listFieldsFormatted, id)
        } else {
            helpers.listPost(listFieldsFormatted)
        }
        navigation.goBack()
    }

    return (
        <>
            <ScrollView>
                <Text>{id ? 'Edit' : 'Add'} List</Text>
                <Text>Name: </Text>
                <TextInput
                    value={listFields.name}
                    onChangeText={(name: string) => updateListFields({ ...listFields, name })}
                />
                <Text>Description: </Text>
                <TextInput
                    value={listFields.description}
                    onChangeText={(description: string) => updateListFields({ ...listFields, description })}
                    multiline
                />
                <Text>Cron Schedule: </Text>
                <TextInput
                    value={listFields.cron_schedule}
                    onChangeText={(cron_schedule: string) => updateListFields({ ...listFields, cron_schedule })}
                />
                <Text>Duration: </Text>
                <TextInput
                    value={listFields.duration}
                    onChangeText={(duration: string) => updateListFields({ ...listFields, duration })}
                    placeholder="hh:mm:ss"
                />
                <Text>Sorting Field: </Text>
                <TextInput
                    value={listFields.sorting_field}
                    onChangeText={(sorting_field: string) => updateListFields({ ...listFields, sorting_field })}
                    placeholder='name | created_at | updated_at'
                />
                <Text>Sorting Type: </Text>
                <TextInput
                    value={listFields.sorting_type}
                    onChangeText={(sorting_type: string) => updateListFields({ ...listFields, sorting_type })}
                    placeholder='asc | desc'
                />
                <Text>Archived: </Text>
                {Platform.OS === 'web'
                    ? <input type="checkbox" checked={listFields.archived} onChange={() => updateListFields({ ...listFields, archived: !listFields.archived })} />
                    : <Switch value={listFields.archived} onValueChange={() => updateListFields({ ...listFields, archived: !listFields.archived })} />

                }
                <TextLabel text='Folder:' />
                <Button onPress={() => setFolderModalVisible(true)} text={folders.find((f: Folder) => f.id === listFields.folder).name} />
                <Modal
                    onRequestClose={() => setFolderModalVisible(false)}
                    visible={folderModalVisible}
                >
                    <ScrollView>
                        {folders.map((f: List) => (
                            <Button
                                key={f.id}
                                text={f.name}
                                onPress={() => {
                                    updateListFields({ ...listFields, folder: f.id })
                                    setFolderModalVisible(false)
                                }}
                            />
                        ))}
                    </ScrollView>
                    <Button
                        text='Cancel'
                        onPress={() => setFolderModalVisible(false)}
                        primary
                    />
                </Modal>
                <Button
                    text={id ? 'Update' : 'Add'}
                    onPress={() => handleSubmit()}
                />
            </ScrollView>
        </>
    )
}

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    modalView: {
        backgroundColor: "white",
        borderRadius: 20,
        padding: 4,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        width: '80%',
        height: '50%'
    }
})