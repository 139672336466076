import * as React from 'react'
import { NavigationContainer, createNavigationContainerRef, TabActions } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import { Text, TouchableOpacity, View } from 'react-native'
import { HeaderBackButton } from '@react-navigation/elements'

import KnowledgeContent from '../screens/KnowledgeContent'
import UserOptions from '../screens/UserOptions'
import * as helpers from '../helpers'
import AddEditContent from '../screens/AddEditContent'

export const SCREENS = {
    KNOWLEDGE_CONTENT: 'Knowledge',
    USER_OPTIONS: 'User Options',
}

const Stack = createStackNavigator()
const navigationRef = createNavigationContainerRef()

export function navigate(name, params) {
    if (navigationRef.isReady()) {
        navigationRef.navigate(name, params);
    }
}

export default function KnowledgeNavigator({ route }) {
    const { appNavigation } = route.params
    // function handleBackNavigation(navigation: any, route: any) {
    //     switch (route.params.from) {
    //         case 'Due':
    //             route.params.rootNavigation?.dispatch(TabActions.jumpTo('Due'))
    //             break
    //         case 'Overdue':
    //             route.params.rootNavigation?.dispatch(TabActions.jumpTo('Overdue'))
    //             break
    //         default:
    //             navigation.goBack()
    //     }
    // }
    
    return (
        <NavigationContainer
            independent={true}
            ref={navigationRef}
        >
            <Stack.Navigator
                initialRouteName={SCREENS.KNOWLEDGE_CONTENT}
                screenOptions={{
                    headerShown: false,
                    // headerRight: () => <HeaderOptions />
                }}
            >
                <Stack.Group>
                <Stack.Screen
                        name={SCREENS.KNOWLEDGE_CONTENT}
                        component={KnowledgeContent}
                    />
                    <Stack.Screen
                        name={SCREENS.USER_OPTIONS}
                        component={UserOptions}
                        initialParams={{ appNavigation: appNavigation }}
                    />
                </Stack.Group>
            </Stack.Navigator>
        </NavigationContainer>
    )
}

function HeaderOptions() {
    return (
        <View style={{flexDirection: 'row'}}>
            <TouchableOpacity
                style={{
                    width: 32,
                    height: 32,
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#ccc',
                    marginVertical: 8,
                    marginHorizontal: 2,
                    borderRadius: 16
                }}
            >
                <Text
                    onPress={() => navigate(SCREENS.USER_OPTIONS, {})}
                >
                    👤
                </Text>
            </TouchableOpacity>
        </View> 
    )
}