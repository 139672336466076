
import * as React from 'react'
import { SafeAreaView, ScrollView, Text } from 'react-native'
import { useSelector } from 'react-redux'

import { RootState } from 'src/app/redux/store'
import TextInput from 'src/app/components/TextInput'
import Button from 'src/app/components/Button'

import * as helpers from '../../helpers'


export default function FolderAddEdit({ navigation, route }) {
    const { id } = route.params
    const emptyFolder = {
        name: '',
        description: ''
    }
    const folder = useSelector((state: RootState) => state.todos.folders.find((folder: Folder) => folder.id === id))
    const [folderFields, updateFolderFields] = React.useState(id ? folder : emptyFolder)

    const handleSubmit = async () => {
        if (id) {
            helpers.folderPatch(folderFields, id)
        } else {
            helpers.folderPost(folderFields)
        }
        navigation.goBack()
    }

    return (
        <SafeAreaView>
            <ScrollView>
                <Text>{id ? 'Edit' : 'Add'} Folder</Text>
                <Text>Name: </Text>
                <TextInput
                    value={folderFields.name}
                    onChangeText={(name: string) => updateFolderFields({ ...folderFields, name })}
                />
                <Text>Description: </Text>
                <TextInput
                    value={folderFields.description}
                    onChangeText={(description: string) => updateFolderFields({ ...folderFields, description })}
                    multiline
                />
                <Button
                    text={id ? 'Update' : 'Add'}
                    onPress={() => handleSubmit()}
                />
            </ScrollView>
        </SafeAreaView>
    )
}
