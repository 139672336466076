export const initialState = {
    // contentList: [],
    contentInFocus: null,
    pinnedContent: null,
}

declare type State = {
    // contentList: KnowledgeContent[],
    contentInFocus: KnowledgeContent | null,
    pinnedContent: KnowledgeContent | null
}

function implementUpdateKnowledgeContent(state: State, payload: KnowledgeContent[]) {
    const newState = JSON.parse(JSON.stringify(state))
    newState.contentInFocus = payload
    console.log('Updated knowledge content to', newState)
    return newState
}

function implementPinKnowledgeContent(state: State) {
    if (!state.pinnedContent) {
        console.log('Pinned knowledge content:', state.contentInFocus)
        return { ...state, pinnedContent: state.contentInFocus}
    } else {
        return { ...state, pinnedContent: null }
    }
}

export const ACTION_TYPES = {
    UPDATE_KNOWLEDGE_CONTENT: 'UPDATE_KNOWLEDGE_CONTENT',
    PIN_KNOWLEDGE_CONTENT: 'PIN_KNOWLEDGE_CONTENT',
    
    CLEAR_KNOWLEDGE_DATA: 'CLEAR_KNOWLEDGE_DATA',
}

function reducer(state: State = initialState, action: ReduxAction) {
    switch (action.type) {
        case ACTION_TYPES.UPDATE_KNOWLEDGE_CONTENT:
            return implementUpdateKnowledgeContent(state, action.payload)
        case ACTION_TYPES.PIN_KNOWLEDGE_CONTENT:
            return implementPinKnowledgeContent(state)
        case ACTION_TYPES.CLEAR_KNOWLEDGE_DATA:
            console.log('Changed knowledge app state to', initialState)
            return initialState
        default:
            return state;
    }
}

export default reducer
