import * as React from 'react'
import { FlatList, Modal, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'

import { RootState } from 'src/app/redux/store'
import { SCREENS } from 'src/time/components/TasksNavigator'


export default function ListsArchived({ navigation, route }) {
    const {folderId} = route.params
    const lists = useSelector((state: RootState) => state.todos.lists
                        .filter((list: List) => list.folder === folderId && list.archived))
                        
    const renderListItem = ({ item }) => {
        return (
            <TouchableOpacity
                onPress={() => navigation.navigate(SCREENS.LIST, { id: item.id })}
                style={{
                    padding: 10,
                    borderBottomWidth: 1,
                    borderBottomColor: '#ccc'
                }}
                key={item.id}
            ><Text>{item.name}</Text>
            </TouchableOpacity>
        )
    }

    return (
        <FlatList
            data={lists}
            keyExtractor={(item) => item.id}
            renderItem={renderListItem}
        />
    )
}

const styles = StyleSheet.create({
    centeredView: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    modalView: {
      backgroundColor: "white",
      borderRadius: 20,
      padding: 4,
      alignItems: "center",
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      width: '80%',
      height: '50%'
    }
  })