import AsyncStorage from '@react-native-async-storage/async-storage'
import { showMessage } from 'react-native-flash-message'
import axios from 'axios'
import { Platform } from 'react-native'
import { BASE_URL } from 'react-native-dotenv'
// import * as Sentry from 'sentry-expo'

import * as auth from '../auth/helpers'
import * as services from './services'


export const initializeMountedApp = async () => {
    try {
        showMessage({message: `Initializing mounted app... BASE_URL = ${BASE_URL}`, type: 'info', icon: 'auto'})
        const tokensStr = await AsyncStorage.getItem('tokens')
        const tokens = tokensStr ? JSON.parse(tokensStr) : {}
        const accessToken = tokens.access
        console.log('Found tokens in local storage:', tokens)
        if (tokens && accessToken) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
            auth.setUpUserData(accessToken)
        }
    } catch (error) {
        console.error(error)
    }
}

export const processPushToken = async (token: string) => {
    const tokenData = {
        token
    }

    await AsyncStorage.setItem('pushToken', token)

    // const response = await services.addPushToken(tokenData)
    // if (!response.error) {
    //     await AsyncStorage.setItem('pushToken', token)
    // }
}

// export const sentryHelpers = (error: Object) => {
//     // Capture exceptions based on platform
//     if (Platform.OS === 'ios' || Platform.OS === 'android') {
//         Sentry.Native.captureException(error)
//     } else {
//         Sentry.Browser.captureException(error)
//     }
// }