import React from 'react'
import { useEffect } from 'react'
import { Provider } from 'react-redux'
import FlashMessage, { showMessage } from 'react-native-flash-message'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import 'react-native-gesture-handler'
import { Dimensions, StatusBar, Text, View } from 'react-native'
// import * as Sentry from 'sentry-expo'
import { HoldMenuProvider } from 'react-native-hold-menu'
import * as Updates from 'expo-updates'
// import { Configuration, OpenAIApi } from "openai"
// import { PersistGate } from 'redux-persist/integration/react'

// import store, {persistor} from './src/app/redux/store'
import store from './src/app/redux/store'
import LoadedApp from './src/app/screens/LoadedApp'
import * as app from './src/app/helpers'
// import {SENTRY_DSN, ENVIRONMENT} from 'react-native-dotenv'
// import { Platform } from 'react-native'
// import { OPENAI_API_KEY } from 'react-native-dotenv'

// const configuration = new Configuration({
//     organization: "org-TtIfl1cLafXI0lHTuz97NcF2",
//     apiKey: OPENAI_API_KEY,
// })
// const openai = new OpenAIApi(configuration)

// const userPrompt = 'create new task discuss with Richa about the accident'

// const prompt = `User: create a new task talk to mahesh at 5 pm.
// Bot: {"intent":"create_task", "entities": {"title":"Talk to mahesh", "time":"5 pm"}

// User: create a new task go to school.
// Bot: {"intent":"create_task", "entities":{"title":"Go to school"}}

// User: ${userPrompt}.
// Bot:`

// async function getResponse() {
//   const completion = await openai.createCompletion({
//     model: "text-davinci-002",
//     prompt: prompt,
//     max_tokens: 200,
//   })
//   console.log(completion?.data?.choices[0]?.text)
// }
// getResponse()

// Sentry.init({
//   dsn: SENTRY_DSN,
//   enableInExpoDevelopment: true,
//   environment: ENVIRONMENT,
//   debug: true, // Useful debugging information if something goes wrong with sending an event. Set `false` in production.
// })

function ProvidedApp() {
  useEffect(() => {
    app.initializeMountedApp() 
  }, [])

  useEffect(() => {
    async function updateApp() {
      try {
        const update = await Updates.checkForUpdateAsync()
        if (update.isAvailable) {
          await Updates.fetchUpdateAsync()
          await Updates.reloadAsync()
          showMessage({
            message: 'App updated',
            description: 'The app has been updated. Restarting...',
            type: 'info',
          })
        }
      } catch (error) {
        console.error('Error checking for updates', error)
        showMessage({
          message: 'Error updating app',
          description: 'There was an error checking for or applying updates. Please try again later.',
          type: 'danger',
        })
      }
    }
    updateApp()
  }, [])

  return (
    <>
      <LoadedApp /> 
      <FlashMessage position="top" style={{ paddingTop: 30 }} />
      <StatusBar barStyle="dark-content" />
    </>
  )
}

// StatusBar.setBarStyle('dark-content')

function App() {
  return (
    <SafeAreaProvider>
      <HoldMenuProvider theme="light" safeAreaInsets={{ top: 0, bottom: 0, right: 0, left: 0 }}>
        <Provider store={store}>
          {/* <PersistGate loading={<Text>Loading</Text>} persistor={persistor}> */}
            <ProvidedApp />
          {/* </PersistGate> */}
        </Provider>
      </HoldMenuProvider>
    </SafeAreaProvider>
  )
}

// let SentryApp = App

// if (Platform.OS === 'web') {
//   SentryApp = Sentry.Browser.wrap(App)
// } else {
//   SentryApp = Sentry.Native.wrap(App)
// } 

export default App
