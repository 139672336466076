
import * as React from 'react'
import { Platform, SafeAreaView, ScrollView, StyleSheet, Switch, Text, View } from 'react-native'
import { useSelector } from 'react-redux'
import { Calendar } from 'react-native-calendars'
import DateTimePicker from '@react-native-community/datetimepicker'

import { RootState } from 'src/app/redux/store'
import TextInput from 'src/app/components/TextInput'
import TextLabel from 'src/app/components/TextLabel'
import Button from 'src/app/components/Button'
import Modal from 'src/app/components/Modal'
import * as helpers from '../../helpers'
import { SCREENS } from '../../components/TasksNavigator'


export default function TimeLogsAddEdit({ navigation, route }: { navigation: any, route: any }) {
    const { source_type, source_id, id, start_time, duration } = route.params
    const emptyTimeLog: TimeLog = {
        start_date: new Date().toISOString().split('T')[0],
        start_time: start_time || '',
        duration: duration || 0,
        title: '',
        notes: '',
        source_id: source_id ? source_id : null,
    }
    const timeLog: TimeLog = useSelector((state: RootState) => state.todos.timeLogs.find((i: TimeLog) => i.id === id))
    const tasks: Task[] = useSelector((state: RootState) => state.todos.tasks)
    const task: Task = useSelector((state: RootState) => state.todos.tasks.find((i: Task) => i.id === source_id))
    const [timeLogFields, updateTimeLogFields] = React.useState(id ? timeLog : emptyTimeLog)
    const [deleteModalVisible, setDeleteModalVisible] = React.useState(false)
    const [startDateModalVisible, setStartDateModalVisible] = React.useState(false)

    const [taskSelectionModalVisible, setTaskSelectionModalVisible] = React.useState(false)
    const [taskSelectionSearchText, setTaskSelectionSearchText] = React.useState('')

    const handleSubmit = async () => {
        if (id) { // Edit existing 
            await helpers.timeLogPatch(timeLogFields, id)
        } else { // Add new
            await helpers.timeLogPost(timeLogFields)
        }
    }

    const handleDelete = async () => {
        helpers.timeLogDelete(id)
        navigation.goBack()
    }

    return (
        <>
            <View style={styles.header}>
                <Button
                    text={'💾'}
                    onPress={() => { handleSubmit(); navigation.goBack() }}
                />
                <Button
                    text={'💾➕'}
                    onPress={() => {
                        handleSubmit()
                        updateTimeLogFields(emptyTimeLog)
                        navigation.navigate(SCREENS.TIME_LOG_ADD_EDIT, { source_type, source_id, id: null })
                    }}
                />
                {
                    id && (
                        <Button
                            text='❌'
                            onPress={() => setDeleteModalVisible(true)}
                        />
                    )
                }
                <Modal
                    onRequestClose={() => setDeleteModalVisible(false)}
                    visible={deleteModalVisible}
                >
                    <Text>Confirm Delete?</Text>
                    <Button onPress={() => setDeleteModalVisible(false)} text='Cancel' />
                    <Button onPress={() => { setDeleteModalVisible(false); handleDelete() }} text='Delete' />
                </Modal>
            </View>
            <ScrollView>
                {!id && !source_id && source_type === 'task' && (
                    <>
                        <TextLabel text='Task:' />
                        <Button onPress={() => setTaskSelectionModalVisible(true)} text={tasks.find((t: Task) => {
                                return t.id == timeLogFields.source_id
                            })?.name} />
                    </>
                )}
                <Modal
                        onRequestClose={() => setTaskSelectionModalVisible(false)}
                        visible={taskSelectionModalVisible}
                    >
                        <ScrollView>
                            <Text
                                style={{paddingTop: 10}}
                            >
                                Search search text:
                            </Text>
                            <TextInput
                                value={taskSelectionSearchText}
                                onChangeText={(searchText: string) => setTaskSelectionSearchText(searchText || '')}
                            />
                            {tasks
                                .filter((t: Task) => t.name.toLowerCase().includes(taskSelectionSearchText.toLowerCase()))
                                .sort((a: Task, b: Task) => a.name.localeCompare(b.name))
                                .map((t: Task) => (
                                <Button
                                    key={t.id}
                                    text={t.name}
                                    onPress={() => {
                                        updateTimeLogFields({ ...timeLogFields, source_id: t.id })
                                        setTaskSelectionModalVisible(false)
                                    }}
                                />
                            ))}
                        </ScrollView>
                        <Button
                            text='Cancel'
                            onPress={() => setTaskSelectionModalVisible(false)}
                            primary
                        />
                    </Modal>
                {!id && source_id && source_type && (
                    <>
                        <TextLabel text={`Source type: ${source_type}`} />
                        {source_type === 'task' && (<TextLabel text={`Source Details: ${task.name}`} />)}
                    </>
                )}
                {!id && !source_id &&
                    <>
                        <Text>Title: </Text>
                        <TextInput
                            value={timeLogFields.title}
                            onChangeText={(title: string) => updateTimeLogFields({ ...timeLogFields, title })}
                            placeholder="Enter title"
                        />
                    </>
                }
                <Text>Notes: </Text>
                <TextInput
                    value={timeLogFields.notes}
                    onChangeText={(notes: string) => updateTimeLogFields({ ...timeLogFields, notes })}
                    multiline
                />
                <Text>Start Date: </Text>
                <Button
                    text={timeLogFields.start_date || 'Select Date'}
                    onPress={() => setStartDateModalVisible(true)}
                />
                <Modal
                    onRequestClose={() => setStartDateModalVisible(false)}
                    visible={startDateModalVisible}
                >
                    <Calendar
                        current={timeLogFields.start_date}
                        onDayPress={(day) => { updateTimeLogFields({ ...timeLogFields, start_date: day.dateString }); setStartDateModalVisible(false) }}
                        markedDates={{ [timeLogFields.start_date]: { selected: true } }}
                    />
                    <Button
                        text='Clear due date'
                        onPress={() => { setStartDateModalVisible(false); updateTimeLogFields({ ...timeLogFields, start_date: null }) }}
                    />
                    <Button
                        text='Cancel'
                        onPress={() => setStartDateModalVisible(false)}
                    />
                </Modal>
                <Text>Start Time: </Text>
                <TextInput
                    value={timeLogFields.start_time}
                    onChangeText={(start_time: string) => updateTimeLogFields({ ...timeLogFields, start_time: start_time === '' ? '' : start_time })}
                />
                <Text>Duration: </Text>
                <TextInput
                    value={timeLogFields.duration.toString()}
                    onChangeText={(duration: string) => updateTimeLogFields({ ...timeLogFields, duration: duration === '' ? 0 : parseInt(duration) })}
                />
            </ScrollView>
        </>
    )
}

const styles = StyleSheet.create({
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',

    }
})