import * as React from 'react'
import { View, Text, StyleSheet, Dimensions, Modal, Alert, TouchableOpacity } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { TabActions } from '@react-navigation/native'
import { HoldItem } from 'react-native-hold-menu'

import buttonStyles from 'src/app/components/Button/styles'
import TextLabel from 'src/app/components/TextLabel'
import { SCREENS } from './TasksNavigator'
import * as helpers from 'src/time/helpers'
import * as TasksNavigator from './TasksNavigator'
import * as RootNavigator from './RootNavigator'
import Button from 'src/app/components/Button'

function handleTaskCompletion(item: Task) {
    // @ts-ignore
    helpers.taskPatch({ completed: !item.completed, flagged: false }, item.id)
}

function handleToggleFlag(item: Task) {
    // @ts-ignore
    helpers.taskPatch({ flagged: !item.flagged }, item.id)
}

function getTaskFooter(task: Task, parentTaskName: string, childTaskCountText: string, dependencyTaskName: string): string {
    let footer = `${task.description ? '✏️ • ': ''}🔼 ${parentTaskName ? parentTaskName : "N/A"}`

    if (childTaskCountText) {
        footer += ` • 🔽 ${childTaskCountText} Open`
    }

    if (task.due_date) {
        footer += ` • ⏰ ${task.due_date}`
    } else if (task.cron_schedule) {
        footer += ` • ⏰ ${helpers.getCronDescription(task.cron_schedule)}`
    }

    if (task.dependency_task) {
        footer += ` • 🔁 ${dependencyTaskName}`
    }

    if (task.due_type && task.due_value) {
        footer += ` • 🗓️ ${task.due_type} : ${task.due_value}`
    }

    return footer

}

function ItemIcon({task}: {task: Task}) {
    if (task.completed) {
        return <MaterialCommunityIcons name="check-circle" size={32} color="green" />
    } else if (task.flagged) {
        return <MaterialCommunityIcons name="flag" size={32} color="orange" />
    } else {
        return <MaterialCommunityIcons name="circle-outline" size={32} color="grey" />
    }
}

function StartTaskIcon({task}: {task: Task}) {
    return <MaterialCommunityIcons name="play-circle-outline" size={32} color="grey" />
}

export default function TaskItem({ item, parentTaskName, childTaskCountText, dependencyTaskName, rootNavigation, from }: 
    { item: Task, parentTaskName?: string, childTaskCountText?: string, dependencyTaskName?: string, rootNavigation?: any, from?: string }) {
    // console.log('TaskItem', item, parentTaskName)
    const MenuItems = [
        {
            text: 'Add time log',
            onPress: () => {
                TasksNavigator.navigate(SCREENS.TIME_LOG_ADD_EDIT, { task: item })
            },
        },
        {
            text: 'Delete',
            isDestructive: true,
            onPress: () => {
                showDeleteConfirmationAlert()
            },
        },
    ]

    function showDeleteConfirmationAlert() {
        Alert.alert(
        "Confirm",
        `Sure you want to delete: ${item.name}?`,
        [
            {
                text: "Cancel",
                onPress: () => {},
                style: "cancel"
            },
            { 
                text: "Delete", 
                onPress: () => handleDelete() ,
                style: "destructive"
            }
        ]
        )
    }

    function handleDelete() {
        helpers.taskDelete(item.id)
    }

    return (
        <View style={{ flexDirection: 'row', borderBottomWidth: 1, borderBottomColor: '#ccc'}}>
            <TouchableOpacity
                    style={styles.checkboxContainer}
                    onPress={() => handleTaskCompletion(item)}
                    onLongPress={() => handleToggleFlag(item)}
                >
                    <ItemIcon task={item}/>
            </TouchableOpacity>
            {/* <TouchableOpacity
                    style={styles.startTaskButtonContainer}
                    onPress={() => helpers.handleTaskStart(item.id)}
                    // onLongPress={() => toggleTaskStartModal()}
                >
                    <StartTaskIcon task={item}/>
            </TouchableOpacity> */}
            <TouchableOpacity
                onLongPress={() => {
                    TasksNavigator.navigate(TasksNavigator.SCREENS.ADD_EDIT_TASK, { mode: 'TASK_EDIT', payload: {id: item.id} })
                    rootNavigation?.dispatch(TabActions.jumpTo(RootNavigator.SCREENS.TASKS))
                }}
                onPress={() => {
                    TasksNavigator.navigate(TasksNavigator.SCREENS.GOAL_TASKS, { parentTaskId: item.id })
                }}
                key={item.id}
                style={{
                    paddingVertical: 8,
                    flexDirection: 'column',
                    // backgroundColor: 'yellow',
                    width: Dimensions.get('window').width -40,
                    paddingRight: 2
                }}
            >
                {/* <HoldItem items={MenuItems}> */}
                    <View
                    >
                        <View style={styles.titleBodyContainer}>
                            <View style={styles.titleContainer}>
                                <Text style={{fontSize: 16, marginLeft: 8}}>{item.name}</Text>
                            </View>
                        </View>
                        <View style={styles.footerContainer}>
                            <TextLabel 
                                extraStyles={styles.footerText} 
                                text={getTaskFooter(item, parentTaskName || '', childTaskCountText || '')}
                            />
                        </View>
                    </View>
                {/* </HoldItem> */}
            </TouchableOpacity>
        </View>
    )
}

const styles = StyleSheet.create({
    checkboxContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 8,
    },
    contentContainer: {},
    flagContainer: {
        marginRight: 4
    },
    footerContainer: {
        // backgroundColor: 'red',
    },
    footerText: {
        fontSize: 12,
        color: '#777'
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    startTaskButtonContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 0,
    },
    taskItemBodyContainer: {
        flex: 1,
        flexDirection: 'row'
    },
    titleBodyContainer: {
        flexDirection: 'row',
        // backgroundColor: 'green',
    },
    titleContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
})
