import * as React from 'react'
import { FlatList, Platform, Text, ScrollView, StyleSheet, View, TouchableOpacity } from 'react-native'
import { useSelector } from 'react-redux'
import { TabActions } from '@react-navigation/native'
import { SafeAreaView } from 'react-native-safe-area-context';
import { Calendar } from 'react-native-calendars'
import WeekView from 'react-native-week-view'
import cronParser from 'cron-parser'
import * as Linking from 'expo-linking'

import { RootState } from 'src/app/redux/store'
import * as TasksNavigator from '../../components/TasksNavigator'
import * as RootNavigator from '../../components/RootNavigator'
import * as helpers from './helpers'
import * as timeHelpers from '../../helpers'
import Button from 'src/app/components/Button'
import Modal from 'src/app/components/Modal'
import TextLabel from 'src/app/components/TextLabel'
import TaskItem from 'src/time/components/TaskItem';
import ActiveTaskBar from 'src/time/components/ActiveTaskBar';
import TextInput from 'src/app/components/TextInput';


export default function Due({ navigation }: { navigation: any }) {
    // useState
    const [date, updateDate] = React.useState(helpers.getCurrentDate())
    const [calendarEvents, updateCalendarEvents] = React.useState([])
    const [weekViewRef, updateWeekViewRef] = React.useState(null)
    const [dueDateModalVisible, setDueDateModalVisible] = React.useState(false)
    const [newLogModalVisible, setNewLogModalVisible] = React.useState(false)
    const [showTimeLogs, setShowTimeLogs] = React.useState(true)
    const [transcription, setTranscription] = React.useState('')
    const [filterTaskId, setFilterTaskId] = React.useState(null)
    
    // useSelector
    const allTasks = useSelector((state: RootState) => state.todos.tasks)
    const allLists = useSelector((state: RootState) => state.todos.lists)
    const allTimeLogs = useSelector((state: RootState) => state.todos.timeLogs)
                            .filter((t: TimeLog) => helpers.ifDue(t.start_date, undefined, date))
    const activeTaskId = useSelector((state: RootState) => state.todos.userProfile.active_task)

    // useEffect
    // React.useEffect(() => { // Get Calendar events whenever date changes
    //     async function getData() {
    //         const events = await timeHelpers.calendarEventsFetch(date)
    //         updateCalendarEvents(events.filter(e => !e.notes?.includes('#ignore')).map(e => ({
    //             id: e.id,
    //             description: '🗓 ' + e.title,
    //             startDate: new Date(e.startDate),
    //             endDate: new Date(e.endDate),
    //             color: '#ff8080',
    //             source: 'device-cal'
    //         })))
    //     }
    //     getData()
    // }, [date])
    
    // Helper functions
    const handleDateChange = (day) => {
        setDueDateModalVisible(false)
        const userDate = day.dateString ? day.dateString : day
        updateDate(userDate)
        weekViewRef.goToDate(userDate, true)
    }

    const getTaskEvent = (t: Task) => {
        const duration = t.duration

        let startDate = null
        let endDate = null

        if (t.due_date) {
            startDate = new Date(t.due_date + 'T' + t.due_time)
            endDate = new Date((new Date(t.due_date + 'T' + t.due_time)).getTime() + (duration||0) * 60000)
        } else if (t.cron_schedule) {
            const optionsCurrentDate = date ? new Date(date) : new Date()
            optionsCurrentDate.setHours(0, 0, 0, 0)
            const optionsEndDate = new Date(optionsCurrentDate.getTime() + new Date(86400000).getTime())
            const options = {
                currentDate: optionsCurrentDate,
                endDate: optionsEndDate,
                iterator: true,
            }
            const iterator = cronParser.parseExpression(t.cron_schedule, options)
            try {
                startDate = new Date(iterator.next().value)
                const auxDateTime = new Date(startDate).getTime()
                endDate = new Date(auxDateTime + (duration||0) * 60000)
            } catch (e) {
                return false
            }
        }
        const event = {
            id: t.id,
            description: t.name,
            startDate,
            endDate,
            color: '#0000ff99',
            type: 'task'
        }
        return event
    }

    const getListEvent = (l: List) => {
        let startDate, endDate
        const duration = l.duration && l.duration != '00:00:00'
            ? parseInt(l.duration.split(':')[0]) * 60 + parseInt(l.duration.split(':')[1])
            : 60
        const optionsCurrentDate = date ? new Date(date) : new Date()
        optionsCurrentDate.setHours(0, 0, 0, 0)
        const optionsEndDate = new Date(optionsCurrentDate.getTime() + new Date(86400000).getTime())
        const options = {
            currentDate: optionsCurrentDate,
            endDate: optionsEndDate,
            iterator: true,
        }
        const iterator = cronParser.parseExpression(l.cron_schedule, options)
        try {
            startDate = new Date(iterator.next().value)
            const auxDateTime = new Date(startDate).getTime()
            endDate = new Date(auxDateTime + duration * 60000)
        } catch (e) {
            return false
        }
        const listTasks = allTasks.filter((t: Task) => t.list == l.id)
            .filter((t: Task) => !t.completed)
            .filter((t: Task) => !t.due_date && !t.cron_schedule)
            .sort((a: Task, b: Task) => a.name.localeCompare(b.name))

        const getListEventTitle = () => {
            let title = `(${l.name}) `
            if (listTasks.length > 0) title += listTasks[0].name
            if (listTasks.length > 0) {
                title += ` + ${listTasks.length - 1} more`
            }
            return title
        }
        const event = {
            id: l.id,
            description: getListEventTitle(),
            startDate,
            endDate,
            color: '#00aa00cc',
            type: 'list'
        }
        return event
    }

    const getTimeLogEvent = (t: TimeLog) => {
        const startDate = new Date(t.start_date + 'T' + t.start_time)
        const duration = t.duration
        const endDate = new Date((new Date(t.start_date + 'T' + t.start_time)).getTime() + duration * 60000) 

        let description = '⏰ '
        const task = allTasks.find((task: Task) => task.id == t.source_id)
        if (task && !task.completed) {
            description += task.name
        } else if (task && task.completed) {
            description += '✅ ' + task.name
        }

        const event = {
            id: t.id,
            description,
            startDate,
            endDate,
            color: '#0077ff99',
            type: 'time-log',
            source_id: t.source_id
        }
        return event
    }

    function handleTaskCompletion(item: Task) {
        timeHelpers.taskPatch({ completed: true }, item.id)
    }

    function handleDateSwipe(change: number) {
        const newDate = new Date(new Date(date).getTime() + change * 24 * 60 * 60 * 1000)
        updateDate(newDate.toISOString().split('T')[0])
    }

    function filterForListView(t: any) {
        return !t.due_time && !t.cron_schedule

        // TODO Consider how to manage web view by separating recurring tasks
        if (Platform.OS === 'web') {
            return t
        } else {
            return !t.due_time && !t.cron_schedule
        }
    }

    function handleSaveNewLog() {
        
    }

    let completedTasks = timeHelpers.getSortedList(allTasks, 'due_date', 'STRING', 'DESC')
                            .filter((task: Task) => task.completed)

    // Manipulations
    const tasksDueToday = allTasks.filter((t: Task) => t.due_date || t.cron_schedule)
        .filter((t: Task) => !t.completed)
        .filter((t: Task) => helpers.ifDue(t.due_date, t.cron_schedule, date))

    const listsForCalView = allLists.filter((l: List) => l.cron_schedule)
        .filter((l: List) => helpers.ifDue(undefined, l.cron_schedule, date))

    const timeLogsForCalView = allTimeLogs.map((t: TimeLog) => getTimeLogEvent(t))

    let weekViewInputEvents = tasksDueToday.filter((t: Task) => t.due_time || t.cron_schedule).map(t => getTaskEvent(t))
    weekViewInputEvents = weekViewInputEvents.concat(calendarEvents)
                            .concat(listsForCalView.map((l: List) => getListEvent(l)))
                            .concat(timeLogsForCalView)

    function ListItem({ item }: { item: Task }) {
        const dependencyTaskName = ''
        const parentTaskName = item.parent_task ? allTasks.find((t: Task) => t.id === item.parent_task)?.name : ''
        return (
            <TaskItem
                item={item}
                parentTaskName={parentTaskName}
                dependencyTaskName={dependencyTaskName}
                rootNavigation={navigation}
                from='Due'
            />
        )
    }
        
    return (
        <SafeAreaView style={{ flexDirection: 'column' }}>
            {activeTaskId && <ActiveTaskBar />}
            {/* <View style={{
                flexDirection: 'row',
                justifyContent: 'center',
            }}>
                <Button
                    text={`Tasks for: ${(new Date(date)).toDateString()}`}
                    onPress={() => setDueDateModalVisible(true)}
                    extraStyle={{
                        width: '100%'
                    }}
                />
                <TextLabel
                    text='Today'
                    extraStyles={{
                        marginLeft: 8,
                        alignSelf: 'center',
                        color: '#00a8ff',
                    }}
                    onPress={() => handleDateChange(helpers.getCurrentDate())}
                />
                <TextLabel
                    text='➕'
                    extraStyles={{
                        alignSelf: 'center',
                    }}
                    onPress={() => {
                        TasksNavigator.navigate('Add/Edit Time Log',  { source_type: 'task', source_id: null })
                        navigation.dispatch(TabActions.jumpTo(RootNavigator.SCREENS.TASKS))
                    }}
                />
            </View>
            <Modal
                onRequestClose={() => setDueDateModalVisible(false)}
                visible={dueDateModalVisible}
            >
                <Calendar
                    current={date}
                    onDayPress={handleDateChange}
                    markedDates={{ [date]: { selected: true } }}
                />
                <Button
                    text='Cancel'
                    onPress={() => setDueDateModalVisible(false)}
                />
            </Modal> */}
            <ScrollView>
                {/*
                {true && Platform.OS !== 'web' &&
                    <View>
                        <WeekView
                            events={weekViewInputEvents}
                            onEventPress={(item) => {
                                if (item.type === 'device-cal') {
                                    // Open calendar app based on OS & navigate to event based on ID
                                    if (Platform.OS === 'android') {
                                        Linking.openURL(`content://com.android.calendar/time/${date.getTime()}`)
                                    }
                                    else if (Platform.OS === 'ios') {
                                        let now = new Date(date);
                                        now = Math.floor(now / 1000);
                                        const seconds = (now - 978307200);
                                        Linking.openURL(`calshow:${seconds}`)
                                    }
                                } else if (item.type === 'list') {
                                    TasksNavigator.navigate(TasksNavigator.SCREENS.LIST, { id: item.id })
                                    navigation.dispatch(TabActions.jumpTo(RootNavigator.SCREENS.TASKS))
                                } else if (item.type === 'time-log' && item.source_type === 'task') {
                                    TasksNavigator.navigate(TasksNavigator.SCREENS.ADD_EDIT_TASK, { id: item.source_id })
                                    navigation.dispatch(TabActions.jumpTo(RootNavigator.SCREENS.TASKS))
                                } else if (item.type === 'task') {
                                    TasksNavigator.navigate(TasksNavigator.SCREENS.ADD_EDIT_TASK, { id: item.id, list: item.list })
                                    navigation.dispatch(TabActions.jumpTo(RootNavigator.SCREENS.TASKS))
                                }
                            }}
                            selectedDate={new Date(date)}
                            numberOfDays={1}
                            showTitle={false}
                            ref={(ref) => { updateWeekViewRef(ref) }}
                            startHour={9}
                            hoursInDisplay={10}
                            timeStep={60}
                            showNowLine={true}
                            EventComponent={Event}
                            onSwipeNext={() => handleDateSwipe(1)}
                            onSwipePrev={() => handleDateSwipe(-1)}
                            timesColumnWidth={40}
                        />
                    </View>
                }
                { // For web platform, just render a list of time logs for the day (show task name, parent task name, duration, start time)
                    Platform.OS === 'web' &&
                    <View style={{padding: 16}}>
                        <Text
                            style={{
                                textAlign: 'center',
                                fontSize: 24,
                                paddingTop: 16,
                                margin: 8,
                                color: '#777'
                            }}
                            onPress={() => setShowTimeLogs(!showTimeLogs)}
                        >
                            Time Logs {showTimeLogs ? '⬇️' : '⏭️'} 
                        </Text>
                        {showTimeLogs && 
                            <FlatList
                                data={allTimeLogs.filter((t: TimeLog) => t.start_date === date)}
                                renderItem={({ item }) => {
                                    const task = allTasks.find((t: Task) => t.id === item.source_id)
                                    const parentTask = task.parent_task ? allTasks.find((t: Task) => t.id === task.parent_task) : null
                                    return (
                                        <TouchableOpacity 
                                            style={{ flexDirection: 'row', justifyContent: 'space-between', padding: 8, borderBottomWidth: 1, borderColor: '#ddd' }}
                                            onPress={() => {
                                                TasksNavigator.navigate(TasksNavigator.SCREENS.ADD_EDIT_TASK, { id: item.source_id })
                                                navigation.dispatch(TabActions.jumpTo(RootNavigator.SCREENS.TASKS))
                                            }}
                                        >
                                            <View style={{ flexDirection: 'column' }}>
                                                <Text style={{ fontSize: 16 }}>{task.name}</Text>
                                                {parentTask && <Text style={{ fontSize: 12 }}>{parentTask.name}</Text>}
                                            </View>
                                            <View style={{ flexDirection: 'column', alignItems: 'flex-end' }}>
                                                <Text style={{ fontSize: 16 }}>{item.start_time}</Text>
                                                <Text style={{ fontSize: 12 }}>{`${item.duration} min`}</Text>
                                            </View>
                                        </TouchableOpacity>
                                    )
                                }}
                            />
                        }  
                        <Text
                            style={{
                                textAlign: 'center',
                                fontSize: 24,
                                paddingTop: 16,
                                margin: 8,
                                color: '#777'
                            }}
                        >
                            Due Tasks
                        </Text>
                    </View>
                }
                {/* Tasks without due time */}
                
                {/* <FlatList
                    data={tasksDueToday.filter(filterForListView)}
                    renderItem={ListItem}
                    keyExtractor={(item: Task) => item.id.toString()}
                />  */}
                {/* Completed tasks on this date */}
                {/* Current Tasks */}
                {/* Header */}
                {activeTaskId && <ActiveTaskBar />}
                <View style={styles.header}>
                    <Button
                        text={`╳`}
                        onPress={() => { setTranscription(''); setFilterTaskId(null) }}
                    />
                    <Button
                        text={'▶️'}
                        onPress={() => timeHelpers.executeCommand(transcription, null, allTasks, null, setFilterTaskId)}
                    />
                </View>
                <TextInput
                        value={transcription}
                        placeholder='Enter command...'
                        onChangeText={(text) => setTranscription(text)}
                />
                <Text
                    style={{
                        textAlign: 'center',
                        fontSize: 18,
                        // paddingTop: 8,
                        marginTop: 16,
                        marginBottom: 8,
                        color: '#777'
                    }}
                >
                    Current Tasks
                </Text>
                {
                    timeHelpers.getCurrentTimeWindows(undefined).reverse().map((dueCode: string) => {
                        const timeWindowTasks = allTasks.filter((task: Task) => `${task.due_type} : ${task.due_value}` === dueCode)
                        return (
                            <View >
                                <TouchableOpacity
                                    onPress={() => navigation.navigate(SCREENS[`GOAL_TIME`], { dueCode: dueCode })}
                                    key={dueCode}
                                >
                                    <View style={styles.sectionHeader}>
                                        <TextLabel extraStyles={{fontSize: 16}} text={`${dueCode} (${timeHelpers.getTaskCountForTimeWindow(dueCode, allTasks)})`} />
                                    </View>
                                    {
                                        timeWindowTasks
                                        .filter((task: Task) => {
                                            if (filterTaskId) {
                                                return timeHelpers.isDescendentOf(task, filterTaskId, allTasks.filter((t: Task) => !t.completed))
                                            } else {
                                                return true
                                            }
                                        })
                                        .map((task: Task) => {
                                            return (
                                                <TaskItem
                                                    item={task}
                                                    parentTaskName={allTasks.find((t: Task) => t.id === task.parent_task)?.name}
                                                    childTaskCountText={timeHelpers.getChildTaskCountForTask(task, allTasks)}
                                                    rootNavigation={navigation}
                                                />
                                            )
                                        })
                                    }
                                </TouchableOpacity>
                            </View>
                        )
                    })
                }
                    <Text
                        style={{
                            textAlign: 'center',
                            fontSize: 24,
                            paddingTop: 16,
                            margin: 8,
                            color: '#777'
                        }}
                    >
                        Completed (Today's Due Date)
                    </Text>
                    <FlatList
                        data={completedTasks.filter((t: Task) => {
                            if (t.due_date) return t.due_date == date
                            if (t.due_type === "D") return t.due_value == date
                            return false
                        })}
                        renderItem={ListItem}
                        keyExtractor={(item: Task) => item.id.toString()}
                    />
            </ScrollView>
        </SafeAreaView>
    )
}

function Event({ event, position }) {
    return (
        <View style={styles.event}>
            <TextLabel text={event.description} extraStyles={styles.eventText} />
        </View>
    )
}

const styles = StyleSheet.create({
    checkboxContainer: {
        marginRight: 10
    },
    secondaryItemContainer: {
        marginRight: 4
    },
    taskItemBodyContainer: {
        flex: 1,
        flexDirection: 'row'
    },
    titleContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    event: {
        borderWidth: 1,
        borderRadius: 4,
        width: '100%',
        height: '100%',
    },
    eventText: {
        color: 'white',
        fontSize: 12,
    }
})
