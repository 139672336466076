declare type InitialState = {
    loginStatus: 'LOGGEDOUT' | 'LOGGING' | 'LOGGEDIN' | 'ERROR',
    id?: string,
    username?: string,
    email?: string,
    first_name?: string,
    last_name?: string,
}

export const initialState: InitialState = {
    loginStatus: 'LOGGEDOUT',
}

function implementLoginUser(payload: InitialState) {
    const newState = payload
    newState.loginStatus = 'LOGGEDIN'
    return newState
}

function implementLogoutUser() {
    const newState = initialState
    console.log('Changed auth app state to', initialState)
    return newState
}

export const ACTION_TYPES = {
    LOGIN_USER: 'login_user',
    LOGOUT_USER: 'logout_user'
}

function reducer(state = initialState, action: ReduxAction) {
    switch (action.type) {
        case ACTION_TYPES.LOGIN_USER:
            return implementLoginUser(action.payload)
        case ACTION_TYPES.LOGOUT_USER:
            return implementLogoutUser()
        default:
            return state
    }
}

export default reducer
