import axiosInstance from 'src/app/network'
import { BASE_URL } from 'react-native-dotenv'
import axios from 'axios'

const LOGIN_URL = `${BASE_URL}/api/v1/auth/login/`
const USER_DETAILS_URL = `${BASE_URL}/api/v1/users/user`
const SIGNUP_URL = `${BASE_URL}/api/v1/users/signup/`

console.log(BASE_URL)

export async function authenticateUser(userCredentials: UserCredentials) {
    try {
        const {data} = await axiosInstance.post(LOGIN_URL, userCredentials)
        return data
    } catch (error) {
        return { error: error.response.data.message }
    }    
}

export async function getUserDetails(accessToken: string) {
    const { data } = await axios.get(USER_DETAILS_URL, {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    })
    return { userDetails: data }
}

export async function signUserUp(userCredentials: UserCredentials) {
    await axiosInstance.post(SIGNUP_URL, userCredentials)
    return {}
}

