
import * as React from 'react'
import { createStackNavigator } from '@react-navigation/stack'

import TimeNavigator from '../../time/components/RootNavigator'
// import FlashcardsNavigator from 'src/flashcards/components/RootNavigator'
import KnowledgeNavigator from 'src/knowledge/components/RootNavigator'
import { createNavigationContainerRef } from '@react-navigation/native'


export const APPS = {
  TIME: 'TIME',
  KNOWLEDGE: 'KNOWLEDGE',
  // FLASHCARDS: 'FLASHCARDS',
}

// import AppleHealthKit, {
//   HealthValue,
//   HealthKitPermissions,
// } from 'react-native-health'

/* Permission options */
// const permissions = {
//   permissions: {
//     read: [AppleHealthKit.Constants.Permissions.Weight],
//     // write: [AppleHealthKit.Constants.Permissions.Steps],
//   },
// } as HealthKitPermissions



export const RootStack = createStackNavigator()
const rootStackNavigationRef = createNavigationContainerRef()

export function rootStackNavigate(name, params) {
  if (rootStackNavigationRef.isReady()) {
    rootStackNavigationRef.navigate(name, params);
  }
}


export default function RootNavigator() {
    console.log('App > RootNavigator mounted')
  // useEffect(() => {
  //   AppleHealthKit.initHealthKit(permissions, (error: string) => {
  //     /* Called after we receive a response from the system */
    
  //     if (error) {
  //       console.log('[ERROR] Cannot grant permissions!')
  //     }
    
  //     /* Can now read or write to HealthKit */
    
  //     const options = {
  //       startDate: "2022-08-12T22:00:00.000+0530", // new Date(2022, 8, 13).toISOString(),
  //       limit: 25
  //     }
    
  //     AppleHealthKit.getLatestWeight(
  //       {unit: 'kg'},
  //       (callbackError: string, results: HealthValue[]) => {
  //         /* Samples are now collected from HealthKit */
  //         console.log('[INFO] Res ults: ', results)
  //         console.log('[INFO] Error: ', callbackError)
  //         console.log(results)
  //       },
  //     )
  //   })
  // }, [])
  return (
    // <Text>App Navigator!</Text>
    <RootStack.Navigator initialRouteName={APPS.TIME} screenOptions={{ headerShown: false }}>
      <RootStack.Screen
        name={APPS.TIME}
        component={TimeNavigator}
      />
      {/* <Stack.Screen
        name={APPS.FLASHCARDS}
        component={FlashcardsNavigator}
      />*/}
      <RootStack.Screen
        name={APPS.KNOWLEDGE}
        component={KnowledgeNavigator}
      /> 
    </RootStack.Navigator>
  )
}