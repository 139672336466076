import React from 'react'
import { ScrollView, View, StatusBar, StyleSheet, Text } from 'react-native'
import { useSelector } from 'react-redux'
import Markdown, { MarkdownIt } from 'react-native-markdown-display'

import Button from 'src/app/components/Button'
import store, { RootState } from 'src/app/redux/store'
import * as helpers from '../../helpers'
import TextLabel from 'src/app/components/TextLabel'
import Modal from 'src/app/components/Modal'
import { SCREENS, navigate } from 'src/knowledge/components/KnowledgeNavigator'
import { SafeAreaView } from 'react-native-safe-area-context'
import TextInput from 'src/app/components/TextInput'
import { ACTION_TYPES } from 'src/knowledge/reducers'

declare type ParentSearchSuggestion = {
    id: number
    title: string
}

export default function KnowledgeContent({ navigation, route }: { navigation: any, route: any }) {
    const contentInFocus = useSelector((state: RootState) => state.knowledge.contentInFocus)
    const pinnedContent = useSelector((state: RootState) => state.knowledge.pinnedContent)

    const [editMode, setEditMode] = React.useState(false)
    const [deleteModalVisible, setDeleteModalVisible] = React.useState(false)
    const [contentFields, setContentFields] = React.useState(contentInFocus)
    const [addChildModalVisible, setAddChildModalVisible] = React.useState(false)
    const [newChildTitle, setNewChildTitle] = React.useState('')
    const [parentContent, setParentContent] = React.useState(null)
    const [selectParentModalVisible, setSelectParentModalVisible] = React.useState(false)
    const [parentSearchText, setParentSearchText] = React.useState('')
    const [parentSearchSuggestions, setParentSearchSuggestions] = React.useState([])

    React.useEffect(() => {
        getParentContent()
    }, [contentInFocus])

    function handleEditSave() {
        if (editMode) {
            helpers.knowledgeContentPatch(contentInFocus.id, contentFields)
        } else {
            setContentFields(contentInFocus)
        }
        setEditMode(!editMode)
    }

    function handleCreateNewChild() {
        helpers.knowledgeContentPost({ title: newChildTitle, parent: contentInFocus.id })
    }

    async function getParentContent() {
        if (contentInFocus) {
            const parentContent = await helpers.knowledgeContentGet(contentInFocus.parent, false)
            setParentContent(parentContent)
        }
    }

    async function fetchParentSuggestions() {
        setParentSearchSuggestions([])
        if (!parentSearchText || parentSearchText.length < 3) return
        const suggestions = await helpers.knowledgeContentSearch(parentSearchText)
        setParentSearchSuggestions(suggestions)
    }

    function handlePinContent() {
        store.dispatch({ type: ACTION_TYPES.PIN_KNOWLEDGE_CONTENT })
    }

    function handleLinkPress(url: string) {
        url = helpers.processContentLink(url)
        // helpers.knowledgeFileGet(url)
    }

    function handleNavigateToParent() {
        if (!contentInFocus) return
        helpers.knowledgeContentGet(contentInFocus.parent)
    }

    function handleEditContent() {
        setEditMode(!editMode)
    }

    function handleSubmit() {
        if (!editMode) return
        // helpers.knowledgeContentUpdate(contentInFocus.id, contentInFocus)
    }

    function handleDelete() {
        helpers.knowledgeContentDelete(contentInFocus)
    }
    
    return (
        <SafeAreaView>
            <ScrollView>
                {/* Header */}
                <View style={styles.header}>
                    <Button
                        text={'🔼'}
                        onPress={() => {
                            helpers.knowledgeContentGet(contentInFocus.parent)
                        }}
                    />
                    <Button
                        text={'⏫'}
                        onPress={() => {
                            helpers.knowledgeContentGet(null)
                        }}
                    />
                    <Button
                        text={'🔄'}
                        onPress={() => {
                            helpers.randomKnowledgeGet(pinnedContent ? pinnedContent.id : null)
                        }}
                    />
                    <Button
                        text={'📌'}
                        onPress={() => {
                            handlePinContent()
                        }}
                    />
                    <Button
                        text={'➕'}
                        onPress={() => {
                            setAddChildModalVisible(true)
                        }}
                    />
                    <Modal
                        onRequestClose={() => setAddChildModalVisible(false)}
                        visible={addChildModalVisible}
                    >
                        <Text>Add Child Content</Text>
                        <TextInput
                            value={newChildTitle}
                            extraStyle={{
                                textAlign: 'center',
                                fontSize: 24,
                                paddingTop: 8,
                                paddingBottom: 8,
                                // color: sectionsVisibility.tasks ? 'white' : '#222',
                                backgroundColor: '#aaa',  
                            }}
                            onChangeText={(text) => setNewChildTitle(text)}
                        />
                        <Button onPress={() => setAddChildModalVisible(false)} text='Cancel' />
                        <Button onPress={() => { setAddChildModalVisible(false); handleCreateNewChild() }} text='Create' />
                    </Modal>
                    <Button
                        text={editMode ? '💾' : '✏️'}
                        onPress={() => { handleEditSave() }}
                        onLongPress={() => { setEditMode(!editMode); setContentFields(contentInFocus) }}
                    />
                    <Button
                        text='❌'
                        onPress={() => setDeleteModalVisible(true)}
                    />                
                    <Modal
                        onRequestClose={() => setDeleteModalVisible(false)}
                        visible={deleteModalVisible}
                    >
                        <Text>Confirm Delete?</Text>
                        <Button onPress={() => setDeleteModalVisible(false)} text='Cancel' />
                        <Button onPress={() => { setDeleteModalVisible(false); handleDelete() }} text='Delete' />
                    </Modal>
                    <Button
                        text={'👤'}
                        onPress={() => navigate(SCREENS.USER_OPTIONS, {})}
                    />
                </View>

                {/* If Pinned Content exists, show that */}
                {pinnedContent && (
                        <View style={{marginHorizontal: 12, marginTop: 4}}>
                            <TextLabel text={`📌 ${pinnedContent.title}`} extraStyles={{
                                backgroundColor: '#333',
                                color: 'white',
                                padding: 8,
                            }} />
                        </View>
                )}

                {/* Title */}
                {
                    !editMode && <Text
                        style={{
                            textAlign: 'center',
                            fontSize: 24,
                            paddingTop: 8,
                            paddingBottom: 8,
                            // color: sectionsVisibility.tasks ? 'white' : '#222',
                            backgroundColor: '#aaa',  
                        }}
                    >
                        {contentInFocus ? contentInFocus.title : '< No Content >'}
                    </Text>
                }
                {
                    editMode && <TextInput
                            value={contentFields.title}
                            extraStyle={{
                                textAlign: 'center',
                                fontSize: 24,
                                paddingTop: 8,
                                paddingBottom: 8,
                                // color: sectionsVisibility.tasks ? 'white' : '#222',
                                backgroundColor: '#aaa',  
                            }}
                            onChangeText={(text) => setContentFields({...contentFields, title: text})}
                        />
                }

                {/* Content path */}
                <Text
                    style={{
                        textAlign: 'center',
                        fontSize: 12,
                        paddingTop: 8,
                        paddingBottom: 8,
                        // color: sectionsVisibility.tasks ? 'white' : '#222',
                        backgroundColor: '#ddd',  
                    }}
                    onPress={() => setSelectParentModalVisible(true)}
                >
                    {contentInFocus ? contentInFocus.full_path : '< No Path >'}
                </Text>
                <Modal
                    onRequestClose={() => setSelectParentModalVisible(false)}
                    visible={selectParentModalVisible}
                >
                    <ScrollView>
                        <Text>Enter search text:</Text>
                        <TextInput
                            value={parentSearchText}
                            onChangeText={(text: string) => {
                                setParentSearchText(text || '')
                                fetchParentSuggestions()
                            }}
                        />
                        {parentSearchSuggestions
                            .map((s: ParentSearchSuggestion) => (
                            <Button
                                key={s.id}
                                text={s.title}
                                onPress={() => {
                                    helpers.knowledgeContentPatch(contentInFocus.id, { parent: s.id })
                                    setSelectParentModalVisible(false)
                                }}
                            />
                        ))}
                    </ScrollView>
                    <Button
                        text='Cancel'
                        onPress={() => setSelectParentModalVisible(false)}
                        primary
                    />
                </Modal>

                {/* Show children */}
                <Text
                        style={{
                            textAlign: 'center',
                            fontSize: 20,
                            marginVertical: 16,
                            // paddingTop: 8,
                            // paddingBottom: 8,
                            color: '#777',
                            // backgroundColor: '#aaa',  
                        }}
                    >
                        Children
                </Text>
                {contentInFocus && 
                    <View style={{justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                        {contentInFocus?.immediate_children.map((child, index) => (
                            <Button
                                key={index}
                                text={child.title}
                                onPress={() => helpers.knowledgeContentGet(child.id)}
                            />
                        ))}
                    </View>
                }

                {/* Description */}
                <Text
                        style={{
                            textAlign: 'center',
                            fontSize: 20,
                            marginVertical: 16,
                            // paddingTop: 8,
                            // paddingBottom: 8,
                            color: '#777',
                            // backgroundColor: '#aaa',  
                        }}
                    >
                        Description
                </Text>

                { !editMode && <View style={styles.knowledgeContent}>
                    <ScrollView>
                        <Markdown 
                            onLinkPress={(url) => handleLinkPress(url)}
                        >
                            { contentInFocus && contentInFocus.description !== "" ? contentInFocus.description : "<No Content Description>" }
                        </Markdown>
                    </ScrollView>
                </View>}
                { editMode && <TextInput
                    value={contentFields.description}
                    extraStyle={{
                        height: 400,
                        // textAlign: 'center',
                        // fontSize: 20,
                        // paddingTop: 8,
                        // paddingBottom: 8,
                        // color: sectionsVisibility.tasks ? 'white' : '#222',
                        backgroundColor: '#aaa',  
                    }}
                    multiline
                    onChangeText={(text) => setContentFields({...contentFields, description: text})}
                />}
            </ScrollView>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    knowledgeContent: {
        padding: 20,
        margin: 8,
        backgroundColor: '#ddd',
        borderRadius: 4,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    }
})