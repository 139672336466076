import * as React from 'react'
import { FlatList, ScrollView, View, StyleSheet, TouchableOpacity, Text, SectionList, Alert } from 'react-native'
import { useSelector } from 'react-redux'
import { HoldItem } from 'react-native-hold-menu'

import Button from 'src/app/components/Button'
import TextLabel from 'src/app/components/TextLabel'
import { SCREENS } from 'src/time/components/TasksNavigator'
import store from 'src/app/redux/store'
import * as helpers from 'src/time/helpers'
import * as TasksNavigator from '../../components/TasksNavigator'
import ActiveTaskBar from 'src/time/components/ActiveTaskBar'
import TextInput from 'src/app/components/TextInput'
import TaskItem from 'src/time/components/TaskItem'

export default function GoalTasks({ navigation, route }) {
    // console.log('GoalTasks mounted', route.params)
    const selectedTaskId = route.params?.parentTaskId
    const allTasks = useSelector(state => state.todos.tasks)

    const [searchTerm, updateSearchTerm] = React.useState('')
    const [flagFilter, setFlagFilter] = React.useState(false)

    const tasks = allTasks
                    .map((t: Task) => { t.due_code = t.due_type ? `${t.due_type} : ${t.due_value}`: 'NA : NA' ; return t })
                    .filter((t: Task) => t.name.toLowerCase().includes(searchTerm.toLowerCase()))
                    
    const filteredTasks = selectedTaskId ? 
                            tasks.filter((t: Task) => t.parent_task === selectedTaskId) : 
                            tasks.filter((t: Task) => !t.parent_task)

    const sortedTasks = filteredTasks.sort((a: Task, b: Task) => a.due_code.localeCompare(b.due_code))

    const sortedTasksIncomplete = sortedTasks.filter((t: Task) => !t.completed)
    const sortedTasksComplete = sortedTasks.filter((t: Task) => t.completed)

    const groupedDataIncomplete = sortedTasksIncomplete.reduce((acc: any, curr: Task) => {
        if (!acc[curr.due_code]) {
            acc[curr.due_code] = [];
        }
        acc[curr.due_code].push(curr);
        return acc;
    }, {})

    const groupedDataComplete = sortedTasksComplete.reduce((acc: any, curr: Task) => {
        if (!acc[curr.due_code]) {
            acc[curr.due_code] = [];
        }
        acc[curr.due_code].push(curr);
        return acc;
    }, {})

    const sectionsIncomplete = Object.keys(groupedDataIncomplete).map(key => ({
        title: key,
        data: groupedDataIncomplete[key],
      }))

    const sectionsComplete = Object.keys(groupedDataComplete).map(key => ({
    title: key,
    data: groupedDataComplete[key],
    }))

    const activeTaskId = useSelector((state: RootState) => state.todos.userProfile.active_task)

    const selectedTask = tasks.find((t: Task) => t.id === selectedTaskId)
    // console.log('selectedTask', selectedTask)
    const taskName = selectedTask?.name
    const taskDueCode = `${selectedTask?.due_type} : ${selectedTask?.due_value}`
    const parentTaskId = selectedTask?.parent_task

    navigation.setOptions({ title: '🔼 '+ (taskName || 'TASKS') })

    const MenuItems = [
        // {
        //     text: 'Complete',
        //     onPress: () => helpers.markTaskComplete(item.id),
        // },
        {
            text: 'Edit',
            // onPress: (id: number) => {
            //     TasksNavigator.navigate(SCREENS.ADD_EDIT_TASK, { mode: 'TASK_EDIT', payload: { id }})
            // },
        },
        // {
        //     text: 'Delete',
        //     isDestructive: true,
        //     onPress: () => {
        //         showDeleteConfirmationAlert()
        //     },
        // },
    ]

    function showDeleteConfirmationAlert() {
        Alert.alert(
        "Confirm",
        `Sure you want to delete: ${item.name}?`,
        [
            {
                text: "Cancel",
                onPress: () => {},
                style: "cancel"
            },
            { 
                text: "Delete", 
                onPress: () => deleteTask() ,
                style: "destructive"
            }
        ]
        )
    }

    function ListItem({ item }: { item: Task }) {
        const childTaskCount = helpers.getChildTaskCountForTask(item, allTasks)
        const childTaskCountText = childTaskCount ? `(${childTaskCount})` : ''
        // return (
            // <HoldItem 
            //     items={MenuItems}
            //     actionParams={{
            //         "Edit": [item.id],
            //     }}
            // >
            // <View style={styles.taskContainer}>
            //     <TouchableOpacity
            //         onPress={() => { navigation.push(SCREENS.GOAL_TASKS, { parentTaskId: item.id }) }}
            //         onLongPress={() => {
            //             // Go to add edit task screen
            //             navigation.navigate(SCREENS.ADD_EDIT_TASK, { mode: 'TASK_EDIT', payload: { id: item.id } })
            //         }}
            //         key={item.id}
            //     >
            //         <View style={styles.titleContainer}>
            //             <TextLabel extraStyles={{fontSize: 16}} text={`${item.name} ${childTaskCountText}${helpers.getDescriptionStatus(item)}${helpers.getTimeLogStatus(item)}`} />
            //         </View>
            //     </TouchableOpacity>
            // </View>
            // </HoldItem>
        // )
        return (
            <TaskItem
                item={item}
                parentTaskName={taskName}
                childTaskCountText={childTaskCountText}
                // dependencyTaskName={dependencyTaskName}
                rootNavigation={navigation}
            />
        )
    }

    function SectionHeader({ title }: { title: string }) {
        return (
            <View style={{backgroundColor: '#ccc', padding: 4}}>
                <TouchableOpacity
                    onPress={() => { title != "NA : NA" ? navigation.navigate(SCREENS.GOAL_TIME, { dueCode: title }) : null }}
                    // onLongPress={() => {
                    //     // Go to add edit task screen
                    //     navigation.navigate(SCREENS.ADD_EDIT_TASK, { id: item.id, list: item.list })
                    // }}
                    key={title}
                >
                    <TextLabel text={title} />
                </TouchableOpacity>
            </View>
        )
    }

    return (
        <>
            {activeTaskId && <ActiveTaskBar />}
            <View
                style={styles.header}
            >
                 <Button
                    text='🔼'
                    onPress={() => navigation.navigate(SCREENS.GOAL_TASKS, { parentTaskId })}
                    extraStyle={{ minWidth: 48 }}
                />
                {taskDueCode && <Button
                    text='🗓️'
                    onPress={() => navigation.navigate(SCREENS.GOAL_TIME, { dueCode: taskDueCode })}
                    extraStyle={{ minWidth: 48 }}
                />}
                <Button
                    text='➕'
                    onPress={() => navigation.navigate(SCREENS.ADD_EDIT_TASK, { mode: 'TASK_ADD', payload: { parentTaskId: selectedTaskId } })}
                    extraStyle={{ minWidth: 48 }}
                />
                <Button
                    text='✏️'
                    onPress={() => navigation.navigate(SCREENS.ADD_EDIT_TASK, { mode: 'TASK_EDIT', payload: { id: selectedTaskId } })}
                    extraStyle={{ minWidth: 48 }}
                />
                <Button
                    text='▶️'
                    onPress={() => helpers.handleTaskStart(selectedTaskId)}
                    extraStyle={{ minWidth: 48 }}
                />
                <Button
                    text='🚩'
                    onPress={() => setFlagFilter(!flagFilter)}
                    extraStyle={{ minWidth: 48 }}
                />
            </View>
            <TextInput
                placeholder='Search'
                onChangeText={(text) => updateSearchTerm(text)}
                extraStyle={{
                    margin: 8,
                    padding: 8,
                    borderWidth: 1,
                    borderColor: '#ccc',
                    borderRadius: 8,
                    backgroundColor: '#fff'
                }}
            />
            <ScrollView>
                <Text
                    style={{
                        textAlign: 'center',
                        fontSize: 24,
                        // paddingTop: 8,
                        margin: 12,
                        color: '#777'
                    }}
                >
                    Tasks
                </Text>
                <SectionList
                    sections={sectionsIncomplete}
                    renderItem={({ item }) => ListItem({ item })}
                    renderSectionHeader={({ section: { title } }) => SectionHeader({ title })}
                    keyExtractor={(item) => item.id}
                />
                <Text
                    style={{
                        textAlign: 'center',
                        fontSize: 24,
                        // paddingTop: 8,
                        margin: 12,
                        color: '#777'
                    }}
                >
                    Completed Tasks
                </Text>
                <SectionList
                    sections={sectionsComplete}
                    renderItem={({ item }) => ListItem({ item })}
                    renderSectionHeader={({ section: { title } }) => SectionHeader({ title })}
                    keyExtractor={(item) => item.name}
                />
                {/* <FlatList
                    data={filteredTasks.filter((t: Task) => {
                        if (flagFilter) {
                            return t.flagged && t.name.toLowerCase().includes(searchTerm.toLowerCase())
                        } else {
                            return t.name.toLowerCase().includes(searchTerm.toLowerCase())
                        }
                    })}
                    renderItem={ListItem}
                    keyExtractor={(item: Task) => item.id.toString()}
                /> */}
            </ScrollView>
        </>
    )
}

const styles = StyleSheet.create({
    checkboxContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 8
    },
    contentContainer: {},
    flagContainer: {
        marginRight: 4
    },
    footerContainer: {
        // backgroundColor: 'red',
    },
    footerText: {
        fontSize: 12,
        color: '#777'
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    taskItemBodyContainer: {
        flex: 1,
        flexDirection: 'row'
    },
    titleBodyContainer: {
        flexDirection: 'row',
        // backgroundColor: 'green',
    },
    titleContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    taskContainer: {
        flex: 1,
        flexDirection: 'column',
        // backgroundColor: 'red',
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
        paddingTop: 4,
        paddingBottom: 4
    },
})