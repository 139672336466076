import { StyleSheet } from "react-native"

export default StyleSheet.create({
    container: {
        flex: 1, 
        justifyContent: 'center', 
        paddingHorizontal: 32
    },
    formContainer: {
        borderWidth:1, 
        borderColor:'#ccc'
    },
})