
import * as React from 'react'
import { Platform, SafeAreaView, ScrollView, StyleSheet, Switch, Text, View } from 'react-native'
import { useSelector } from 'react-redux'
import { Calendar } from 'react-native-calendars'

import { RootState } from 'src/app/redux/store'
import TextInput from 'src/app/components/TextInput'
import TextLabel from 'src/app/components/TextLabel'
import Button from 'src/app/components/Button'
import Modal from 'src/app/components/Modal'
import * as helpers from '../../helpers'
import { SCREENS } from '../../components/TasksNavigator'


export default function AddEditTask({ navigation, route }: { navigation: any, route: any }) {
    const { mode, payload } = route.params
    console.log('Time > AddEditTask Mounted with params:', route.params)

    let id: number | null, name, parentTaskId
    id = null
    if (mode === 'TASK_EDIT') {
        id = payload.id
    } else if (mode === 'TASK_ADD') {
        parentTaskId = payload.parentTaskId
        name = payload.name
    } else {
        id = null
    }

    const emptyTask = {
        name: name ? name : '',
        description: '',
        flagged: false,
        due_date: '',
        due_time: '',
        cron_schedule: '',
        duration: '',
        completed: false,
        completion_remarks: '',
        dependency_task: '',
        due_type: '',
        due_value: '',
        parent_task: parentTaskId ? parentTaskId : '',
    }
    const tasks = useSelector((state: RootState) => state.todos.tasks)
    const task = tasks.find((t: Task) => t.id === id)
    const lists = useSelector((state: RootState) => state.todos.lists)

    const [taskFields, updateTaskFields] = React.useState(id ? task : emptyTask)
    const [deleteModalVisible, setDeleteModalVisible] = React.useState(false)
    
    const [listModalVisible, setListModalVisible] = React.useState(false)
    const [listSearchText, setListSearchText] = React.useState('')
    
    const [dependencyTaskModalVisible, setDependencyTaskModalVisible] = React.useState(false)
    const [dependencyTaskSearchText, setDependencyTaskSearchText] = React.useState('')

    const [parentTaskModalVisible, setParentTaskModalVisible] = React.useState(false)
    const [parentTaskSearchText, setParentTaskSearchText] = React.useState('')
    
    const [dueDateModalVisible, setDueDateModalVisible] = React.useState(false)

    const handleSubmit = async () => {

        const taskFieldsFormatted = JSON.parse(JSON.stringify(taskFields))

        if (id) { // Edit existing task
            await helpers.taskPatch(taskFieldsFormatted, id)
        } else { // Add new task
            await helpers.taskPost(taskFieldsFormatted)
        }
    }

    const handleDelete = async () => {
        navigation.goBack()
        id && helpers.taskDelete(id)
    }

    return (
        <>
            <View style={styles.header}>
                <Button
                    text={'💾'}
                    onPress={() => { handleSubmit(); navigation.goBack() }}
                />
                <Button
                    text={'💾➕'}
                    onPress={() => {
                        handleSubmit()
                        updateTaskFields(emptyTask)
                        navigation.navigate(SCREENS.ADD_EDIT_TASK, { list, id: null })
                    }}
                />
                <Button
                    text={'◀️🗓️'}
                    onPress={() => { 
                        if (taskFields.due_type) {
                            let currentlySetValue = taskFields.due_value || helpers.getCurrentTimeWindows(taskFields.due_type)
                            const currentlySetDueCode = `${taskFields.due_type} : ${currentlySetValue}`
                            updateTaskFields({ ...taskFields, 
                                due_value: helpers.getPreviousDueCode(currentlySetDueCode).split(' : ')[1]
                            })
                        }
                     }}
                />
                <Button
                    text={'🗓️▶️'}
                    onPress={() => { 
                        if (taskFields.due_type) {
                            let currentlySetValue = taskFields.due_value || helpers.getCurrentTimeWindows(taskFields.due_type)
                            const currentlySetDueCode = `${taskFields.due_type} : ${currentlySetValue}`
                            updateTaskFields({ ...taskFields, 
                                due_value: helpers.getNextDueCode(currentlySetDueCode).split(' : ')[1]
                            })
                        }
                     }}
                />
                <Button
                    text={'⏰'}
                    onPress={() => { navigation.navigate(SCREENS.TIME_LOGS_LIST, { source_type: 'task', source_id: task.id }) }}
                />
                <Button
                    text={'➕⏰'}
                    onPress={() => { navigation.navigate(SCREENS.TIME_LOG_ADD_EDIT, { source_type: 'task', source_id: task.id }) }}
                />
                {
                    id && (
                        <Button
                            text='❌'
                            onPress={() => setDeleteModalVisible(true)}
                        />
                    )
                }
                <Modal
                    onRequestClose={() => setDeleteModalVisible(false)}
                    visible={deleteModalVisible}
                >
                    <Text>Confirm Delete?</Text>
                    <Button onPress={() => setDeleteModalVisible(false)} text='Cancel' />
                    <Button onPress={() => { setDeleteModalVisible(false); handleDelete() }} text='Delete' />
                </Modal>
            </View>
            <ScrollView>
                <Text>Name: </Text>
                <TextInput
                    value={taskFields.name}
                    onChangeText={(name: string) => updateTaskFields({ ...taskFields, name })}
                    placeholder="Enter task name"
                />
                <TextLabel text='Parent task:' />
                <Button onPress={() => setParentTaskModalVisible(true)} 
                        text={tasks.find((t: Task) => t.id == taskFields.parent_task)?.name} 
                />
                <Modal
                    onRequestClose={() => setParentTaskModalVisible(false)}
                    visible={parentTaskModalVisible}
                >
                    <ScrollView>
                        <Text
                            style={{paddingTop: 10}}
                        >
                            Search text:
                        </Text>
                        <TextInput
                            value={parentTaskSearchText}
                            onChangeText={(searchText: string) => setParentTaskSearchText(searchText || '')}
                        />
                        {tasks
                            .filter((t: Task) => t.name.toLowerCase().includes(parentTaskSearchText.toLowerCase()))
                            .sort((a: Task, b: Task) => a.name.localeCompare(b.name))
                            .map((t: Task) => {
                                // Get full breadcrumb of ancestor tasks for the current task going up to the ancestor without any parent
                                let taskName = ""
                                let parentTask = tasks.find((pt: Task) => pt.id == t.parent_task)
                                while (parentTask) {
                                    taskName = `${parentTask.name} > ${taskName}`
                                    parentTask = tasks.find((pt: Task) => pt.id == parentTask.parent_task)
                                }
                                taskName = taskName.slice(0, -3) // Remove the last ' > ' from the breadcrumb

                                return (
                                    <View style={{ borderWidth: 1, borderColor: 'black', borderRadius: 4, margin: 2, padding: 2 }}>
                                        <Button
                                            key={t.id}
                                            text={t.name}
                                            onPress={() => {
                                                updateTaskFields({ ...taskFields, parent_task: t.id })
                                                setParentTaskModalVisible(false)
                                            }}
                                        />
                                        <Text>{taskName}</Text>
                                    </View>
                                )
                            })}
                    </ScrollView>
                    <Button
                        text='Cancel'
                        onPress={() => setParentTaskModalVisible(false)}
                        primary
                    />
                </Modal>
                <Text>Due Type: </Text>
                <TextInput
                    value={taskFields.due_type}
                    onChangeText={(due_type: string) => 
                        updateTaskFields({ ...taskFields, 
                            due_type: due_type === '' ? '' : due_type,
                            due_value: helpers.getCurrentTimeWindows(due_type)
                         })
                    }
                /> 
                <Text>Due Value: </Text>
                <TextInput
                    value={taskFields.due_value}
                    onChangeText={(due_value: string) => updateTaskFields({ ...taskFields, due_value: due_value === '' ? '' : due_value })}
                />
                <Text>Due Date: </Text>
                <TextInput
                    value={taskFields.due_date}
                    onChangeText={(due_date: string) => updateTaskFields({ ...taskFields, due_date: due_date === '' ? '' : due_date })}
                />
                <Text>Description: </Text>
                <TextInput
                    value={taskFields.description}
                    onChangeText={(description: string) => updateTaskFields({ ...taskFields, description })}
                    multiline
                />
                {/* <Button
                    text={taskFields.due_date}
                    onPress={() => setDueDateModalVisible(true)}
                /> */}
                <Modal
                    onRequestClose={() => setDueDateModalVisible(false)}
                    visible={dueDateModalVisible}
                >
                    <Calendar
                        current={taskFields.due_date}
                        onDayPress={(day) => { updateTaskFields({ ...taskFields, due_date: day.dateString }); setDueDateModalVisible(false) }}
                        markedDates={{ [taskFields.due_date]: { selected: true } }}
                    />
                    <Button
                        text='Clear due date'
                        onPress={() => { setDueDateModalVisible(false); updateTaskFields({ ...taskFields, due_date: '' }) }}
                    />
                    <Button
                        text='Cancel'
                        onPress={() => setDueDateModalVisible(false)}
                    />
                </Modal>
                <Text>Due Time: </Text>
                <TextInput
                    value={taskFields.due_time}
                    onChangeText={(due_time: string) => updateTaskFields({ ...taskFields, due_time: due_time === '' ? '' : due_time })}
                />
                <Text>Cron Schedule: </Text>
                <TextInput
                    value={taskFields.cron_schedule}
                    onChangeText={(cron_schedule: string) => updateTaskFields({ ...taskFields, cron_schedule: cron_schedule === '' ? '' : cron_schedule })}
                />
                <TextLabel text={`(Cron expression: ${helpers.getCronDescription(taskFields.cron_schedule)})`} />
                <Text>Duration: </Text>
                <TextInput
                    value={taskFields.duration}
                    onChangeText={(duration: string) => updateTaskFields({ ...taskFields, duration: duration === '' ? '' : duration })}
                />
                <Text>Flagged: </Text>
                {Platform.OS === 'web' && (taskFields.flagged === true || taskFields.flagged === false)
                    ? <input type="checkbox" checked={taskFields.flagged} onChange={() => updateTaskFields({ ...taskFields, flagged: !taskFields.flagged })} />
                    : <Switch value={taskFields.flagged} onValueChange={() => updateTaskFields({ ...taskFields, flagged: !taskFields.flagged })} />

                }
                <Text>Completed: </Text>
                {Platform.OS === 'web' && (taskFields.completed === true || taskFields.completed === false)
                    ? <input type="checkbox" checked={taskFields.completed} onChange={() => updateTaskFields({ ...taskFields, completed: !taskFields.completed })} />
                    : <Switch value={taskFields.completed} onValueChange={() => updateTaskFields({ ...taskFields, completed: !taskFields.completed })} />

                }
                <Text>Completion Remarks</Text>
                <TextInput
                    value={taskFields.completion_remarks}
                    onChangeText={(completion_remarks: string) => updateTaskFields({ ...taskFields, completion_remarks })}
                    placeholder="Enter completion remarks"
                    multiline
                />
                
                <TextLabel text='Dependency task:' />
                <Button onPress={() => setDependencyTaskModalVisible(true)} text={tasks.find((t: Task) => {
                        return t.id == taskFields.dependency_task
                    })?.name} />
                <Modal
                    onRequestClose={() => setDependencyTaskModalVisible(false)}
                    visible={dependencyTaskModalVisible}
                >
                    <ScrollView>
                        <Text
                            style={{paddingTop: 10}}
                        >
                            Search search text:
                        </Text>
                        <TextInput
                            value={dependencyTaskSearchText}
                            onChangeText={(searchText: string) => setDependencyTaskSearchText(searchText || '')}
                        />
                        {tasks
                            .filter((t: Task) => t.name.toLowerCase().includes(dependencyTaskSearchText.toLowerCase()))
                            .sort((a: Task, b: Task) => a.name.localeCompare(b.name))
                            .map((t: Task) => (
                            <Button
                                key={t.id}
                                text={t.name}
                                onPress={() => {
                                    updateTaskFields({ ...taskFields, dependency_task: t.id })
                                    setDependencyTaskModalVisible(false)
                                }}
                            />
                        ))}
                    </ScrollView>
                    <Button
                        text='Cancel'
                        onPress={() => setDependencyTaskModalVisible(false)}
                        primary
                    />
                </Modal>
                {/* <TextLabel text='List:' />
                <Button onPress={() => setListModalVisible(true)} text={lists.find((l: List) => l.id == taskFields.list)['name']} /> */}
                <Modal
                    onRequestClose={() => setListModalVisible(false)}
                    visible={listModalVisible}
                >
                    <ScrollView>
                        <Text>Enter search text:</Text>
                        <TextInput
                            value={listSearchText}
                            onChangeText={(listSearchText: string) => setListSearchText(listSearchText || '')}
                        />
                        {lists
                            .filter((l: List) => l.name.toLowerCase().includes(listSearchText.toLowerCase()))
                            .map((list: List) => (
                            <Button
                                key={list.id}
                                text={list.name}
                                onPress={() => {
                                    updateTaskFields({ ...taskFields, list: list.id })
                                    setListModalVisible(false)
                                }}
                            />
                        ))}
                    </ScrollView>
                    <Button
                        text='Cancel'
                        onPress={() => setListModalVisible(false)}
                        primary
                    />
                </Modal>
            </ScrollView>
        </>
    )
}

const styles = StyleSheet.create({
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    }
})