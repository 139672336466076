import { hideMessage, showMessage } from 'react-native-flash-message'
import AsyncStorage from '@react-native-async-storage/async-storage'

import axiosInstance from 'src/app/network'
import store from '../app/redux/store'
import * as services from './services'
import { ACTION_TYPES } from './reducers'

export const logUserIn = async function (userCredentials: UserCredentials) {
    showMessage({message: 'Logging you in...', type: 'info', icon: 'auto'})
    
    const result: Result = await services.authenticateUser(userCredentials)
    if (result.error) { // Error
        showMessage({message: 'System error, try restarting the app', type: 'danger', icon: 'auto', duration: 3000})
        return
    } 

    showMessage({message: 'Authenticated. Redirecting...', type: 'success', icon: 'auto', duration: 3000})
    const tokens = result
    await AsyncStorage.setItem('tokens', JSON.stringify(tokens))
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${tokens.access}`
    setUpUserData(tokens.access)
    // hideMessage()
}

export const setUpUserData = async function (accessToken: string) {
    showMessage({message: 'Setting up user data...', type: 'info', icon: 'auto'})

    const result: Result = await services.getUserDetails(accessToken)
    if (result.error) { // Error
        showMessage({message: 'System error, try restarting the app', type: 'danger', icon: 'auto', duration: 3000})
        return
    }
    const {userDetails} = result
    console.log('User details:', userDetails)

    store.dispatch({ type: ACTION_TYPES.LOGIN_USER, payload: userDetails})
}

export const logUserOut = async function () {
    store.dispatch({ type: ACTION_TYPES.LOGOUT_USER})
    await AsyncStorage.removeItem('tokens')
    showMessage({message: 'Logged out', type: 'info', icon: 'auto', duration: 3000})
}

export const signUserUp = async function (userCredentials: UserCredentials) {
    showMessage({
        message: 'Creating account...',
        type: 'info',
        icon: 'auto',
        duration: 0
    })
    const result: Result = await services.signUserUp(userCredentials)
    if (!result.error) {
        showMessage({
            message: 'Account created! Please login.',
            type: 'success',
            icon: 'auto',
            duration: 3000
        })
    } else {
        showMessage({
            message: result.error,
            type: 'danger',
            icon: 'auto',
            duration: 3000
        })
    }
}