import React from 'react'
import { ScrollView } from 'react-native'
import { useSelector } from 'react-redux'

import Button from 'src/app/components/Button'
import { RootState } from 'src/app/redux/store'
import * as authHelpers from 'src/auth/helpers'
import * as knowledgeHelpers from 'src/knowledge/helpers'
import * as helpers from '../../helpers'
import { APPS } from 'src/app/components/RootNavigator'

export default function UserOptions({navigation, route}) {
    const appNavigation = route.params.appNavigation

    const userProfile = useSelector((state: RootState) => state.todos.userProfile)

    const navigateToKnowledgeApp = () => {
        appNavigation.navigate(APPS.KNOWLEDGE)
    }

    return (
        <ScrollView>
            <Button
                text="Time App"
                onPress={() => {}}  
            />
            <Button
                text="Knowledge App"
                onPress={() => navigateToKnowledgeApp()}  
            />
            <Button
                text="Import Reminders"
                onPress={() => helpers.remindersImport(userProfile.ios_reminder_list_id, userProfile.default_list)}  
            />
            <Button
                text="Logout"
                onPress={() => {
                    helpers.logUserOut()
                    knowledgeHelpers.logUserOut()
                    authHelpers.logUserOut()
                }}
            />
            <Button
                text="Close"
                onPress={() => {navigation.goBack()}}
            />
        </ScrollView>
    )
}