import { hideMessage, showMessage } from 'react-native-flash-message'

import * as services from './services'
import store from 'src/app/redux/store'
import { ACTION_TYPES } from './reducers'

export function logUserOut() {
    store.dispatch({ type: ACTION_TYPES.CLEAR_KNOWLEDGE_DATA})
}

export async function knowledgeContentGet(id: number | null, bringInFocus: boolean = true) {
    const response = await services.knowledgeContentGet(id)

    if (!response.error) {
        const knowledgeResponse = response.data
        // showMessage({ message: `Fetched content: ${knowledgeResponse.filepath}`, type: 'success', icon: 'auto' })
        if (bringInFocus) {
            store.dispatch({ type: ACTION_TYPES.UPDATE_KNOWLEDGE_CONTENT, payload: knowledgeResponse })
        } else {
            return knowledgeResponse
        }
    } else {
        showMessage({ message: `Error fetching content`, type: 'danger', icon: 'auto' })
    }
}

export async function knowledgeContentPost(content: KnowledgeContent) {
    const response = await services.knowledgeContentPost(content)

    if (!response.error) {
        showMessage({ message: `Created content: ${content.title}`, type: 'success', icon: 'auto' })
        const response2 = await services.knowledgeContentGet(content.parent)
        const knowledgeResponse = response2.data
        store.dispatch({ type: ACTION_TYPES.UPDATE_KNOWLEDGE_CONTENT, payload: knowledgeResponse })
    } else {
        showMessage({ message: `Error creating content`, type: 'danger', icon: 'auto' })
    }
}

export async function knowledgeContentPatch(id: number, data: KnowledgeContent) {
    const response = await services.knowledgeContentPatch(id, data)

    if (!response.error) {
        const knowledgeResponse = response.data
        showMessage({ message: `Updated content: ${knowledgeResponse.title}`, type: 'success', icon: 'auto' })
        store.dispatch({ type: ACTION_TYPES.UPDATE_KNOWLEDGE_CONTENT, payload: knowledgeResponse })
    } else {
        showMessage({ message: `Error updating content`, type: 'danger', icon: 'auto' })
    }
}

export async function knowledgeContentDelete(content: KnowledgeContent) {
    const response = await services.knowledgeContentDelete(content.id)

    if (!response.error) {
        showMessage({ message: `Deleted content: ${content.title}`, type: 'success', icon: 'auto' })
        const response2 = await services.knowledgeContentGet(content.parent)
        const knowledgeResponse = response2.data
        store.dispatch({ type: ACTION_TYPES.UPDATE_KNOWLEDGE_CONTENT, payload: knowledgeResponse })
    } else {
        showMessage({ message: `Error deleting content`, type: 'danger', icon: 'auto' })
    }
}

export async function knowledgeContentSearch(query: string) {
    const response = await services.knowledgeContentSearch(query)

    if (!response.error) {
        const searchResponse = response.data
        showMessage({ message: `Searched content length: ${searchResponse.length} results`, type: 'success', icon: 'auto' })
        return searchResponse
    } else {
        showMessage({ message: `Error searching content`, type: 'danger', icon: 'auto' })
    }

}

// export async function knowledgeFileGet(path: string | null) {
//     const response = await services.knowledgeFileGet(path)

//     if (!response.error) {
//         const knowledgeFileResponse = response.data
//         // showMessage({ message: `Fetched content: ${knowledgeFileResponse.filepath}`, type: 'success', icon: 'auto' })
//         store.dispatch({ type: ACTION_TYPES.UPDATE_KNOWLEDGE_FILE_CONTENT, payload: knowledgeFileResponse })
//         // console.log('knowledgeFileResponse', knowledgeFileResponse)
//     } else {
//         showMessage({ message: `Error fetching content: ${path}`, type: 'danger', icon: 'auto' })
//     }
// }

export function processContentLink(url: string) {
    if (url.startsWith('../')) { // Need to go to parent file
        // Get the current file path from the store
        const state = store.getState()
        const currentFilepath = state.knowledge.filepath

        // Remove the last segment of the path twice
        const currentFilepathArray = currentFilepath ? currentFilepath.split('/') : []
        currentFilepathArray.pop() // Remove file name
        currentFilepathArray.pop() // Remove folder name
        const newPath = currentFilepathArray.join('/')
        url = newPath + '/' + url.replace('../', '') // Add the new path to the url
        return url
    } else if (url.startsWith('./')) { // Go to a child file
        // Get the current file path from the store
        const state = store.getState()
        const currentFilepath = state.knowledge.filepath

        // Remove the last segment of the path
        const currentFilepathArray = currentFilepath ? currentFilepath.split('/') : []
        currentFilepathArray.pop() // Remove file name
        const newPath = currentFilepathArray.join('/')
        url = newPath + '/' + url.replace('./', '') // Add the new path to the url
        return url
    } else {
        return url
    }
}

export async function randomKnowledgeGet(id: number | null) {
    const state = store.getState()

    const response = await services.randomKnowledgeGet(id)

    if (!response.error) {
        const knowledgeFileResponse = response.data
        showMessage({ message: `Fetched content: ${knowledgeFileResponse.title}`, type: 'success', icon: 'auto' })
        store.dispatch({ type: ACTION_TYPES.UPDATE_KNOWLEDGE_CONTENT, payload: knowledgeFileResponse })
    } else {
        showMessage({ message: `Error fetching random content`, type: 'danger', icon: 'auto' })
    }
}

export async function pinContent() {
    store.dispatch({ type: ACTION_TYPES.PIN_KNOWLEDGE_CONTENT })
}