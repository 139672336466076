const initialState = {
}

declare type InitialState = {
}

export const ACTION_TYPES = {
}

export default function reducer(state = initialState, action: ReduxAction) {
    switch (action.type) {
        default:
            return state;
    }
}