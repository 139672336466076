import * as React from 'react'
import { useState } from 'react'
import { View } from 'react-native'

import TextInput from 'src/app/components/TextInput'
import Button from 'src/app/components/Button'

import * as auth from '../../helpers'
import style from './style'

function SignUp({ navigation }) {
    const [firstName, updateFirstName] = useState('')
    const [lastName, updateLastName] = useState('')
    const [email, updateEmail] = useState('')
    const [password, updatePassword] = useState('')
    const [repeatPassword, updateRepeatPassword] = useState('')

    return (
        <View style={style.container}>
            <TextInput
                value={firstName}
                onChangeText={(text) => updateFirstName(text)}
                placeholder='First Name'
            />
            <TextInput
                value={lastName}
                onChangeText={(text) => updateLastName(text)}
                placeholder='Last Name'
            />
            <TextInput
                value={email}
                onChangeText={(text) => updateEmail(text)}
                placeholder='Email'
            />
            <TextInput
                value={password}
                onChangeText={(text) => updatePassword(text)}
                placeholder='Password'
                secureTextEntry
            />
            <TextInput
                value={repeatPassword}
                onChangeText={(text) => updateRepeatPassword(text)}
                placeholder='Re-enter Password'
                secureTextEntry
            />
            <Button
                text='Sign Up'
                disabled={!(email !== '' && password !== '' && repeatPassword !== '' && password === repeatPassword)}
                onPress={() => {
                    auth.signUserUp({ email, password, firstName, lastName })
                    navigation.goBack()
                }}
                primary
                extraStyle={{ marginTop: 20 }}
            />
            <Button
                text='Back to Login'
                onPress={() => navigation.goBack()}
            />
        </View>
    )
}

export default SignUp
