import * as Calendar from 'expo-calendar'
import cronstrue from 'cronstrue'
import { hideMessage, showMessage } from 'react-native-flash-message'
import Notifications from 'expo-notifications'
import Fuse from 'fuse.js'

// import { BASE_URL_WEBSOCKET } from 'react-native-dotenv'
import store from 'src/app/redux/store'
import * as services from './services'
import { ACTION_TYPES } from './reducers'
import AsyncStorage from '@react-native-async-storage/async-storage'

const IOS_REMINDER_LIST_ID = "E488A732-4A8E-40D6-9807-26A33B834CB4"
const INBOX_TASK_ID = 86

export var ws: WebSocket

export async function setUpWebSocket() {
    const tokensString = await AsyncStorage.getItem('tokens')

    if (!tokensString) {
        return
    }

    const tokens = JSON.parse(tokensString)
    try {
        ws = new WebSocket(`${BASE_URL_WEBSOCKET}?token=${tokens.access}`)

        ws.onopen = () => {
            ws.send('Hello from client')
            console.log('Websocket Connected')
          }
          
          ws.onmessage = (e) => {
            console.log('Websocket Message Received')
            console.log(e.data);
          }
          
          ws.onerror = (e) => {
            console.log('Websocket Error')
            console.log(e.message);
          }
          
          ws.onclose = (e) => {
            console.log('Websocket Closed')
            console.log(e.code, e.reason);
          }
    }   catch (err) {
        console.log('Error connecting with WebSocket: ', err)
    }
}

export function logUserOut() {
    store.dispatch({ type: ACTION_TYPES.CLEAR_TIME_DATA})
}

export async function calendarEventsFetch(date: string, calendarIds?: string[]) {
    const { status } = await Calendar.requestCalendarPermissionsAsync()
    let hardcodedCalendarIds: any = []

    // Use Expo calendar library to get calendar permission and get events for given date
    if (status === 'granted') {
        if (!calendarIds) {
            const calendars = await Calendar.getCalendarsAsync()
            hardcodedCalendarIds = [
                '28E270A1-BC0F-4D9C-ACE6-6F43EF824BB0' // DeHaat Work Calendar
            ]
        }
        const events = await Calendar.getEventsAsync(
            calendarIds || hardcodedCalendarIds,
            new Date(date),
            new Date((new Date(date)).getTime() + new Date(86400000).getTime())
        )
        return events
    } else {
        return []
    }
}

export const executeCommand = (transcription: string, navigation: any, allTasks: Task[], SCREENS: any, setFilterTaskId: Function, setFilterType: Function): void => {
    // console.log('executeCommand: ', transcription)
    // setTranscription('')
    const command = transcription.split(',')
    const intent = command[0].trim().toLowerCase()
    if (intent === "go to task") {
        const taskSearchText = command[1].trim()
        const task = getTaskFromSearchText(taskSearchText, allTasks)
        if (task) {
            showMessage({ message: `Task "${task.name}" found`, type: 'info' })
            navigation.navigate(SCREENS.GOAL_TASKS, { parentTaskId: task.id })
        } else {
            showMessage({ message: `Task ${taskSearchText} not found`, type: 'info' })
        }
    } else if (intent === "add task") {
        const parentSearchTerm = command[1].trim()
        const parentTask = getTaskFromSearchText(parentSearchTerm, allTasks)
        const newTaskName = command[2].trim()
        showMessage({ message: `Adding task "${newTaskName}" under "${parentTask?.name}"`, type: 'info' })    
        navigation.navigate(SCREENS.ADD_EDIT_TASK, { mode: 'TASK_ADD', payload: { parentTaskId: parentTask ? parentTask.id : null, name: newTaskName }})      
    } else if (intent === "filter tasks" || intent === "filter task") {
        const filterTaskSearchText = command[1].trim()
        if (filterTaskSearchText.startsWith('!') || filterTaskSearchText.split(' ')[0].toLocaleLowerCase() === 'not') {
            const filterOutTaskSearchText = filterTaskSearchText.replace('!', '').replace('not', '').trim()
            const filterOutTask = getTaskFromSearchText(filterOutTaskSearchText, allTasks)
            if (filterOutTask) {
                showMessage({ message: `Filtering out tasks of "${filterOutTask.name}"`, type: 'info' })
                setFilterTaskId(filterOutTask.id)
                setFilterType('OUT')
            } else {
                showMessage({ message: `Task for "${filterOutTaskSearchText}" not found`, type: 'info' })
            }
        } else {
            const filterTask = getTaskFromSearchText(filterTaskSearchText, allTasks)
            if (filterTask) {
                showMessage({ message: `Filtering in tasks of "${filterTask.name}"`, type: 'info' })
                setFilterTaskId(filterTask.id)
                setFilterType('IN')
            } else {
                showMessage({ message: `Task for "${filterTaskSearchText}" not found`, type: 'info' })
            }
        }
    } else {
        showMessage({ message: `Intent ${intent} not recognized`, type: 'info' })
    }
}

export const getOverdueTasks = (allTasks: Task[], dueCode: string) => {
    const currentDueType = dueCode.split(':')[0].trim()
    const tasksOfCurrentDueType = allTasks.filter((t: Task) => t.due_type === currentDueType)
    const overdueTasks = tasksOfCurrentDueType.filter((t: Task) => {
        if (t.completed) return false
        if (currentDueType === 'L') return false
        return `${t.due_type} : ${t.due_value}` < dueCode
    })
    return overdueTasks
}

export function getTaskCountForTimeWindow(timeWindow: string, allTasks: Task[]): string {
    const [timeWindowType, timeWindowValue] = timeWindow.split(' : ')
    const allTasksInTimeWindow = allTasks
                                .filter((task: Task) => {
                                    return task.due_type === timeWindowType && task.due_value === timeWindowValue
                                })
    const openTasksInTimeWindow = allTasksInTimeWindow.filter((task: Task) => !task.completed)
    return `${openTasksInTimeWindow.length}/${allTasksInTimeWindow.length}`
}

export function getChildTaskCountForTask(task: Task, allTasks: Task[]): string {
    const allChildTasks = allTasks
                        .filter(t => t.parent_task === task.id)
    const openChildTasks = allChildTasks.filter(t => !t.completed)
    return `${openChildTasks.length}/${allChildTasks.length}`
}

export function getCronDescription(cron: string) {
    try {
        let string = cronstrue.toString(cron, { verbose: false })
        string = string.replace('Monday', 'M')
        string = string.replace('Tuesday', 'Tu')
        string = string.replace('Wednesday', 'W')
        string = string.replace('Thursday', 'Th')
        string = string.replace('Friday', 'F')
        string = string.replace('Saturday', 'Sa')
        string = string.replace('Sunday', 'Su')
        string = string.replace('and', '&')
        return string
    } catch (err) {
        return 'Invalid expression'
    }
}

export function getDescriptionStatus(task: Task): string {
    if (task.description != '') {
        return ' 📝'
    } else {
        return ''
    }
}

export function getTaskFromSearchText(searchText: string, tasks: Task[]): Task | undefined {
    const fuseOptions = {
        keys: ['name']
    }
    const fuse = new Fuse(tasks, fuseOptions)
    const result = fuse.search(searchText)
    return result[0].item
}

export function getTimeLogStatus(task: Task): string {
    const timeLogs: TimeLog[] = store.getState().todos.timeLogs
    const taskTimeLogs = timeLogs.filter((tl: TimeLog) => tl.source_id === task.id)
    if (taskTimeLogs.length > 0) {
        return ' ⏰'
    } else {
        return ''
    }
}

export function getCurrentTimeWindows(returnForSpecificDueType: string | undefined) {
    const currentTimeWindows = []
    currentTimeWindows.push('L : L')
    currentTimeWindows.push('TD : 31-60')
    currentTimeWindows.push('DD : 31-40')
    currentTimeWindows.push('Y : 2024')
    currentTimeWindows.push('Q : 2024-Q2')
    
    // Get current month in YYYY-MM format
    let today = new Date()
    let mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
    let yyyy = today.getFullYear()
    const currentMonth = yyyy + '-' + mm
    currentTimeWindows.push(`M : ${currentMonth}`)

    // Get date for previous Saturday in YYYY-MM-DD format
    const previousSaturday = new Date(today)
    previousSaturday.setDate(today.getDate() - (today.getDay() + 1) % 7)
    const dd2 = String(previousSaturday.getDate()).padStart(2, '0')
    const mm2 = String(previousSaturday.getMonth() + 1).padStart(2, '0') //January is 0!
    const yyyy2 = previousSaturday.getFullYear()
    const previousSaturdayDate = yyyy2 + '-' + mm2 + '-' + dd2
    currentTimeWindows.push(`W : ${previousSaturdayDate}`)

    // Get date in YYYY-MM-DD format
    today = new Date()
    const dd = String(today.getDate()).padStart(2, '0')
    mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
    yyyy = today.getFullYear()
    const todayDate = yyyy + '-' + mm + '-' + dd
    currentTimeWindows.push(`D : ${todayDate}`)
    
    if (returnForSpecificDueType !== undefined) {
        const dueCodeForSpecificDueType = currentTimeWindows.find(tw => tw.startsWith(`${returnForSpecificDueType} :`))
        const dueValueForSpecificDueType = dueCodeForSpecificDueType ? dueCodeForSpecificDueType.split(' : ')[1] : ''
        return dueValueForSpecificDueType
    }
    return currentTimeWindows
}

export function getUnplannedTasks(tasks: Task[], lists: List[], list?: List, folder?: Folder): Task[] {
    let unplannedTasks = tasks
                            .filter(task => !task.completed)
                            .filter(task => !task.due_date)
                            .filter(task => !task.cron_schedule)
                            .filter(task => !task.name.includes('♾'))
                            .filter(task => {
                                if (task.dependency_task) {
                                    const dependencyTask = tasks.find(t => t.id === task.dependency_task)

                                    // If dependency task is not complete, this task is not considered unplanned
                                    return dependencyTask && dependencyTask.completed 
                                } else {
                                    return true
                                }
                            })
                            .filter(task => {
                                const taskList = lists.find(l => l.id === task.list)
                                if (taskList) {
                                    return !taskList.archived && !taskList.name.includes('♾')
                                }
                                return true
                            })

    if (list) {
        unplannedTasks = unplannedTasks.filter(task => task.list === list.id)
    } else if (folder) {
        const folderLists = lists.filter(list => list.folder === folder.id).map(l => l.id)
        unplannedTasks = unplannedTasks.filter(task => folderLists.includes(task.list))
    }
    return unplannedTasks
}

export async function folderDelete(id: number) {
    const response = await services.folderDelete(id)
    if (!response.error) {
        const folder = response.data
        store.dispatch({ type: ACTION_TYPES.FOLDER_DELETE, payload: id })
    }
}

export async function folderPatch(folderFields: Folder, id: number) {
    const response = await services.folderPatch(folderFields, id)
    if (!response.error) {
        const folder = response.data
        store.dispatch({ type: ACTION_TYPES.FOLDER_ADD_UPDATE, payload: folder })
    }
}

export async function folderPost(folderFields: Folder) {
    const response = await services.folderPost(folderFields)
    if (!response.error) {
        const folder = response.data
        store.dispatch({ type: ACTION_TYPES.FOLDER_ADD_UPDATE, payload: folder })
    }
}

export async function foldersGet() {
    const response = await services.foldersGet()
    if (!response.error) {
        const folders = response.data
        store.dispatch({ type: ACTION_TYPES.FOLDERS_REPLACE, payload: folders })
    }
}

export function getFormattedDuration(duration: string): string {
    const durationArray = duration.split(':')
    const hours = parseInt(durationArray[0])
    const minutes = parseInt(durationArray[1])
    const seconds = parseInt(durationArray[2])
    const hoursString = hours > 0 ? `${hours}h ` : ''
    const minutesString = minutes > 0 ? `${minutes}m ` : ''
    const secondsString = seconds > 0 ? `${seconds}s ` : ''
    return `${hoursString}${minutesString}${secondsString}`
}

function getLocalDateStringFromISOString(ISOString: string): string {
    const date = new Date(ISOString)
    const year = date.getFullYear()
    let month = date.getMonth() + 1
    let dt = date.getDate()

    if (dt < 10) dt = '0' + dt
    if (month < 10) month = '0' + month

    const localDateString = year + '-' + month + '-' + dt
    return localDateString
}

function getLocalTimeStringFromISOString(ISOString: string): string {
    const date = new Date(ISOString)
    const hours = date.getHours()
    let minutes = date.getMinutes()
    let seconds = date.getSeconds()

    if (minutes < 10) minutes = '0' + minutes
    if (seconds < 10) seconds = '0' + seconds

    const localTimeString = hours + ':' + minutes + ':' + seconds
    return localTimeString
}

export function getSortedList(
    list: any[],
    primarySortField: string,
    primarySortFieldType: 'STRING' | 'NUMBER',
    sortOrder: 'ASC' | 'DESC' = 'ASC',
    secondarySortField?: string,
    secondarySortFieldType?: 'STRING' | 'NUMBER'): any[] {
    const nullList = list.filter(item => item[primarySortField] === null)
    const nonNullList = list.filter(item => item[primarySortField] !== null)
    if (primarySortFieldType === 'STRING' && sortOrder === 'ASC') {
        nonNullList.sort((a: any, b: any) => a[primarySortField].localeCompare(b[primarySortField]))
    } else if (primarySortFieldType === 'STRING' && sortOrder === 'DESC') {
        nonNullList.sort((a: any, b: any) => b[primarySortField].localeCompare(a[primarySortField]))
    } else if (sortOrder === 'ASC') {
        nonNullList.sort((a: any, b: any) => a[primarySortField] - b[primarySortField])
    } else if (sortOrder === 'DESC') {
        nonNullList.sort((a: any, b: any) => b[primarySortField] - a[primarySortField])
    }
    return nonNullList.concat(nullList)
}

export const getPreviousDueCode = (dueCode: string) => {
    const dueType = dueCode.split(':')[0].trim()
    const dueValue = dueCode.split(':')[1].trim()
    switch (dueType) {
        case 'L':
            return 'NA : NA'
        case 'TD':
            switch (dueValue) {
                case '1-30':
                    return 'NA : NA'
                case '31-60':
                    return 'TD : 1-30'
                case '61-90':
                    return 'TD : 31-60'
                case '91-120':
                    return 'TD : 61-90'
                default:
                    return 'NA : NA'
            }
        case 'DD':
            // if dueValue is '11-20', return 'DD: 1-10'
            const decade = parseInt(dueValue.split('-')[0])
            if (decade === 1) return 'NA : NA'
            return `DD : ${decade-10}-${decade-1}`
        case 'Y':
            // if dueValue is '1991', return 'Y : 1990'
            const year = parseInt(dueValue)
            return `Y : ${year-1}`
        case 'Q':
            // if dueValue is '1991-Q1', return 'Q : 1990-Q4'
            const quarterYear = parseInt(dueValue.split('-')[0])
            const quarter = parseInt(dueValue.split('-')[1].split('')[1])
            if (quarter === 1) return `Q : ${quarterYear-1}-Q4`
            return `Q : ${quarterYear}-Q${quarter-1}`
        case 'M':
            // if dueValue is '1991-01', return 'M : 1990-12'
            const monthYear = parseInt(dueValue.split('-')[0])
            const month = parseInt(dueValue.split('-')[1])
            if (month === 1) return `M : ${monthYear-1}-12`
            const newMonth = month - 1
            const formattedMonth = newMonth < 10 ? `0${newMonth}` : newMonth
            return `M : ${monthYear}-${formattedMonth}`
        case 'W':
            // if dueValue is '1991-01-01', return 'W : 1990-12-25'
            const weekYear = parseInt(dueValue.split('-')[0])
            const weekMonth = parseInt(dueValue.split('-')[1])
            const weekDay = parseInt(dueValue.split('-')[2])
            const previousWeekDate = new Date(weekYear, weekMonth - 1, weekDay - 7)
            const previousWeekYear = previousWeekDate.getFullYear()
            const previousWeekMonth = String(previousWeekDate.getMonth() + 1).padStart(2, '0')
            const previousWeekDay = String(previousWeekDate.getDate()).padStart(2, '0')
            return `W : ${previousWeekYear}-${previousWeekMonth}-${previousWeekDay}`
        case 'D':
            // if dueValue is '1991-01-02', return 'D : 1991-01-01'
            const dayYear = parseInt(dueValue.split('-')[0])
            const dayMonth = parseInt(dueValue.split('-')[1])
            const dayDay = parseInt(dueValue.split('-')[2])
            const previousDay = new Date(dayYear, dayMonth - 1, dayDay - 1)
            const previousDayYear = previousDay.getFullYear()
            const previousDayMonth = String(previousDay.getMonth() + 1).padStart(2, '0')
            const previousDayDay = String(previousDay.getDate()).padStart(2, '0')
            return `D : ${previousDayYear}-${previousDayMonth}-${previousDayDay}`
        default:
            return 'NA : NA'
    }
}

export const getParentDueCode = (dueCode: string) => {
    const dueType = dueCode.split(':')[0].trim()
    const dueValue = dueCode.split(':')[1].trim()

    let parentDueType = ''
    let parentDueValue = ''
    switch (dueType) {
        case 'L':
            parentDueType = 'NA'
            parentDueValue = 'NA'
            break
        case 'TD':
            parentDueType = 'L'
            parentDueValue = 'L'
            break
        case 'DD':
            parentDueType = 'TD'
            // Calculate tri-decade based on decade value (e.g. if DD is 11-20, TD is 1-30)
            let decade = parseInt(dueValue.split('-')[0])
            const triDecade = Math.floor(decade / 30) * 30
            parentDueValue = `${triDecade+1}-${triDecade+30}`
            break
        case 'Y':
            parentDueType = 'DD'
            // Calculate decade based on year value, starting 1990 (e.g. if Y is 1990, DD is 1-10, if Y is 2024, DD is 31-40)
            const year = parseInt(dueValue)
            decade = Math.floor((year - 1990) / 10) * 10
            parentDueValue = `${decade+1}-${decade+10}`
            break
        case 'Q':
            parentDueType = 'Y'
            // Calculate year based on quarter value (e.g. if Q is 1990-Q4, Y is 1990)
            const quarterYear = parseInt(dueValue.split('-')[0])
            parentDueValue = `${quarterYear}`
            break
        case 'M':
            parentDueType = 'Q'
            // Calculate quarter based on month value (e.g. if M is 1990-12, Q is 1990-Q4)
            const monthYear = parseInt(dueValue.split('-')[0])
            const month = parseInt(dueValue.split('-')[1])
            const quarter = Math.ceil(month / 3)
            parentDueValue = `${monthYear}-Q${quarter}`
            break
        case 'W':
            parentDueType = 'M'
            // Calculate month based on week value (e.g. if W is 1990-12-25, M is 1990-12)
            const weekYear = dueValue.split('-').slice(0, 2).join('-')
            parentDueValue = weekYear
            break
        case 'D':
            parentDueType = 'W'
            // Calculate week based on day value (e.g. if D is 1990-12-31, W is the previous Saturday)
            const dayYear = parseInt(dueValue.split('-')[0])
            const dayMonth = parseInt(dueValue.split('-')[1])
            const dayDay = parseInt(dueValue.split('-')[2])
            const dayDate = new Date(dayYear, dayMonth - 1, dayDay)
            const day = dayDate.getDay()
            const previousSaturday = new Date(dayDate)
            previousSaturday.setDate(dayDate.getDate() - (day + 1) % 7)
            const previousSaturdayYear = previousSaturday.getFullYear()
            const previousSaturdayMonth = previousSaturday.getMonth() + 1
            const previousSaturdayDay = previousSaturday.getDate()
            parentDueValue = `${previousSaturdayYear}-${previousSaturdayMonth < 10 ? '0' + previousSaturdayMonth : previousSaturdayMonth}-${previousSaturdayDay < 10 ? '0' + previousSaturdayDay : previousSaturdayDay}`
            break
        default:
            parentDueType = 'NA'
            parentDueValue = 'NA'
            break
    }
    return `${parentDueType} : ${parentDueValue}`
}

export const getNextDueCode = (dueCode: string) => {
    const dueType = dueCode.split(':')[0].trim()
    const dueValue = dueCode.split(':')[1].trim()
    switch (dueType) {
        case 'L':
            return 'NA : NA'
        case 'TD':
            switch (dueValue) {
                case '1-30':
                    return 'TD : 31-60'
                case '31-60':
                    return 'TD : 61-90'
                case '61-90':
                    return 'TD : 91-120'
                case '91-120':
                    return 'NA : NA'
                default:
                    return 'NA : NA'
            }
        case 'DD':
            // if dueValue is '1-10', return 'DD: 11-20'
            const decade = parseInt(dueValue.split('-')[1])
            if (decade === 30) return 'NA : NA'
            return `DD : ${decade+1}-${decade+10}`
        case 'Y':
            // if dueValue is '1990', return 'Y : 1991'
            const year = parseInt(dueValue)
            return `Y : ${year+1}`
        case 'Q':
            // if dueValue is '1990-Q4', return 'Q : 1991-Q1'
            const quarterYear = parseInt(dueValue.split('-')[0])
            const quarter = parseInt(dueValue.split('-')[1].split('')[1])
            if (quarter === 4) return `Q : ${quarterYear+1}-Q1`
            return `Q : ${quarterYear}-Q${quarter+1}`
        case 'M':
            // if dueValue is '1990-12', return 'M : 1991-01'
            const monthYear = parseInt(dueValue.split('-')[0])
            const month = parseInt(dueValue.split('-')[1])
            if (month === 12) return `M : ${monthYear+1}-01`
            const newMonth = month + 1
            const formattedMonth = newMonth < 10 ? `0${newMonth}` : newMonth
            return `M : ${monthYear}-${formattedMonth}`
        case 'W':
            // if dueValue is '1990-12-25', return 'W : 1991-01-01'
            const weekYear = parseInt(dueValue.split('-')[0])
            const weekMonth = parseInt(dueValue.split('-')[1])
            const weekDay = parseInt(dueValue.split('-')[2])
            const dueDate = new Date(weekYear, weekMonth - 1, weekDay)
            dueDate.setDate(dueDate.getDate() + 7)
            const nextDueYear = dueDate.getFullYear()
            const nextDueMonth = dueDate.getMonth() + 1
            const nextDueDay = dueDate.getDate()
            return `W : ${nextDueYear}-${nextDueMonth < 10 ? '0' + nextDueMonth : nextDueMonth}-${nextDueDay < 10 ? '0' + nextDueDay : nextDueDay}`
        case 'D':
            // if dueValue is '1991-01-01', return 'D : 1991-01-02'
            const dayYear = parseInt(dueValue.split('-')[0])
            const dayMonth = parseInt(dueValue.split('-')[1])
            const dayDay = parseInt(dueValue.split('-')[2])
            const nextDay = new Date(dayYear, dayMonth - 1, dayDay + 1)
            const nextDayYear = nextDay.getFullYear()
            const nextDayMonth = nextDay.getMonth() + 1
            const nextDayDay = nextDay.getDate()
            const formattedNextDayMonth = nextDayMonth < 10 ? `0${nextDayMonth}` : nextDayMonth
            const formattedNextDayDay = nextDayDay < 10 ? `0${nextDayDay}` : nextDayDay
            return `D : ${nextDayYear}-${formattedNextDayMonth}-${formattedNextDayDay}`
        default:
            return 'NA : NA'
    }
}

export function getTimestampForDateAndTimeStrings(dateString: string, timeString: string): number {
    return new Date(`${dateString}T${timeString}`).getTime()
}

export function handleTaskStart(taskId: number): void {
    // Calculate start time as nearest 0, 15, 30, 45 minute mark before current time
    const now = new Date()
    const minutes = now.getMinutes()
    const nearestQuarterHour = Math.floor(minutes / 15) * 15
    const startTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), nearestQuarterHour, 0, 0)

    // @ts-ignore
    userProfilePatch({ active_task: taskId, active_task_start_time: startTime })

    // Send immediate push notification to device with task name, start time using Expo Notifications
    const task = store.getState().todos.tasks.find((task: Task) => task.id === taskId)
    if (task) {
        const title = task.name
        const body = `Started at ${startTime.toLocaleTimeString()}`
        sendPushNotification(title, body, taskId)
    }

}

export function isDescendentOf(task: Task, filterTaskId: number, allTasks: Task[]): boolean {
    // if (task.id === 108) console.log(`Checking if ${task.id} is descendent of ${filterTaskId}`)
    if (task.id === filterTaskId) {
        return true
    }

    if (task.parent_task) {
        const parentTask = allTasks.find(t => t.id === task.parent_task)
        return isDescendentOf(parentTask, filterTaskId, allTasks)
    }
    return false
}

export function markTaskComplete(taskId: number): void {}

export function deleteTask(taskId: number): void {}

async function sendPushNotification(title: string, body: string, taskId: number) {
    // Get push token from local storage
    const pushToken = await AsyncStorage.getItem('pushToken')
    const message = {
      to: pushToken,
      sound: null,
      title: title,
      body: body,
      data: { taskId },
    };
  
    await fetch('https://exp.host/--/api/v2/push/send', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Accept-encoding': 'gzip, deflate',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(message),
    });
  }

export async function listDelete(id: number) {
    const response = await services.listDelete(id)
    if (!response.error) {
        const list = response.data
        store.dispatch({ type: ACTION_TYPES.DELETE_LIST, payload: id })
    }
}

export async function listPatch(listFields: List, id: number) {
    const response = await services.listPatch(listFields, id)
    if (!response.error) {
        const list = response.data
        store.dispatch({ type: ACTION_TYPES.ADD_UPDATE_LIST, payload: list })
    }
}

export async function listPost(listFields: List) {
    const response = await services.listPost(listFields)
    if (!response.error) {
        const list = response.data
        store.dispatch({ type: ACTION_TYPES.ADD_UPDATE_LIST, payload: list })
    }
}

export async function listsGet() {
    const response = await services.listsGet()
    if (!response.error) {
        const lists = response.data
        store.dispatch({ type: ACTION_TYPES.REPLACE_LISTS, payload: lists })
    }
}

export async function pushNotificationMetadataGet() {
    const tokensStr = await AsyncStorage.getItem('tokens')
    const tokens = tokensStr ? JSON.parse(tokensStr) : {}
    const accessToken = tokens.access
    showMessage({ message: 'Fetching push notifications metadata...', type: 'info', icon: 'auto' })
    const response = await services.pushNotificationMetadataGet(accessToken)
    if (!response.error) {
        const pushNotificationsMetadata = response.data
        store.dispatch({ type: ACTION_TYPES.PUSH_NOTIFICATION_METADATA_REPLACE, payload: pushNotificationsMetadata })
        showMessage({ message: `Push notifications metadata fetched`, type: 'success', icon: 'auto' })
    } else {
        // showMessage({ message: `Error fetching push notifications: ${response.error}`, type: 'danger', icon: 'auto' })  
    }
}

export async function refreshData() {
    // await foldersGet()
    // await listsGet()
    await userProfileGet()
    await tasksGet()
    // await timeLogsGet(accessToken)
}

export async function remindersImport() {
    const tokensStr = await AsyncStorage.getItem('tokens')
    const tokens = tokensStr ? JSON.parse(tokensStr) : {}
    const accessToken = tokens.access
    const response1 = await Calendar.requestRemindersPermissionsAsync()
    const response2 = await Calendar.requestCalendarPermissionsAsync()
    if (response1.status === 'granted' && response2.status === 'granted') {
        const reminders = await Calendar.getRemindersAsync([IOS_REMINDER_LIST_ID])
        let filteredReminders = reminders.filter(r => !r.completed && !r.dueDate)

        for (let reminder of filteredReminders) {
            if (reminder.id && reminder.title) {
                let description = reminder.notes ? reminder.notes : ''
                description += reminder.url ? ` ${reminder.url}` : ''
                const taskFields: Task = {
                    name: reminder.title,
                    description,
                    // due_date: reminder.dueDate ? getLocalDateStringFromISOString(reminder.dueDate) : null,
                    // due_time: reminder.dueDate ? getLocalTimeStringFromISOString(reminder.dueDate) : null,
                    // due_type: reminder.dueDate ? 'D' : null,
                    // due_value: reminder.dueDate ? getLocalDateStringFromISOString(reminder.dueDate) : null,
                    parent_task: INBOX_TASK_ID,
                }

                showMessage({ message: `Saving reminder: ${reminder.title}`, type: 'info', icon: 'auto' })
                const response = await services.taskPost(taskFields, accessToken)
                if (!response.error) {
                    const task = response.data
                    store.dispatch({ type: ACTION_TYPES.ADD_UPDATE_TASK, payload: task })
                    await Calendar.deleteReminderAsync(reminder.id)
                    showMessage({ message: `Reminder saved & deleted: ${reminder.title}`, type: 'success', icon: 'auto' })
                } else {
                    showMessage({ message: `Error saving reminder: ${response.error}`, type: 'danger', icon: 'auto' })
                }

                // Create push notification for this task
                if (taskFields.due_date && taskFields.due_time) {
                    // let notificationBody = ''
                    // if (taskFields.description) notificationBody += `✏️ ${taskFields.description}` 
                    const notificationId = await services.pushNotificationSchedule({
                        title: taskFields.name,
                        body: taskFields.description,
                        data: taskFields,
                        trigger: getTimestampForDateAndTimeStrings(taskFields.due_date, taskFields.due_time),
                    })
                    const pushNotificationMetadata = {
                        expo_identifier: notificationId,
                        metadata: JSON.stringify({ task_id: task.id })
                    }

                    showMessage({ message: `Scheduling push notification for: ${taskFields.name}`, type: 'info', icon: 'auto' })
                    const response2 = await services.pushNotificationMetadataPost(pushNotificationMetadata)
                    if (!response2.error) {
                        const pushNotificationMetadata = response2.data
                        store.dispatch({ type: ACTION_TYPES.PUSH_NOTIFICATION_METADATA_ADD, payload: pushNotificationMetadata })
                        showMessage({ message: `Push notification scheduled for: ${taskFields.name}`, type: 'success', icon: 'auto' })
                    } else {
                        showMessage({ message: `Error scheduling push notification for: ${taskFields.name}`, type: 'danger', icon: 'auto' })
                    }
                }
            }
        }

        // show message to user about number of imported tasks
        filteredReminders.length > 0 && showMessage({
            message: `${filteredReminders.length} reminder(s) to import`,
            type: 'success',
            autoHide: true,
        })
    }
}

export async function taskDelete(id: number) {
    const tokensStr = await AsyncStorage.getItem('tokens')
    const tokens = tokensStr ? JSON.parse(tokensStr) : {}
    const accessToken = tokens.access
    showMessage({ message: `Deleting task: ${id}`, type: 'info', icon: 'auto' })
    const response = await services.taskDelete(id, accessToken)
    if (!response.error) {
        const data = response.data

        // Unschedule push notifications & delete metadata
        const pushNotificationsMetadata = store.getState()
            .todos.pushNotificationMetadata
            .filter((p: any) => p.metadata.task_id === id)
        if (pushNotificationsMetadata.length > 0) {
            for (let pushNotificationMetadata of pushNotificationsMetadata) {
                const response2 = await services.pushNotificationUnschedule(pushNotificationMetadata.expo_identifier)
                const response3 = await services.pushNotificationMetadataDelete(pushNotificationMetadata.id)
                if (!response2.error && !response3.error) {
                    store.dispatch({ type: ACTION_TYPES.PUSH_NOTIFICATION_METADATA_DELETE, payload: pushNotificationMetadata.id })
                }
            }
        }

        store.dispatch({ type: ACTION_TYPES.DELETE_TASK, payload: id })

        showMessage({ message: `Task Deleted: ${id}`, type: 'success', icon: 'auto' })
    } else {
        showMessage({ message: `Error deleting task: ${response.error}`, type: 'danger', icon: 'auto' })
    }
}

export async function taskPatch(taskFields: Task, id: number) {
    const tokensStr = await AsyncStorage.getItem('tokens')
    const tokens = tokensStr ? JSON.parse(tokensStr) : {}
    const accessToken = tokens.access
    showMessage({message: `Patching task: ${id}`, type: 'info', icon: 'auto'})
    const response = await services.taskPatch(taskFields, id, accessToken)
    if (!response.error) {
        const task = response.data
        const storeTask = store.getState().todos.tasks.find((t: Task) => t.id === id)
        const storeTaskPushNotificationsMetadata = store.getState()
            .todos.pushNotificationMetadata
            .filter((p: any) => p.task_id === id)

        // Task is being completed -> remove existing push notifications
        if (taskFields.completed && storeTaskPushNotificationsMetadata.length > 0) {
            for (let pushNotificationMetadata of storeTaskPushNotificationsMetadata) {
                const response2 = await services.pushNotificationUnschedule(pushNotificationMetadata.expo_identifier)
                const response3 = await services.pushNotificationMetadataDelete(pushNotificationMetadata.id)
                if (!response2.error && !response3.error) {
                    store.dispatch({ type: ACTION_TYPES.PUSH_NOTIFICATION_METADATA_DELETE, payload: pushNotificationMetadata.id })
                }
            }
        }

        // Task is not being completed but due_date or due_time got cleared -> remove existing push notifications
        if (storeTaskPushNotificationsMetadata.length > 0
            && !taskFields.completed
            && (!taskFields.due_date || !taskFields.due_time)) {
            for (let pushNotificationMetadata of storeTaskPushNotificationsMetadata) {
                const response4 = await services.pushNotificationUnschedule(pushNotificationMetadata.expo_identifier)
                const response5 = await services.pushNotificationMetadataDelete(pushNotificationMetadata.id)
                if (!response5.error && !response4.error) {
                    store.dispatch({ type: ACTION_TYPES.PUSH_NOTIFICATION_METADATA_DELETE, payload: pushNotificationMetadata.id })
                }
            }
        }

        // Task is not being completed but due_date or due_time got changed -> remove existing push notifications & create new ones
        if (!taskFields.completed
            && taskFields.due_date
            && taskFields.due_time
            && (taskFields.due_date != storeTask.due_date || taskFields.due_time != storeTask.due_time)) {
            // Delete older push notifications
            for (let pushNotificationMetadata of storeTaskPushNotificationsMetadata) {
                const response6 = await services.pushNotificationUnschedule(pushNotificationMetadata.expo_identifier)
                const response7 = await services.pushNotificationMetadataDelete(pushNotificationMetadata.id)
                if (!response7.error && !response6.error) {
                    store.dispatch({ type: ACTION_TYPES.PUSH_NOTIFICATION_METADATA_DELETE, payload: pushNotificationMetadata.id })
                }
            }

            // Create new push notification for this device
            const notificationId = await services.pushNotificationSchedule({
                title: task.name,
                body: task.description,
                data: task,
                trigger: getTimestampForDateAndTimeStrings(task.due_date, task.due_time),
            })
            const pushNotificationMetadata = {
                expo_identifier: notificationId,
                metadata: JSON.stringify({ task_id: task.id })
            }
            const response8 = await services.pushNotificationMetadataPost(pushNotificationMetadata)
            if (!response8.error) {
                const pushNotificationMetadata = response8.data
                store.dispatch({ type: ACTION_TYPES.PUSH_NOTIFICATION_METADATA_ADD, payload: pushNotificationMetadata })
            }

        }

        store.dispatch({ type: ACTION_TYPES.ADD_UPDATE_TASK, payload: task })

        showMessage({message: `Task Patched: ${id}`, type: 'success', icon: 'auto'})

    } else {
        showMessage({message: `Error patching task: ${response.error}`, type: 'danger', icon: 'auto'})
    
    }
}

export async function taskPost(taskFields: Task) {
    const tokensStr = await AsyncStorage.getItem('tokens')
    const tokens = tokensStr ? JSON.parse(tokensStr) : {}
    const accessToken = tokens.access
    showMessage({ message: `Creating task: ${taskFields.name}`, type: 'info', icon: 'auto' })
    const response = await services.taskPost(taskFields, accessToken)
    if (!response.error) {
        const task = response.data
        showMessage({ message: `Task Created: ${taskFields.name}`, type: 'success', icon: 'auto' })
        if (task.due_date && task.due_time) {
            const notificationId = await services.pushNotificationSchedule({
                title: task.name,
                body: task.description,
                data: task,
                trigger: getTimestampForDateAndTimeStrings(task.due_date, task.due_time),
            })
            const pushNotificationMetadata = {
                expo_identifier: notificationId,
                metadata: JSON.stringify({ task_id: task.id })
            }
            const response2 = await services.pushNotificationMetadataPost(pushNotificationMetadata)
            if (!response2.error) {
                const pushNotificationMetadata = response2.data
                store.dispatch({ type: ACTION_TYPES.PUSH_NOTIFICATION_METADATA_ADD, payload: pushNotificationMetadata })
                showMessage({ message: `Push notification scheduled for: ${taskFields.name}`, type: 'success', icon: 'auto' })
            } else {
                showMessage({ message: `Error scheduling push notification for: ${taskFields.name}`, type: 'danger', icon: 'auto' })
            }
        }
        store.dispatch({ type: ACTION_TYPES.ADD_UPDATE_TASK, payload: task })
        showMessage({ message: `Task Created: ${taskFields.name}`, type: 'success', icon: 'auto' })
    } else {
        showMessage({ message: `Error creating task: ${response.error}`, type: 'danger', icon: 'auto' })
    }
}

export async function tasksGet() {
    const tokensStr = await AsyncStorage.getItem('tokens')
    const tokens = tokensStr ? JSON.parse(tokensStr) : {}
    const accessToken = tokens.access
    showMessage({ message: 'Fetching tasks...', type: 'info', icon: 'auto' })
    const response = await services.tasksGet(accessToken)
    if (!response.error) {
        const tasks = response.data
        showMessage({ message: `${tasks.length} tasks fetched`, type: 'success', icon: 'auto' })
        store.dispatch({ type: ACTION_TYPES.REPLACE_TASKS, payload: tasks })
    } else {
        showMessage({ message: `Error fetching tasks: ${response.error}`, type: 'danger', icon: 'auto' })
    }
}

export async function timeLogDelete(id: number) {
    const response = await services.timeLogDelete(id)
    if (!response.error) {
        const timeLog = response.data
        store.dispatch({ type: ACTION_TYPES.TIME_LOG_DELETE, payload: id })
    }
}

export async function timeLogPatch(fields: TimeLog, id: number) {
    const response = await services.timeLogPatch(fields, id)
    if (!response.error) {
        const timeLog = response.data
        store.dispatch({ type: ACTION_TYPES.TIME_LOG_ADD_UPDATE, payload: timeLog })
    }
}

export async function timeLogPost(fields: TimeLog) {
    const response = await services.timeLogPost(fields)
    if (!response.error) {
        const timeLog = response.data
        store.dispatch({ type: ACTION_TYPES.TIME_LOG_ADD_UPDATE, payload: timeLog })
    }
}

export async function timeLogsGet(accessToken: string) {
    const response = await services.timeLogsGet(accessToken)
    if (!response.error) {
        const timeLogs = response.data
        // showMessage({ message: `${timeLogs.length} time logs fetched`, type: 'success', icon: 'auto' })
        store.dispatch({ type: ACTION_TYPES.TIME_LOGS_REPLACE, payload: timeLogs })
    }
}

export async function userProfileGet() {
    const tokensStr = await AsyncStorage.getItem('tokens')
    const tokens = tokensStr ? JSON.parse(tokensStr) : {}
    const accessToken = tokens.access
    showMessage({ message: 'Fetching user profile...', type: 'info', icon: 'auto' })
    const response = await services.userProfileGet(accessToken)
    if (!response.error) {
        const userProfile = response.data
        showMessage({ message: 'User profile fetched', type: 'success', icon: 'auto' })
        store.dispatch({ type: ACTION_TYPES.USER_PROFILE_REPLACE, payload: userProfile })
        await remindersImport()
    } else {
        showMessage({ message: `Error fetching user profile: ${response.error}`, type: 'danger', icon: 'auto' })
    }
}

export async function userProfilePatch(fields: TodosUserProfile) {
    const profileId = store.getState().todos.userProfile.id
    const response = await services.userProfilePatch(fields, profileId)
    if (!response.error) {
        const userProfile = response.data
        store.dispatch({ type: ACTION_TYPES.USER_PROFILE_REPLACE, payload: userProfile })
    }
}