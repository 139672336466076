import React from 'react'
import { ScrollView } from 'react-native'
import { useSelector } from 'react-redux'

import Button from 'src/app/components/Button'
import { RootState } from 'src/app/redux/store'
import * as authHelpers from 'src/auth/helpers'
import * as helpers from '../../helpers'
import { rootStackNavigate, RootStack, APPS } from 'src/app/components/RootNavigator'
import { SCREENS } from 'src/time/components/TasksNavigator'
import { SafeAreaView } from 'react-native-safe-area-context'

export default function UserOptions({navigation, route}) {
    const appNavigation = route.params.appNavigation

    return (
        <SafeAreaView>
            <ScrollView>
                <Button
                    text="Time App"
                    onPress={() => {
                        appNavigation.navigate(APPS.TIME)
                    }}
                />
                <Button
                    text="Knowledge App"
                    onPress={() => {}}  
                />
                <Button
                    text="Logout"
                    onPress={() => authHelpers.logUserOut()}
                />
                <Button
                    text="Close"
                    onPress={() => {navigation.goBack()}}
                />
            </ScrollView>
        </SafeAreaView>
    )
}