import * as React from 'react'
import { FlatList, Modal, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'
import { HoldItem } from 'react-native-hold-menu'

import Button from 'src/app/components/Button'
import { RootState } from 'src/app/redux/store'
import { SCREENS } from 'src/time/components/TasksNavigator'
import * as helpers from '../../helpers'
import TextLabel from 'src/app/components/TextLabel'
import * as TasksNavigator from '../../components/TasksNavigator'
import ActiveTaskBar from 'src/time/components/ActiveTaskBar'

export default function Lists({ navigation, route }) {
    const folderId = route.params.id

    const planningMode = useSelector((state: RootState) => state.todos.appState.planningMode)
    const lists = useSelector((state: RootState) => state.todos.lists
                                .filter((list: List) => list.folder === folderId && !list.archived))
    const tasks = useSelector((state: RootState) => state.todos.tasks)
    const folderName = useSelector((state: RootState) => state.todos.folders.find((folder: Folder) => folder.id === folderId).name)
    const [deleteModalVisible, setDeleteModalVisible] = React.useState(false)
    const activeTaskId = useSelector((state: RootState) => state.todos.userProfile.active_task)

    React.useEffect(() => {navigation.setOptions({title: '📂 '+folderName})}, [])

    function getUnplannedTasksCountInList(list: List) {
        const listTasks = helpers.getUnplannedTasks(tasks, lists, list)
        return listTasks.length
    }

    const planningLists = lists.filter((list: List) => {
        return getUnplannedTasksCountInList(list) > 0  
    })

    const renderListItem = ({ item }) => {
        const countTotal = tasks.filter((task: Task) => task.list === item.id).length
        const countPending = tasks.filter((task: Task) => task.list === item.id && !task.completed && !task.name.includes('♾')).length
        const countUnplanned = getUnplannedTasksCountInList(item)

        const MenuItems = [
            {
                text: 'Edit list',
                onPress: () => {
                    TasksNavigator.navigate(SCREENS.ADD_EDIT_LIST, { id: item.id })
                },
            }
        ]

        return (
            <View style={styles.taskContainer}>
                <HoldItem items={MenuItems}>
                    <TouchableOpacity
                        onPress={() => navigation.navigate(SCREENS.LIST, { id: item.id })}
                        key={item.id}
                    >
                            <View style={styles.titleContainer}>
                                <TextLabel extraStyles={{fontSize: 16}} text={'📋 '+item.name} />
                            </View>
                    </TouchableOpacity>
                    <View style={styles.footerContainer}>
                        <TextLabel 
                            extraStyles={styles.footerText} 
                            text={`${countPending} pending. ${countUnplanned} unplanned. ${countTotal} total.`} 
                        />
                    </View>
                </HoldItem>
            </View>
        )
    }

    const handleDelete = async () => {
        helpers.folderDelete(folderId)
        navigation.goBack()
    }

    return (
        <>
            {activeTaskId && <ActiveTaskBar />}
            <View style={styles.header}>
                <Button
                    text='+'
                    onPress={() => navigation.navigate(SCREENS.ADD_EDIT_LIST, { id: null, folderId })}
                    primary
                    extraStyle={{minWidth: 48}}
                />
                <Button
                    text='Show Archived'
                    onPress={() => navigation.navigate(SCREENS.LISTS_ARCHIVED, { folderId })}
                />
                <Button
                    text='Edit Folder'
                    onPress={() => navigation.navigate(SCREENS.FOLDER_ADD_EDIT, { id: folderId })}
                />
                {
                    folderId && (
                        <Button
                            text='Delete'
                            onPress={() => setDeleteModalVisible(true)}
                            extraStyle={{backgroundColor: '#ff000099'}}
                        />
                    )
                }
                <Modal 
                    onRequestClose={() => setDeleteModalVisible(false)} 
                    visible={deleteModalVisible} 
                    animationType='slide'
                    transparent={true}
                >
                    <View style={styles.centeredView}>
                        <View style={styles.modalView}>
                        <Text>Confirm Delete?</Text>
                        <Button onPress={() => setDeleteModalVisible(false)} text='Cancel' />
                        <Button onPress={() => { setDeleteModalVisible(false); handleDelete() }} text='Delete' />
                        </View>
                    </View>
                </Modal>
            </View>
            <FlatList
                data={planningMode ? planningLists : lists}
                keyExtractor={(item) => item.id}
                renderItem={renderListItem}
            />
        </>
    )
}

const styles = StyleSheet.create({
    centeredView: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    modalView: {
      backgroundColor: "white",
      borderRadius: 20,
      padding: 4,
      alignItems: "center",
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      width: '80%',
      height: '50%'
    },
    checkboxContainer: {
        marginRight: 10
    },
    flagContainer: {
        marginRight: 4
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    taskContainer: {
        flex: 1, 
        flexDirection: 'column',
        // backgroundColor: 'red',
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
        paddingTop: 4,
        paddingBottom: 4
    },
    taskItemBodyContainer: {
        flex: 1, 
        flexDirection: 'row'
    },
    titleContainer: {
        // backgroundColor: 'pink',
        paddingLeft: 4,
        paddingRight: 4,
        paddingTop: 4
    },
    footerContainer: {
        // backgroundColor: 'yellow',
        paddingLeft: 4
    },
    footerText: {
        fontSize: 12,
        color: '#777'
    }
  })