import React from 'react'
import { Text } from 'react-native'

import styles from './styles'

export default function TextLabel({ text, extraStyles, onPress }: { text: string, extraStyles?: any, onPress?: any }) {
    return (
        <Text 
            style={extraStyles ? [styles.base, extraStyles] : styles.base}
            onPress={onPress}
        > {text} </Text>
    )
}
