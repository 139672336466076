import axios from 'axios'
import AsyncStorage from '@react-native-async-storage/async-storage'

import { BASE_URL, ENVIRONMENT } from 'react-native-dotenv'

const KWG_CONTENT = `${BASE_URL}/api/v1/knowledge/content/`
const KWG_FILES = `${BASE_URL}/api/v1/knowledge/files/`

export async function knowledgeContentGet(id: number | null) {
    const tokensStr = await AsyncStorage.getItem('tokens')
    const tokens = tokensStr ? JSON.parse(tokensStr) : {}
    const accessToken = tokens.access
    try {
        const { data } = await axios.get(KWG_CONTENT + (id ? `${id}/` : ''),
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            }
        )
        return { data }
    } catch (error) {
        return { error }
    }
}

export async function knowledgeContentPost(content: KnowledgeContent) {
    const tokensStr = await AsyncStorage.getItem('tokens')
    const tokens = tokensStr ? JSON.parse(tokensStr) : {}
    const accessToken = tokens.access
    try {
        const { data } = await axios.post(KWG_CONTENT, content,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            }
        )
        return { data }
    } catch (error) {
        return { error }
    }
}

export async function knowledgeContentPatch(id: number, contentFields: KnowledgeContent) {
    const tokensStr = await AsyncStorage.getItem('tokens')
    const tokens = tokensStr ? JSON.parse(tokensStr) : {}
    const accessToken = tokens.access
    try {
        const { data } = await axios.patch(KWG_CONTENT + id + '/', contentFields,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            }
        )
        return { data }
    } catch (error) {
        return { error }
    }
}

export async function knowledgeContentDelete(id: number | null) {
    const tokensStr = await AsyncStorage.getItem('tokens')
    const tokens = tokensStr ? JSON.parse(tokensStr) : {}
    const accessToken = tokens.access
    try {
        const { data } = await axios.delete(KWG_CONTENT + id + '/',
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            }
        )
        return { data }
    } catch (error) {
        return { error }
    }
}

export async function knowledgeContentSearch(query: string) {
    const tokensStr = await AsyncStorage.getItem('tokens')
    const tokens = tokensStr ? JSON.parse(tokensStr) : {}
    const accessToken = tokens.access
    try {
        const { data } = await axios.get(KWG_CONTENT + `search/?q=${query}`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            }
        )
        return { data }
    } catch (error) {
        return { error }
    }
}

export async function randomKnowledgeGet(id: number | null) {
    const tokensStr = await AsyncStorage.getItem('tokens')
    const tokens = tokensStr ? JSON.parse(tokensStr) : {}
    const accessToken = tokens.access
    try {
        if (id) {
            const { data } = await axios.get(`${KWG_CONTENT}random-descendant/${id}/`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            )
            return { data }
        } else { // No ID
            const { data } = await axios.get(`${KWG_CONTENT}random/`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            )
            return { data }
        }
        

    } catch (error) {
        return { error }
    }
}