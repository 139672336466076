import * as React from 'react'
import { NavigationContainer, createNavigationContainerRef, TabActions } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import { Text, TouchableOpacity, View } from 'react-native'
import { HeaderBackButton } from '@react-navigation/elements'

import AddEditList from '../screens/AddEditList'
import AddEditTask from '../screens/AddEditTask'
import Folders from '../screens/Folders'
import FolderAddEdit from '../screens/FolderAddEdit'
import Lists from '../screens/Lists'
import List from '../screens/List'
import TasksCompleted from '../screens/TasksCompleted'
import UserOptions from '../screens/UserOptions'
import ListsArchived from '../screens/ListsArchived'
import TimeLogsAddEdit from '../screens/TimeLogsAddEdit'
import TimeLogsList from '../screens/TimeLogsList'
import * as helpers from '../helpers'
import GoalHome from '../screens/GoalHome'
import GoalTasks from '../screens/GoalTasks'
import GoalTime from '../screens/GoalTime'


export const SCREENS = {
    ADD_EDIT_LIST: 'Add/Edit List',
    ADD_EDIT_TASK: 'Add/Edit Task',
    FOLDERS: 'Folders',
    FOLDER_ADD_EDIT: 'Add/Edit Folder',
    LISTS: 'Lists',
    LISTS_ARCHIVED: 'Archived Lists',
    LIST: 'List',
    TASKS_COMPLETED: 'Tasks Completed',
    TIME_LOGS_LIST: 'Time Logs',
    TIME_LOG_ADD_EDIT: 'Add/Edit Time Log',
    USER_OPTIONS: 'User Options',
    GOAL_HOME: 'GoalHome',
    GOAL_TASKS: 'GoalTasks',
    GOAL_TIME: 'GoalTime',
}

const Stack = createStackNavigator()
const navigationRef = createNavigationContainerRef()

export function navigate(name, params) {
    if (navigationRef.isReady()) {
        navigationRef.navigate(name, params);
    }
}

export default function TasksNavigator({ route }) {
    const { appNavigation } = route.params

    function handleBackNavigation(navigation: any, route: any) {
        switch (route.params.from) {
            case 'Due':
                route.params.rootNavigation?.dispatch(TabActions.jumpTo('Due'))
                break
            case 'Overdue':
                route.params.rootNavigation?.dispatch(TabActions.jumpTo('Overdue'))
                break
            default:
                navigation.goBack()
        }
    }
    
    return (
        <NavigationContainer
            independent={true}
            ref={navigationRef}
        >
            <Stack.Navigator
                initialRouteName={SCREENS.GOAL_HOME}
                screenOptions={{
                    headerShown: true,
                    headerRight: () => <HeaderOptions />
                }}
            >
                <Stack.Group>
                    <Stack.Screen
                        name={SCREENS.ADD_EDIT_LIST}
                        component={AddEditList}
                    />
                    <Stack.Screen
                        name={SCREENS.ADD_EDIT_TASK}
                        component={AddEditTask}
                        options={({navigation, route}) => ({
                            headerLeft: (props) => (
                              <HeaderBackButton
                                {...props}
                                onPress={() => handleBackNavigation(navigation, route, props)}
                              />
                            ),
                        })}
                    />
                    <Stack.Screen
                        name={SCREENS.GOAL_HOME}
                        component={GoalHome}
                    />
                    <Stack.Screen
                        name={SCREENS.GOAL_TASKS}
                        component={GoalTasks}
                    />
                    <Stack.Screen
                        name={SCREENS.GOAL_TIME}
                        component={GoalTime}
                    />
                    <Stack.Screen
                        name={SCREENS.FOLDERS}
                        component={Folders}
                    />
                    <Stack.Screen
                        name={SCREENS.FOLDER_ADD_EDIT}
                        component={FolderAddEdit}
                    />
                    <Stack.Screen
                        name={SCREENS.LISTS}
                        component={Lists}
                    />
                    <Stack.Screen
                        name={SCREENS.LIST}
                        component={List}
                    />
                    <Stack.Screen
                        name={SCREENS.LISTS_ARCHIVED}
                        component={ListsArchived}
                    />
                    <Stack.Screen
                        name={SCREENS.TASKS_COMPLETED}
                        component={TasksCompleted}
                    />
                    <Stack.Screen
                        name={SCREENS.TIME_LOGS_LIST}
                        component={TimeLogsList}
                    />
                    <Stack.Screen
                        name={SCREENS.TIME_LOG_ADD_EDIT}
                        component={TimeLogsAddEdit}
                    />
                </Stack.Group>
                <Stack.Group screenOptions={{ presentation: 'modal' }}>
                    <Stack.Screen
                        name={SCREENS.USER_OPTIONS}
                        component={UserOptions}
                        initialParams={{ appNavigation: appNavigation }}
                    />
                </Stack.Group>
            </Stack.Navigator>
        </NavigationContainer>
    )
}

function HeaderOptions() {
    return (
        <View style={{flexDirection: 'row'}}>
            <TouchableOpacity
                style={{
                    width: 32,
                    height: 32,
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#ccc',
                    marginVertical: 8,
                    marginHorizontal: 2,
                    borderRadius: 16
                }}
            >
                <Text
                    onPress={() => navigate(SCREENS.GOAL_HOME, {})}
                >
                    ⏫
                </Text>
            </TouchableOpacity>
            <TouchableOpacity
                style={{
                    width: 32,
                    height: 32,
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#ccc',
                    marginVertical: 8,
                    marginHorizontal: 2,
                    borderRadius: 16
                }}
            >
                <Text
                    onPress={() => helpers.refreshData()}
                >
                    🔄
                </Text>
            </TouchableOpacity>
            <TouchableOpacity
                style={{
                    width: 32,
                    height: 32,
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#ccc',
                    marginVertical: 8,
                    marginHorizontal: 2,
                    borderRadius: 16
                }}
            >
                <Text
                    onPress={() => navigate(SCREENS.USER_OPTIONS, {})}
                >
                    👤
                </Text>
            </TouchableOpacity>
        </View> 
    )
}