import { composeWithDevTools } from 'redux-devtools-extension'
// import { persistStore, persistReducer } from 'redux-persist'
import { createStore } from 'redux'
import AsyncStorage from '@react-native-async-storage/async-storage'
// import storage from 'redux-persist/lib/storage' 

import reducers from './reducers'

// const persistConfig = {
//     key: 'root',
//     storage: AsyncStorage,
// }

// const persistedReducer = persistReducer(persistConfig, reducers)
// const store = createStore(persistedReducer, composeWithDevTools())

const store = createStore(reducers, composeWithDevTools())

// export const persistor = persistStore(store)

export type RootState = ReturnType<typeof reducers>

export default store