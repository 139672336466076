import { combineReducers } from 'redux'

import app from './app-reducers'
import auth from '../../auth/reducers'
// import flashcards from 'src/flashcards/reducers'
import knowledge from 'src/knowledge/reducers'
import todos from '../../time/reducers'

const reducers = combineReducers({
    app,
    auth,
    // flashcards,
    knowledge,
    todos
})

export default reducers